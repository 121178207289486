import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "@language";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { classNames } from "@commonFunction";
import { Field, reduxForm, getFormValues } from "redux-form";
import _, { map } from "lodash";
import {
  charityviewModalopen,
  productExculdelocation,
  continentLength,
} from "@productmanagementActions";

class CharityViewModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      discTypeState: "percentage",
      btnLoader: false,
      isBtnDisable: false,
      selectedContinent: null,
      selectedOptions: [],
      selectedUsers: [],
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  closeModal() {
    this.setState({ open: false });
    this.props.charityviewModalopen(false);
  }

  /**** Fake Close ****/
  closeModal1() {}

  // Form Submit
  onFormSubmit(values) {}

  componentWillUnmount() {}

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      ischarityviewModalopen,
      formValues,
      description,
      image,
      name,
      percentage,
    } = this.props;

    return (
      <Transition.Root show={ischarityviewModalopen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={ischarityviewModalopen}
          onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}>
                <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div></div>
                  <div className="flex flex-wrap col-span-2 content-center justify-center mt-4">
                    <div className="text-lg leading-6 font-medium text-secondary">
                      {LocaleStrings.charity_details}
                    </div>
                  </div>
                  <div className=" absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal"
                      onClick={this.closeModal}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mx-14 my-4 sm:flex-row-reverse">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(
                      this.onFormSubmit.bind(this)
                    )}></form>
                  <div className="pt-4">
                    {image != null ? (
                      <img
                        className="w-full mx-auto h-40 rounded"
                        src={image}></img>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-8">
                    {name != null ? (
                      <div>
                        {LocaleStrings.charity_name} {name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-4">
                    {percentage != null ? (
                      <div>
                        {LocaleStrings.charity_donation_percentage} {percentage}
                        %
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-4">
                    {description != null ? (
                      <div>
                        {LocaleStrings.charity_description} {description}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    ischarityviewModalopen: state.ischarityviewModalopen,
    initialValues: state.productexcludeLocationdata,
    continentList: state.continentList,
    formValues: getFormValues("UploadProductForm")(state),
  };
}

export default connect(mapStateToProps, {
  charityviewModalopen,
  productExculdelocation,
  continentLength,
})(
  reduxForm({
    validate,
    form: "CharityViewModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CharityViewModal)
);
