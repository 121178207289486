/* This example requires Tailwind CSS v2.0+ */
import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AvatarEditor from "react-avatar-editor";
import { ZoomInIcon, ZoomOutIcon, XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { classNames } from "@commonFunction";

function ProductCropper(props) {
  var { displaySize, cropperSize, requiredSize, className, displayText } =
    props;
  var [canvasRecource, setCanvasRecource] = useState("");
  var [btnLoader, setbtnLoader] = useState(false);
  var [imageUrl, setImagepath] = useState("");
  var [scale, setScale] = useState(1.2);
  var [imageErrorMsg, setImageErrorMsg] = useState("");

  var scaleStep = 0.05;
  var defaultScale = 1.2;
  var minScale = 1;
  var maxScale = 2;
  var editor = "";

  useEffect(() => {
    if (!_.isEmpty(props.imagepath)) {
      setCanvasRecource(props.imagepath);
      setImagepath(props.imagepath);
    }
  }, [props.imagepath]);

  const setEditorRef = (edit) => (editor = edit);

  const onClickSave = () => {
    saveImage(null);
  };
  // console.log("canvasRecource", canvasRecource);
  const saveImage = (callback) => {
    if (editor) {
      setbtnLoader(true);
      const canvas = editor.getImage().toDataURL();
      var imageType = props.imageType;
      if (!imageType) imageType = "jpg";

      var img;
      if (imageType === "png") img = editor.getImage().toDataURL();
      else img = editor.getImage().toDataURL("image/jpeg", 0.9);
      let img1 = convertToFile(canvas);
      callback = setImagepath(img1);
      // props.onImageSave(img1);
      if (callback) {
        setTimeout(() => {
          callback();
          setbtnLoader(false);
          props.setisOpen(false);
        }, 2000);
      }
    }
  };

  const convertToFile = async (dataUrl) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    let newFile = new File([blob], "pictureName", { type: "image/jpeg" });

    let imageURL = window.URL.createObjectURL(newFile);
    // console.log("imageURL", imageURL);

    setImagepath(imageURL);
    props.onImageSave(imageURL, newFile);
    // if (callback) {
    //   setTimeout(() => {
    //     callback();
    //   }, 2000);
    // }
    setTimeout(() => {
      setbtnLoader(false);
      props.setisOpen(false);
    }, 2000);
    return newFile;
    // handlePictureSelect(newFile)
  };

  const resetImage = () => {
    setCanvasRecource("");
    setImagepath("");
    setScale(defaultScale);
    setImageErrorMsg("");

    props.onImageSave("");
    // props.onImageChange(false);
  };

  const onZoomOut = () => {
    var scale1 = scale;
    scale1 = scale1 - scaleStep;
    if (scale1 < minScale) scale1 = minScale;
    setScale(scale1);
  };

  const onZoomIn = () => {
    var scale1 = scale;
    scale1 = scale1 + scaleStep;
    if (scale1 > maxScale) scale1 = maxScale;
    setScale(scale1);
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.setisOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle
             sm:p-6 bigmodal">
              {/* MODAL HEADER */}
              <div className="">
                <div></div>
                <div className="flex flex-wrap content-center justify-center mt-4 col-span-4">
                  <div className="text-lg leading-6 font-medium text-secondary  h-12">
                    {LocaleStrings.crop_save_img}
                  </div>
                </div>
                <div className="absolute top-0 right-0 pt-4 pr-4 ">
                  <button
                    type="button"
                    className="btn-closeModal"
                    onClick={() => props.setisOpen(false)}>
                    <span className="sr-only">{LocaleStrings.close}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="center-item">
                {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"></div> */}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-secondary"></Dialog.Title>
                  <div className="mt-2">
                    <img
                      loading="lazy"
                      className="object-cover  xs:w-full xs:h-full"
                      src={props.currentImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className={`space-y-2 center-item block`}>
                <AvatarEditor
                  image={canvasRecource}
                  color={[0, 0, 0, 0.5]} // RGBA
                  scale={scale}
                  rotate={0}
                  ref={setEditorRef}
                  disabledrop="true"
                  width={cropperSize.width}
                  height={cropperSize.height}
                  className="editor-canvas"
                  style={{ background: "#777" }}
                />
              </div>
              <div className="space-x-2 flex center-item mt-10">
                <button
                  type="button"
                  className="theme-icon-button-circle-xs"
                  onClick={onZoomIn}>
                  <ZoomInIcon className="h-4 w-5" aria-hidden="true" />
                  <span className="ml-2">{LocaleStrings.zoom_in}</span>
                </button>
                <button
                  type="button"
                  className="theme-icon-button-circle-xs"
                  onClick={onZoomOut}>
                  <ZoomOutIcon className="h-4 w-5" aria-hidden="true" />
                  <span className="ml-2">{LocaleStrings.zoom_out}</span>
                </button>
                <button
                  type="button"
                  className="default-button-xs1 flex"
                  onClick={onClickSave}>
                  <svg
                    className={classNames(
                      btnLoader ? "" : "hidden",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  <span className="mt-1">{LocaleStrings.btn_save}</span>
                </button>
                {/* <button
                  type="button"
                  className="danger-icon-button-circle-xs"
                  onClick={resetImage}>
                  <XIcon className="h-4 w-5" aria-hidden="true" />
                </button> */}
              </div>
              {/* <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => props.setisOpen(false)}>
                  {LocaleStrings.close}
                </button>
              </div> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var { session } = state;

  return {
    session,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {})(ProductCropper);
