import LocalizedStrings from "react-localization";

let LocaleStrings = new LocalizedStrings({
  en: {
    //API CALL
    something_went_wrong: "Something went wrong",
    api_success_status: "Request has succeeded",
    // Freeze account Text
    account_freezed: "Your Account is Freezed!",
    no_content: "No Content",
    no_search_result: "No search results found",
    btn_save: "Save",
    crop_save_img: "Crop & Save Image",
    zoom_in: "Zoom In",
    zoom_out: "Zoom Out",
    browserhtml_notsupport: "Your browser does not support HTML video.",

    // Units
    pcs: "pcs",
    nos: "nos",
    previous: "Previous",

    // Payment methods
    card: "Card",
    cash: "Cash",
    upi: "UPI",
    netbanking: "Net Banking",

    // Address type
    home: "Home",
    office: "Office",
    other: "Other",

    // Negotiation status
    negotiating: "Negotiating",
    accepted: "Accepted",
    rejected: "Rejected",

    categories: "Categories",
    subcategories: "Sub-categories",

    account_temporarily_unavailable: "Account temporarily unavailable",
    temporarily_unavailable: "Temporarily unavailable",
    sku: "sku",
    skip: "Skip",
    accept: "Accept",
    reject: "Reject",
    cancel: "Cancel",
    cancel_upload: "Cancel upload",
    back: "Back",
    star: "Star",
    copied: "Copied",
    not_applicable_in_short: "N/A",
    cancelled: "Cancelled",
    expired: "Expired",
    repayment_btn: "Pay now",
    repayment_btn_relist: "Pay now & Relist",
    resend: "Resend",
    not_a_valid_phone_number: "Not a valid phone number",
    invalid_quantity: "Not a valid quantity",
    invalid_price: "Invalid price given",
    report: "Report",
    already_reported: "Already reported",
    common_fail_message: "Something went wrong",
    invalid_length: "Invalid",
    invalid: "Invalid",
    invalid_number: "Invalid Number",
    ok: "ok",
    visit_again: "Visit Again",
    feature_under_construction: "The feature is coming soon",
    invalid_email: "Invalid email",
    invalid_date_range: "Invalid date range",
    email: "Email",
    email_phone: "Email/Phone",
    password: "Password",
    signin: "Sign in",
    forgot_your_password: "Forgot your password?",
    create_a_new_account: "Create a new account",
    signup_as_vendor: "Signup as Vendor",
    signup_header_english: "English",
    select_the_type_of_business: "Select the type of business you own",
    or_use_social: "OR USE SOCIAL",
    connect_with_apple: "Connect with Apple",
    connect_with_facebook: "Connect with Facebook",
    connect_with_google: "Connect with Google",
    connect_with_twitter: "Connect with Twitter",
    company: "Company",
    home_business: "Home Business",
    personal_items: "Personal Items",
    consumer: "Consumer",
    login: "Login",
    signout: "Sign Out",
    login_error_other_than_vendor: "Other Than Vendor Not Allowed to log in!",
    login_form_validation_email_required: "Please enter your email address!",
    login_form_validation_password_required: "Please enter your password!",
    login_user_not_registered: "Your account is not registered with us!",
    login_user_session_expired: "Your account access session is expired!",
    invalid_email_password: "Incorrect email or password!",
    invalid_email_phone_password: "Incorrect email/phone or password!",
    invalid_user: "Invalid User!",
    button_logout: "Logout",
    button_yes: "Yes",
    button_no: "No",
    logout_want_to_logout: "Do you really want to Logout?",
    select: "SELECT",
    accept_cash_on_delivery: "Accept cash on delivery",
    product_managemnet_istaxable_text: "Is this product taxable ?",
    product_tax_text: "Product tax(in %)",
    enter_product_tax_tex: "Enter product tax(in %)",
    item_location: "Item Location",
    shipping_charge: "Shipping Charge",
    shipping: "Shipping",
    shipping_charge_note:
      "Please note that the shipping charge for your orders will be set as charged by our partners, and Shopez will not be held responsible for any discrepancies in shipping charges. The shipping charge for your order may be less or more than what you expected, depending on the shipping charge set by our partners and you will be responsible for bearing the charged amount towards Shopez.",
    shipping_charge_note2:
      "Please note that if the shipping charge set by you (either 'Flat' or 'No shipping') for the product is less than the charge set by our partners, you will be responsible for bearing the balance amount towards Shopez. Shopez cannot be held responsible for any discrepancies in shipping charges or for any additional costs incurred due to such discrepancies.",
    specs_small_text: "Type In unique details that your buyer might look for",
    enter_item_location: "Enter item location",
    select_return_time: "Select return time",
    selling_for_charity: "Selling for charity",
    make_a_donation: "Make a Donation",
    edit: "Edit",
    add: "Add",
    ban: "Ban",
    delete: "Delete",
    cancel_bid: "Cancel Bid",
    no_result_found: "No results found",
    geocoder_failed_due_to: "Geocoder failed due to",
    digit_one: "1",
    digit_two: "2",
    drop_or_select: "Drop Or Select",
    browser_doesnot_support_html_vidoe:
      "Your browser does not support HTML video",
    name: "Name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    rather_not_say: "Rather not say",
    verify: "Verify",
    public: "Public",
    private: "Private",
    percent_sign: "%",
    discount_using: "discount using",
    might_apply: "might apply",
    shipping: "Shipping",
    off: "OFF",
    connect_social_media_text: "Connect with social media",
    or: "Or",
    select_file: "Click to upload image",
    button_crop_save: "Crop & Save",
    button_upload_new_image: "Upload New Image",
    login_sub_haeder_text: "Enter your email/phone no. & password to login",
    create_a_account: "Create a account",
    login_success: "You are successfully logged in",
    required: "Required",
    anonymous: "Anonymous",
    verify_email_button: "Verify Email",
    enter_email_want_to_change:
      "Please enter the email address you want to change!",
    english: "English",
    arabic: "Arabic",
    no_found: "Not found !",

    // Review and rating common component
    rating_and: "Rating And",
    review_and_rating_text1: "1. Product rating",
    review_and_rating_text2: "2. How accurate was the product description?",
    review_and_rating_text3:
      "3. How satisfied were you with the seller's Communication?",
    review_and_rating_text4: "4. How quickly did the seller ship the item?",

    report_confirm_review_title: "Report review",
    report_confirm_review_message:
      "Are you sure you want to report about this review?",

    out_of_five: "out of 5",

    // Sign Up/In/Register
    signup: "Signup",
    signup_sub_header_text: "Let's get started by creating a new account",
    identif_your_business: "Identify Your Business",
    password_reset_link_text:
      "Please enter your registered email and we will send you a password reset link",

    password_reset_link_alert_text: "Password reset link sent successfully",
    continue: "Continue",
    business_name: "Business Name",
    nick_name: "Nick Name",
    date_of_birth: "Date of birth",
    password_dont_match: "Both password doesn't match",
    account_exist_alert_text: "Account already exist, trying to login",

    verification_header_text1: "Verify Email",
    verification_subheader_text1: "Verify your email address",
    verification_header_text2: "Verify",
    verification_subheader_text2: "Verify your phone number",
    verification_header_text3: "Upload Documents",
    verification_subheader_text3:
      "Before you can start using Shopez, Please upload the following:",

    verification_email_note_text1:
      "In order for us to activate your account and let you login, we need to verify the email address. We have sent an email to (",
    verification_email_note_text2:
      ") which contains a link you need to click on.",

    verification_email_note_text3:
      "You have entered the wrong email id? You can change the email now and verify.",

    verification_email_note_text4:
      "Your new email has been successfully updated! Please re-login to verify your new email.",

    verification_email_resend_btn: "Haven't received the email?",

    verification_phone_change_number: "Change Number?",
    verification_phone_change_number_field_name: "Change Number",
    verification_phone_change_number_field_placeholder: "Enter your number",

    enter_wrong_emailid: "You have entered the wrong email id",
    email_varified_successfully: "Email verified successfully",
    phone_varified_successfully: "Phone number verified successfully",
    resent_email_alert_text:
      "Email has been sent to your register email address !",
    email_exist_alert_text: "Email already exists! Enter another",

    business_name_not_available: "Sorry, Business name is not available !",
    nick_name_not_available: "Sorry, Nick name is not available !",

    // Side Bar
    sales_management: "Sales Management",
    profile_settings: "Profile Settings",
    projects: "Projects",
    language: "Language",
    language_privacysettings: "Privacy setting",
    currency: "Currency",
    product_management: "Product Management",
    revenue_reports: "Revenue Reports",
    coupons_management: "Coupons Management",
    discounts_management: "Discounts Management",
    ratings_reviews: "Ratings & Reviews",
    faq_support: "FAQ & Support",
    legal_docs: "Legal Docs",
    app_suggestions: "App Suggestions",
    email_address: "Email address",

    // Notification
    notifications: "Notifications",
    read_all: "Read All",
    update: "Update",
    mark_all_messages_as_read: "Mark all messages as read",
    no_notification_header: "No Notification(s)",
    no_notification_subheader: "No notification received yet",

    // Messages
    message_modal_header_text: "Messages",
    send_message: "Send Message",
    type_your_message: "Type your message",
    no_message_header: "No Message(s)",
    no_message_subheader: "No Message received yet",
    message_tab_product: "Products",
    message_tab_order: "Orders",

    // Forgot Password Modal
    forgot_password: "Forgot Password",
    forgot_password_reset_link_text:
      "Please enter your registered email and will send you a password reset link",
    send_reset_link: "Send Reset Link",
    close: "Close",
    email_id_not_register_alert: "Email Id not registered with Shopez",

    // Sales Management
    sales_overview: "Sales Overview",
    sales_report: "Sales Report",

    // Product Management
    product_tab_all: "All Product",
    product_tab_drafted: "Drafted Products",
    setup_store_setup_btn_text: "Setup",
    setup_store_header_text: "Setup Store",
    setup_store_subheader_text:
      "Please setup our Store before adding a Product",
    setup_address_header_text_personal: "Setup Address",
    setup_address_subheader_text_personal:
      "Please setup our Address before adding a Product",
    product_list_search_by:
      "Search by Product name, descriptions or specifications",
    sort: "Sort",
    filter: "Filter",
    list: "List",
    grid: "Grid",
    search: "Search",
    search_product: "Search product",
    upload_product: "Upload Product",
    upload_new: "Upload New",
    edit_product: "Edit Product",
    update_product: "Update Product",
    delete_product: "Delete Product",
    waiting_for_approval: "Waiting for Approval",
    out_of_stock: "Out of stock",
    banned: "Banned",
    rejected: "Rejected",
    sold: "sold",
    purchased: "Purchased",
    unlisted: "Unlisted",
    reviews: "Reviews",
    likes: "likes",
    en: "En",
    ar: "Ar",
    prooduct_reviews_rating: "Product reviews & rating",
    no_reviews: "No reviews",

    about_the_product: "About the product",
    product_variation: "Product variation",
    product_specifications: "Product specifications",
    item_specifications: "Item specifications",
    sales_formate_and_pricing: "Sales format and pricing",
    shipping_type_and_service: "Shipping type and service",
    set_preferences: "Set preferences",
    select_a_tab: "Select a tab",
    translate_from_arabic: "Translate from Arabic",
    translate_from_english: "Translate from English",
    successfully_translated_to_english: "Successfully Translated to english",
    successfully_translated_to_arabic: "Successfully Translated to arabic",
    enable_auto_translate:
      "Enable Auto translate (applicable if details in both languages are available)",
    scan_barcode_to_autofill: "Scan Barcode To Auto-fill",
    product_title: "Product title",
    enter_product_title: "Enter Product title",
    product_subtitle_desc: "Product sub-title description",
    enter_product_subtitle_desc: "Enter product sub-title description",
    select_product_category_subcategory: "Select product category, subcategory",
    product_condition: "Product condition",
    select_product_condition: "Select product condition",
    new: "New",
    used: "Used",
    refurbished: "Refurbished",
    next: "Next",
    is_product_has_variant: "The product has variant ?",
    product_variant_small_desc:
      "List different variation of the item (different color and sizes)",
    has_default_photo: "Use Default Photo",
    standard_description: "Standard description",
    enter_standard_description: "Enter standard description",
    sales_format: "Sales Format",
    direct_sale: "Direct sale",
    add_variation: "Add Variation",
    // price_quantity_small_text: "Price & Quantity  (! The price in the listing is either invalid or below the minimum price of QAR 0.99)",
    price_quantity_small_text: "Price & Quantity",
    select_variation_text: "Please select a variation",
    required_itemspecs: "Items Specifics (Required)",
    additional_itemspecs: "Items Specifics (Additional)",
    item_specs_smalltext: "Buyer Needs this details to find your items",
    auction: "Auction",
    // min_reserve_price: "Minimum reserve price (in %)", //old
    // enter_min_reserve_price: "Enter minimum reserve price (in %)", //old
    min_reserve_price: "Minimum reserve price",
    enter_min_reserve_price: "Enter minimum reserve price",
    start_date: "Start date",
    end_date: "End date",
    schedule_auction: "Schedule Auction",
    incremental_rate: "Incremental rate",
    enter_incremental_rate: "Enter Incremental rate",
    return_type: "Return type",
    return_time: "Return time",
    start_return_type: "Select return type",
    select_return_time: "Select return time",
    handling_time: "Handling time",
    select_handling_time: "Select handling time",
    refund_type: "Refund type",
    shipping_cost_breared_by: "Refund/Replace shipping cost bearer",
    select_shipping_cost_breared_by:
      "Select Refund/Replace shipping cost bearer",
    select_refund_type: "Select refund type",
    charity_events: "Charity events",
    select_charity_events: "Select charity events",
    charity_desc: "Charity Description:-",
    order_handled_by: " Order to be handled by",
    donation_percentage_by_shopez: "  Donation Percentage Added By Shopez:-",
    charity_details: "Charity Details",
    charity_name: "Charity Name: ",
    charity_donation_percentage: "Donation Percentage: ",
    charity_description: "Charity Description: ",
    donation_percentage: "Donation percentage",
    set_donation_percentage: "Set donation percentage",
    shipping_service: "Shipping service",
    select_service: "Select service",
    package: "Package",
    select_package: "Select package",
    flat: "Flat Shipping (Same charge for every location)",
    calculated: "Calculated",
    shipping_cost: "Shipping cost",
    enter_shipping_cost: "Enter shipping cost",
    shipping_cost_by_location: "Shipping cost by location",
    set_shipping_cost_by_location: "Set shipping cost by location",
    no_shipping: "No shipping (Local pickup only)",
    enter_price: "Enter price",
    enter_price_placeholder: "Enter price (discount applicable as set)",
    enable_best_offer: "Enable negotiation offer",
    enable_best_offer_text: "( This will enable negotiation for this item )",
    add_product_discount: "Add Product Discount",
    product_discount: "Product Discount",
    add_new_discount: "Add New Discount",
    edit_discount: "Edit Discount",
    update_discount: "Update discount",
    delete_discount: "Delete discount",
    shipping_zone_pincode: "Shipping availability (Zone / Pincode)",
    select_shipping_zone_pincode: "Select Zone / Pincode",
    label_country: "Country",
    placeholder_country: "Select Country",
    label_state: "State",
    placeholder_state: "Enter State",
    label_city: "City",
    placeholder_city: "Enter City",
    exclude_location: "Exclude Location",
    excludeshipping_nocontent: "No Continent",

    upload_products_error_shippingdetails: "Please Add All shipping Details",
    upload_products_photosoff: "Photo's of ",
    upload_products_photosoff: "Photo's of ",
    save_as_draft: "Save as draft",
    note_for_media_and_price:
      "*Adding New Variant Would Require Adding of Media And Price details again",

    auction_duration_label: "Select Auction Duration",
    addcustom_btn: "Add Custom",

    discount_title: "Discount title",
    enter_discount_title: "Enter discount title",
    validity_starting_date: "Validity starting date",
    select_starting_date: "Select starting date",
    validity_end_date: "Validity end date",
    select_end_date: "Select end date",
    discount: "Discount",
    coupon_discount: "Coupon Discount",
    product_category_subcategory: "Product category,subcategory",

    category_subcategory: "Category & Sub-category",
    done: "Done",
    search_categories: "Search categories",
    product_management_modal_subtext:
      "Based on your product title and subtitle, here are some suggested categories and sub-categories",
    cate_subcate_error_text: "Please select product category,subcategory",
    product_added_successfully: "Product added successfully",
    product_deleted_successfully: "Product deleted successfully",

    product_updated_successfully: "Product updated successfully",
    product_listed_successfully: "Product Listed successfully",
    product_unlisted_successfully: "Product Unlisted successfully",
    bid_cancelled_successfully: "Bid has been cancelled successfully",

    no_product_header: "(No Products)",
    no_product_subheader: "You have not created any product yet, upload some",

    leave_the_page_confirmation_header_text: "Attention!",
    leave_the_page_confirmation_suheader_text:
      "Are you sure you want to leave the page ?",

    // Variation Details
    variant_details_has_variant_confirmation_header_text: "Please note",
    variant_details_has_variant_confirmation_suheader_text:
      "Changing this option will lead to delete variation",

    variation_details_max_duration_error_text:
      "Maximum video duration limit is : # seconds",
    variation_details_max_image_size_limit_in_bytes_text:
      "Maximum image size limit is : # byte",

    variation_details_file_error_text:
      "Every variation should have at least one image or video",

    // Upload Product [Product Variants Tab]
    product_variation_name: "Variation name",
    product_noof_variants: "No. of variants",
    enter_the_variant_details: "Enter the variant details",

    isVariationDeatilsopen_name: "Name",
    isVariationDeatilsopen_priceunit: "Price Per Unit",
    isVariationDeatilsopen_startingbid: "Starting bid",
    isVariationDeatilsopen_qty: "Qty",
    isVariationDeatilsopen_unit: "Unit",
    variant_images_max: "Variant images (5max)",
    add_more_variation_btn_text: "Add More Variation",
    update_variation_details_btn_text: "Update Variation Details",
    variation_details: "Variation Details",
    quantity: "Quantity",
    variation_details_name: "Name",
    variation_details_price_units_validation:
      "Required Price ,Units and Quantity of each variations",
    no_variation_found: "No Variation Found",
    clear_variant_data: "Clear variant's data",
    photos_videos: "Photos/Videos",
    toggling_default_photo:
      "(*Toggling Default Photo and Variation would remove previous media)",
    auction_product_error: "Please select start date to relist auction product",
    draft_product_error: "Please add product name to add draft",

    delete_variant: "Delete Variant?",
    delete_variant_confirm_text:
      "The action will remove all related data and media",
    media_delete_sucess: "Media deleted successfully",

    update_variation_details_error_text: "Some of your variations are missing!",

    media_delete_confirmation_header_text: "Delete media",
    media_delete_confirmation_suheader_text:
      "Are you sure you wish to delete it? This action cannot be undone",

    product_cat_sub_cateegory_alert:
      "Products needs to be lined with category and subcategory",
    product_variation_alert:
      "Please add at least one variation of your product",

    product_delete_confirmation_header_text: "Delete Product?",
    product_delete_confirmation_suheader_text:
      "Are you sure to delete product?",

    cancel_bid_confirmation_header_text: "Cancel bid?",
    cancel_bid_confirmation_suheader_text: "Are you sure to Cancel bid?",

    actualprice_greater_than_discountprice_alert:
      "Discounted price cannot be greater than the actual price! Please check variant details under product variant tab",

    upload_product_enable_best_price_alert_text:
      "Attention! Your items have a potential pricing error. Please review and confirm or update your price. Continue with set price |  Review price",
    upload_product_variation_notadded_alert:
      "Please Add product variant details",

    upload_products_error_all_required_fields: "Please add all required fields",
    upload_products_error_for_auction_product:
      "Incremental Rate, Auction Duration and Minimum reserve Price are required",
    upload_products_error_variants_and_specifications:
      "Please add all Variant and Required Specification Details",
    upload_products_error_variants_details: "Please add all Variant Details",
    upload_products_error_shipping_details: "Please Add All shipping Details",

    // Product Fee
    product_fee: "Product Fee",
    confirm_btn_text: "Confirm",
    product_description: "Product Description",
    specification: "Specification",
    view_all_details: "View All Details",
    category: "Category",
    subcategory: "Sub Category",
    selected_variant: "Selected Variant",
    condition: "Condition",
    listing_fee: "Listing Fee",
    product_listing_price: "Product Listing Price",
    product_Sent_approval_text:
      "Your product will be sent for approval and the listing will be valid till 1 month from the time of approval",
    tax_inclusive_text: "Inclusive of all taxes and charges",

    product_fees_listing_detailed_variants_thead_text1: "Name",
    product_fees_listing_detailed_variants_thead_text2: "Price",

    // Product Details
    product_details: "Product Details",
    return_policy: "Return Policy",
    return_or_replacement: "Return/Replacement",
    questions_answers: "Questions & Answers",
    questions_answers_subtext: "Want to Know More About the Product?",
    ask_a_question: "Ask a Question",
    answer: "Answer",
    view_all_questions_answers: "View All Questions & Answers",
    type_your_question: "Type your question",
    type_your_answer: "Type your answer",
    your_answer_submitted_successfully: "Your answer submitted successfully",
    your_question_submitted_successfully:
      "Your question submitted successfully",
    your_comment_submitted_successfully: "Your comment submitted successfully",
    your_comment_updated_successfully: "Your comment updated successfully",
    commnet_deleted_successfully: "Comment deleted successfully",
    comments: "Comments",
    mentions: "Mentions",
    type_your_comment: "Type your comment",
    no_comment_header: "No Comment(s)",
    no_comment_yet: "There's no Comment(s) yet",
    relist: "Relist",
    unlist: "Unlist",
    payment_status: "Payment status",
    listing_status: "Listing status",
    approved_with_expairy_date: "Approved (Expiry date - #)",

    // Featured / Unfeatured
    fetured_success: "This product is now listed as featured!",
    unfetured_success: "This product is now removed from featured list!",
    fetured: "Featured",
    unfetured: "Unfeatured",
    isfetured: "Featured ?",
    isunfetured: "Unfeatured ?",

    // Question and answer
    report_confirm_questions_answers_title: "Report question",
    report_confirm_questions_answers_message:
      "Are you sure you want to report this question?",

    // Comment
    report_confirm_comment_title: "Report comment",
    report_confirm_comment_message:
      "Are you sure you want to report this comment?",

    // Payment status
    payment_approve: "Paid",
    payment_pending: "Pending",
    payment_failed: "Failed",
    payment_declined: "Declined",
    payment_pending_confirmation_bank: "Pending confirmation from bank",
    payment_cancelled: "Cancelled",

    repayment_screen_header_text: "Pay now",

    productfee_screen_listing_note_text:
      "Your product will be sent for approval and the listing will be valid till 1 month from the time of approval",

    // Product Management Variants Listing
    bids: "Bids",
    expired: "Expired",
    ended: "Ended",
    active: "Active",
    deactivate: "Deactivate",
    title: "Title",
    pricing: "Pricing",
    status: "Status",
    action: "Action",

    starts_in: "Starts in",
    ends_in: "Ends in",
    bid_not_started: "Bid not Started",
    bid_over: "Ended",

    buying_history_bids: "bids",
    buying_history_bids_placed: "Bids Placed",
    buying_history_no_bids: "No bids(s) placed yet",
    buying_history_last_bid: "Last bid placed ",

    // Product upload [Item Specification Tab]
    no_item_specification_found: "No Item specification found",

    add_product: "Add Product",

    // Language
    change_language: "Change Language",

    // Loader Texts
    preparing_sales_management: "Preparing Sales Management...",
    preparing_sales_neworders: "Preparing New Orders...",
    preparing_sales_deliveredorders: "Preparing Delivered Orders...",
    preparing_sales_salescancreturepl:
      "Preparing Cancelled/Returned/Replaced Orders...",
    preparing_sales_negotiationsoffers: "Preparing Negotiations Offers...",
    preparing_sales_notifications: "Preparing Notifications...",
    preparing_messages: "Preparing Messages...",

    preparing_profile_settings: "Preparing Profile Settings...",
    preparing_account_settings: "Preparing Account Settings...",
    preparing_language: "Preparing Language...",
    preparing_currency: "Preparing Currency...",
    preparing_product_management: "Preparing Product Management...",
    preparing_product_details: "Preparing Product Details...",
    preparing_revenue_reports: "Preparing Revenue Reports...",
    preparing_sales_reports: "Preparing Sales Reports...",
    preparing_coupons_management: "Preparing Coupons Management...",
    preparing_discounts_management: "Preparing Discounts Management...",
    preparing_ratings_reviews: "Preparing Ratings & Reviews...",
    preparing_faq_support: "Preparing FAQ & Support...",
    preparing_legal_docs: "Preparing Legal Docs...",
    preparing_transaction_history: "Preparing Transaction History...",
    preparing_app_suggestions: "Preparing App Suggestions...",
    preparing_store_verification: "Preparing Store Verification...",
    preparing_store_information: "Preparing Store Information...",
    preparing_my_followers: "Preparing My Followers...",
    preparing_profile_information: "Preparing Profile Information...",
    preparing_product_comments: "Preparing Product Comments...",
    preparing_product_likes: "Preparing Product Likes...",
    preparing_qa: "Preparing Questions and answers...",

    // Saler Management
    saler_tab_text_alltime: "All Time",
    saler_tab_text_daily: "Daily",
    saler_tab_text_weekly: "Weekly",
    saler_tab_text_monthly: "Monthly",
    saler_btn_text_viewreport: "View Report",
    preparing_vendor_statistics: "Preparing Vendor Statistics",

    canceled_orders: "Cancelled Orders",
    new_orders: "New Orders",
    open_orders: "Open Orders",
    delivered_orders: "Delivered Orders",
    negotiation_offers: "Negotiation Offers",
    delivered: "Delivered",
    clubbed: "Replace / Return / Cancel",
    negotiation: "Negotiation",
    replace: "Replace",
    total_earnings: "Total Earnings",
    all: "All",

    tab_text_cancelled: "Cancelled",
    tab_text_returned: "Returned",
    tab_text_returns: "Returns",
    tab_text_replaced: "Replaced",

    alert_head_cancel: "Cancel",
    alert_order_cancel_subtext: "Are you sure do you want cancel?",
    alert_refund_initiate_subtext:
      "Are you sure do you want to initiate a refund?",

    // New Orders  / Delivered Orders
    copy_email: "Copy Email",
    delivery_to: "Delivery To",
    order_id: "Order ID",
    image: "Image",
    order_date: "Date",
    order_date1: "Order date",
    order_by: "Order by",
    order_total: "Order total",
    order_details: "Order Details",
    sale_status: "Sale Status",
    sale_contact_no: "Contact No",
    email_buyer: "Email Buyer",
    message_buyer: "Message Buyer",
    qty: "Qty",
    feedback_received: "Feedback received",
    feedback_pending: "Feedback pending",
    price_details: "Price Details",
    original_product: "Orginal Product",
    exchanged_product: "Exchanged Product",
    paid_amount: "Paid Amount",
    refund_Amount: "Refund Amount",
    product_price: "Product Price",
    in_transit: "In Transit",
    shipping_fee: "Shipping Fee",
    tax_charges: "Tax & Charges",
    payment_method: "Payment Method",
    total: "Total",
    update_order_status: "Update order status",
    order_details_re_ship_item_text: "Re-ship item",
    product_shipping_text:
      "Product shipping (Add tracking number before updating)",
    tracking_number: "Tracking number",
    mark_as_shipped: "Mark as shipped",
    mark_delivered: "Mark Delivered",
    mark_received: "Mark Received",

    cancellation_reason: "Cancellation reason",
    refund_reject_reason: "Refund rejected reason",
    return_replace_reason: "Return / replace reason",

    return_reason: "Return Reason",
    replace_reason: "Replace Reason",
    cancel_reason: "Cancel reason",
    cancel_reason_desc: "please specify your cancellation reason here",
    enter_reason: "Enter reason",
    cancel_order: "Cancel Order",
    download_invoice: "Download Invoice",
    cancel_reason_error_text: "Missing cancel reason",
    cancel_reason_error_subtext: "Please Input cancel reason",
    tracking_number_error_text: "Tracking number is missing",
    tracking_number_error_special_charactertext:
      "Tracking number cannot have special characters",
    tracking_number_error_subtext: "Please Input tracking number",

    cancel_reason_successfully: "The order has been cancelled!",
    refund_initiated_successfully:
      "The refund has been initiated successfully!",
    shipping_status: "Shipping Status",
    marked_asshipped_successfully: "Marked as Shipped successfully",
    marked_asreshipped_successfully: "Marked as Re-shipped successfully",
    marked_asreshippedfail:
      "Marked as Re-shipped failed! Partner didn't accept the order",
    mark_delivered_successfully: "Mark as delivered successfully",
    initiate_pickup_successfully: "Initiate pickup successfully",
    marked_received_successfully: "Marked as received successfully",

    pickup_confirmed_successfully: "Pickup confirmed successfully",

    // Status Array Text
    order_negotiated: "Negotiated",
    order_closed: "Closed",
    order_initiated: "Initiated",
    order_responded: "Responded",
    order_requested: "Requested",

    order_delivered: "Order Delivered",
    order_dispatched: "Order Dispatched",
    order_Placed: "Order Placed",
    order_cancelled: "Order Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Initiate Pickup",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    view_linked_order: "View Linked Order",

    refunded: "Refunded",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Replace Delivered",
    replace_reshippped: "Replace re-shipped",

    confirm_pickeup: "Confirm Pickup",

    cancel_refund: "Cancel refund initiated",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",

    initiate_replace_pickup: "Initiate Replacement Pickup",
    return_cancelled: "Returned Cancelled",
    replacement_picked: "Replacement picked up",
    replacement_received: "Replacement received",
    refund_processing: "Refund Processing",

    // Negotiation
    negotiation_status: "Status",
    negotiation_accept: "Accept",
    negotiation_reject: "Reject",

    shipping_and_taxes_extra: "Shipping and Taxes extra",

    // Dailies and Weeks
    this_week: "This Week",
    today: "Today",
    last_week: "Last Week",
    weeks_back: "weeks back",
    after: "After",
    weeks: "weeks",
    next_week: "Next Week",
    yesterday: "Yesterday",

    // Months Name
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",

    /**** Discount Management ****/
    discount_thead_title: "Title",
    discount_thead_discount: "Discount",
    discount_thead_applied_on: "Applied On",
    discount_thead_valide_until: "Valid Until",
    discount_thead_area: "Area",
    discount_thead_percentage: "Percentage",
    discount_applicable_rule: "Discount applicable rule",
    discount_applicable_rule_subtext:
      "Select the rule which discount is applicable",
    select_discount_area: "Select discount area",
    discount_product_category: "Product category",
    discount_product_subcategory: "Product sub-category",
    discount_product: "Product",
    discount_added_successfully: "Discount added successfully",
    discount_type_require: "Choose a discount type",
    similar_discount_already_available: "Similar discount already available",
    select_validity_from: "Select validity from",
    select_validity_until: "Select validity until",
    discount_percentage_not_valide:
      "Not a valid input for 'Discount Percentage'",
    discount_product_category_error_text: `Choose from 'Product category' as you selected Category as 'Discount Area'`,
    discount_product_subcategory_error_text: `Choose from 'Product sub-category' as you selected Subcategory as 'Discount Area'`,
    discount_product_error_text: `Choose from 'Product' as you selected Product as 'Discount Area'`,
    discount_int_validation: "Discount must be greater than 0",
    discount_invalid: "Invalid discount value !",

    discount_title_required: "Discount title is mandatory!",

    discount_accept_confirm_title: "Accept ?",
    discount_accept_confirm_message:
      "Are you sure that you want to accept this discount?",
    discount_reject_confirm_title: "Reject ?",
    discount_reject_confirm_message:
      "Are you sure that you want to reject this discount?",

    discount_accepted_success: "Discount has been accepted!",
    discount_rejected_success: "Discount has been rejected!",
    discount_activated_success: "Activated Successfully",
    discount_deactivated_success: "Deactivated Successfully",

    /**** Coupons Management ****/
    add_new_coupons: "Add New Coupon",
    coupons_tab_text_active: "Active",
    coupons_tab_text_inactive: "Inactive",
    coupons_thead_coupon: "Coupon",
    coupons_thead_coupon_code: "Coupon Code",
    coupons_thead_above: "above",
    edit_coupon: "Edit Coupon",
    update_coupon: "Update Coupon",
    coupon_title: "Coupon title",
    enter_coupon_title: "Enter coupon title",
    coupon_coupon_desc: "Coupon description",
    enter_coupon_desc: "Enter coupon description",
    coupon_code: "Coupon code",
    enter_coupon_code: "Enter coupon code",
    coupon_generate_text: "Generate",
    coupon_applicable_rule: "Coupon applicable rule",
    coupon_applicable_rule_subtext:
      "Select the rule which coupon is applicable",
    select_criteria: "Select criteria",
    applicable_time: "Applicable time",
    applicable_time_text: "Number of time applicable",
    cart_amount: "Cart amount",
    enter_cart_amount: "Enter cart amount",
    coupon_added_successfully: "Coupon added successfully",
    coupon_updated_successfully: "Coupon Updated successfully",

    deactivate_confirmation_header_text: "Deactivate coupon",
    deactivate_confirmation_suheader_text:
      "Are you sure you wish to deactivate it? This action cannot be undone",
    coupon_deactivated_successfully: "Coupon deactivated successfully",

    delete_confirmation_header_text: "Delete coupon",
    delete_confirmation_suheader_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    coupon_deleted_successfully: "Coupon Deleted successfully",

    validity_end_date_and_greater_date_validation_text:
      "Validity end date must be greater than Validity starting date !Invalid date range!",

    /**** Profile Settings ****/
    profile_settings_list_text1: "Profile Information",
    profile_settings_list_subtext1: "Manage vendor account",

    profile_settings_list_text2: "Store Information",
    profile_settings_list_subtext2: "Manage store's public profile",

    profile_settings_list_text3: "Store Verification",
    profile_settings_list_subtext3: "Get your store verified on shopez",

    profile_settings_list_text4: "Account Settings",
    profile_settings_list_subtext4: "Manage payment accounts",

    profile_settings_list_text5: "My Followers",
    profile_settings_list_subtext5: "See who follows you",

    update_account: "Update Account",
    profile_settings_actsettings_terms_text:
      "All your earnings are collected on shopez and will be sent to you as per 7 days cycle.Please add your bank account details where you would like to receive the payments",

    beneficiary_name: "Beneficiary Name",
    beneficiary_full_name: "Beneficiary full name",

    bank_name: "Bank Name",
    beneficiary_bank_name: "Beneficiary bank name",

    act_settings_act_number_lbl: "Account Number/IBAN",
    act_settings_act_number_placeholder: "Beneficiary account no./IBAN",

    act_settings_swift_code_lbl: "Swift code/Bic code/sort code",
    act_settings_swift_code_placeholder: "Swift code/Bic code/sort code",
    act_settings_benificiary_contactno: "Benificiary Contact No.",

    select_country: "Select Country",
    country_code: "Country Code",
    country: "Country",

    building_no: "Building number",
    enter_building_no: "Enter building number ",

    zone_no: "Zone number ",
    enter_zone_no: "Enter zone number ",

    street_no: "Street number",
    enter_street_no: "Enter street number ",
    // Changed
    // pobox_no: "Unit number / P.O. Box",
    // enter_pobox_no: "Enter Unit number / P.O. Box",
    unit_no: "Unit number",
    enter_unit_no: "Enter Unit number",
    pobox_no: "P.O. Box",
    enter_pobox_no: "enter p.o box",

    act_settings_contact_number_lbl: "Contact Number",
    act_settings_contact_number_placeholder:
      "Beneficiary contact no.(optional)",

    // My Followers
    text_you: "You",
    btn_follow: "Follow",
    btn_unfollow: "Unfollow",

    profile_settings_myfollowers_sheardwhishlist: "Shared Wishlist",
    profile_settings_myfollowers_Following: "Following",

    no_shared_wishlist: "No Shared wishlist(s)",
    user_havenot_shared_wishlist: "The user haven't shared any wishlist",

    no_followers_yet: "No Followers yet",
    no_following: "No Following(s)",
    user_not_following_user: "The user is not following any user",

    items: "Items",

    password_updated_successfully:
      "Password updated successfully ! Please Re-Login !",

    report_as_abusive: "Report as abusive",

    report_confirm_consumer_title: "Report user",
    report_confirm_consumer_message:
      "Are you sure you want to report this user?",
    report_success: "You have successfully reported",

    // Profile Information
    update_profile: "Update Profile",
    get_verified: "Get verified",
    no_rating: "No Rating",
    your_profile_is_unverified: "Your profile is Unverified",
    profile_updated_successfully: "Profile information updated successfully",
    add_phone_number: "Add phone number",
    update_password: "Update Password",
    update_phone_number: "Update Phone Number",
    send_code_success: "OTP sent to given Number",
    otp_has_been_sent_success: "OTP has been sent",
    verification_code_mismatch: "Verification code does not match",
    otp_verified_successfully: "OTP verified successfully",
    otp_not_verified: "OTP not verified",
    mobile_not_verified: "You have not verified mobile number yet.",
    phone_updated_successfully: "Phone number updated successfully",
    verify_modal_errror_addemail: "Please add email",
    verify_modal_code_error_mismatched: "Code mismatched,not verified",
    verify_modal_code_error_notverified: "You have not verified email",
    verify_modal_success_verificationcode:
      "Verification code sent to given email",
    profile_verify_email: "Verification email has been sent to your email id",

    // Store Information
    update_store_info: "Update store info",
    contact_person_label: "Contact Person's",
    contact_person_placeholder: "Contact person's name",
    contact_number_label: "Contact number",
    another_number_label: "Another number",
    add_another_number_btn: "Add another number",
    select_away_time: "Select away time",
    store_info_start_date_label: "Start date",
    store_info_end_date_label: "End date",
    set_automatic_response_text: "Set Automatic response",
    set_automatic_response_subtext:
      "(Automatic response is for anyone who contacts through messages during time away)",
    set_automatic_response_placeholder: "hey hello how's your day going hey",
    store_updated_successfully: "Store information updated successfully",
    delete_contact_confirm: "Delete Contact Number",
    delete_contact_number_confirm_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    contact_deleted_successfully: "Contact Deleted successfully",
    plus_add_another_number: "+ Add another number",
    location_header: "Location",
    store_location_text: "Store location",
    search_places: "Search Places ...",
    search_address: "Search Address",
    search_your_location: "Search your location",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    address: "Address",
    current_password: "Current Password",
    confirm_password: "Confirm Password",
    security_code: "Security Code",
    phone_number: "Phone Number",
    update_phone_number_text: "Please enter the number you wish to update",
    current_number: "Current Number",
    send_code: "Send Code",
    phone_number_verfication_text:
      "Please enter the verification code that we have sent to your phone number.",
    verification_code_mismatch: "Verification code does not match",
    store_location_verfication_text: "Please enter store location",

    store_image_upload_icon_notes1: "1920 px wide and 1080 px tall recommended",
    store_image_upload_icon_notes2: "Adjust the image position while uploading",

    store_information_upload_store_image_warning:
      "Please upload a store image or Click on cancel upload button to proceed further !",
    store_information_phoneno_error_alert: "Please add proper phone number(s)",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document:
      "Commercial registration document(Supports documents and pdf's only)",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed(Supports documents and pdf's only)",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    vendor_verified_text: "You are an approved and verified User",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only doc and pdf file",
    attachfile_to_update_error_text: "Attach files to update",

    // Account Settings
    check_digits: "Check Digits",
    bank_code: "Bank Code",
    account_number: "Bank account number",
    bank_account_number_error_text: "digits and capital letters only",
    add_account: "Add account",
    account_add_success: "Account added successfully!",
    account_update_success: "Account updated successfully!",

    // App Suggestions
    app_suggestions_header_text: "Select your suggestions",

    app_suggestions_lbl_email_address: "Email Address",
    app_suggestions_plshldr_email_address: "Email Address",

    app_suggestions_lbl_suggestion_desc: "Suggestion Description",
    app_suggestions_plshldr_suggestion_desc: "Add Description",

    app_suggestions_footer_text:
      "Your suggestion will be emailed to Shopez and Necessary actions will be taken",
    send_suggestion: "Send Suggestion",

    file_attached: "file attached",
    files_attached: "files attached",

    bug: "Bug",
    app_feature: "App Feature",
    app_suggestions_submit_success: "Your suggestions submitted successfully",

    // FAQ & Support
    raise_a_ticket: "Raise a Ticket",
    raise_question_error: "Question field is required!",
    faq_footer_head_text: "Need more help? Contact Us",
    call_us: "Call Us:",
    write_to_us: "Write to us:",
    raise_tacket_sub_header_text:
      "If you haven't got the answer to your problems, please write to us.",
    write_your_problems: "Write your problems",
    write_your_questions: "Write your question?",
    search_text_faq_support: "Search our FAQs",

    faq_support_submit_success: "Your concern was submitted successfully",

    // Legal Docs
    legal_docs_list_text1: "Terms & Conditions",
    legal_docs_list_subtext1: "View our terms & conditions",
    legal_docs_accept_tnc_text:
      "I accept the Terms and Conditions terms & conditions",
    please_accept_tnc: "Please check the terms & conditions!",

    legal_docs_list_text2: "Privacy Policy",
    legal_docs_list_subtext2: "View our privacy policy",

    legal_docs_list_text3: "Your Sales Policy",
    legal_docs_list_subtext3:
      "View and update your return / exchange / bidding policy",

    // legal_docs_list_text4: "Shopez Sales Policy",
    // legal_docs_list_subtext4: "View our sales policy",
    legal_docs_list_text4: "About Us",
    legal_docs_list_subtext4: "View about us",
    sales_policy_requires_en: "Sales Policy Required For English!",
    sales_policy_requires_ar: "Sales Policy Required For Arabic!",

    sales_policy_requires_text: "Sales policy is mandatory",

    tnc_email_me_a_copy: "Email me a copy of Terms & Conditions",
    email_not_sent_msg:
      "Email not sent, Please check the email or you internet",
    copy_emil_sent_successfully_msg:
      "A copy of T&C has been sent to your email!",

    // Revenue Reports
    revenue_reports_list_text1: "Revenue Report",

    revenue_reports_list_text2: "Sales Report",

    revenue_reports_list_text3: "Transaction History",

    download_report: "Download Report",
    sales_report_footer_text: "items Total Sold",
    revenue_report_footer_text: "Total Earning",

    qty_sold: "Qty Sold",
    in_stock: "In Stock",
    deal_amount: "Deal amount",
    variant: "Variant",

    transaction_id: "Transaction id",
    transaction_amount: "Transaction Amount",
    transaction_date: "Transaction Date",
    transaction_product: "Product",
    transaction_type: "Type",
    transaction_status: "Status",

    search_by_transaction_id: "Search by transaction id",
    revenue_report_revenue_search_by:
      "Search by Product Name, Descriptions or Specifications",
    revenue_report_sales_search_by:
      "Search by Product Name, Descriptions or Specifications",

    sales_policy_successfully: "Sales Policy updated successfully",

    // Rating & Review
    play_store: "Play Store",
    google_play: "Google Play",
    app_store: "App Store",
    rate_us_text: "Rate us on play store and app store",

    //Change Currency
    currency_of_preference: "Currency of Preference",
    currency_of_preference_note: "You can not revert this choice",
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    // repayment
    repayment_nourlfound: "No payment url found",
    repayment_paymentfailed: "Payment Failed , something went wrong",

    // Language
    language_of_preference: "Language of Preference",
  },
  ar: {
    expired: "منتهية الصلاحية",
    repayment_btn: "السداد",
    something_went_wrong: "هناك خطأ ما",
    api_success_status: "نجح الطلب",

    // Freeze
    account_freezed: "صافي المصرفية",

    // Units
    pcs: "جهاز كمبيوتر شخصى",
    nos: "لا",

    // Payment methods
    card: "بطاقة",
    cash: "نقدي",
    upi: "يو بي آي",
    netbanking: "صافي المصرفية",

    // Address type
    home: "مسكن",
    office: "مكتب",
    other: "آخر",

    // Negotiation status
    negotiating: "التفاوض",
    accepted: "وافقت",
    rejected: "مرفوض",

    categories: "فئات",
    subcategories: "الفئات الفرعية",

    account_temporarily_unavailable: "الحساب غير متاح مؤقتا",
    temporarily_unavailable: "الحساب غير متاح مؤقتا",
    sku: "SKU",
    skip: "يتخطى",
    accept: "قبول",
    reject: "رفض",
    cancel: "إلغاء",
    cancel_upload: "إلغاء الرفع",
    back: "خلف",
    star: "نجمة",
    copied: "نسخ",
    not_applicable_in_short: "غير متاح",
    cancelled: "ألغيت",
    resend: "إعادة إرسال",
    not_a_valid_phone_number: "رقم هاتف غير صالح",
    invalid_quantity: "كمية غير صالحة",
    invalid_price: "تم تقديم سعر غير صالح",
    report: "نقل",
    already_reported: "ذكرت بالفعل",
    common_fail_message: "هناك خطأ ما",
    invalid_length: "غير صالح",
    invalid: "غير صالح",
    invalid_date_range: "النطاق الزمني غير صالح",
    invalid_number: "رقم غير صالح",
    ok: "موافق",
    visit_again: "زورني مره اخرى",
    feature_under_construction: "الميزة قيد الإنشاء",
    invalid_email: "بريد إلكتروني خاطئ",
    email: "بريد إلكتروني",
    email_phone: "البريد الإلكتروني / الهاتف",
    password: "كلمه السر",
    signin: "تسجيل الدخول",
    forgot_your_password: "نسيت رقمك السري؟",
    create_a_new_account: "انشاء حساب جديد",
    signup_as_vendor: "سجل كمورد",
    select_the_type_of_business: "حدد نوع العمل التجاري الذي تملكه",
    or_use_social: "أو استخدم وسائل التواصل الاجتماعي",
    connect_with_apple: "تواصل مع Apple",
    connect_with_facebook: "تواصل مع الفيسبوك",
    connect_with_google: "تواصل مع جوجل",
    connect_with_twitter: "تواصل مع Twitter",
    company: "شركة",
    home_business: "الأعمال المنزلية",
    personal_items: "اغراض شخصية",
    consumer: "مستهلك",
    login: "تسجيل الدخول",
    signout: "خروج",
    login_error_other_than_vendor: "غير مسموح للبائع بتسجيل الدخول!",
    login_form_validation_email_required:
      "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك!",
    login_form_validation_password_required: "من فضلك أدخل رقمك السري!",
    login_user_not_registered: "حسابك غير مسجل معنا!",
    login_user_session_expired: "انتهت جلسة الوصول إلى حسابك!",
    invalid_email_password: "بريد أو كلمة مرورغير صحيحة!",
    invalid_email_phone_password:
      "البريد الإلكتروني / الهاتف أو كلمة المرور غير صحيحة!",
    invalid_user: "مستخدم غير صالح!",
    button_logout: "تسجيل خروج",
    button_yes: "نعم",
    button_no: "لا",
    logout_want_to_logout: "هل تريد حقًا تسجيل الخروج؟",
    email_address: "عنوان البريد الإلكتروني",
    select: "تحديد",
    cancel_bid: "إلغاء العطاء",
    no_result_found: "لم يتم العثور على نتائج",
    geocoder_failed_due_to: "فشل المكود الجغرافي بسبب",
    digit_one: "واحد",
    digit_two: "إثنان",
    drop_or_select: "إسقاط أو تحديد",
    name: "اسم",
    gender: "جنس تذكير أو تأنيث",
    male: "الذكر",
    female: "أنثى",
    rather_not_say: "الأفضل أن لا يقال",
    verify: "التحقق",
    public: "عام",
    private: "نشر",
    percent_sign: "٪",
    discount_using: "الخصم باستخدام",
    might_apply: "قد تنطبق",
    shipping: "شحن",
    off: "إيقاف",
    connect_social_media_text: "تواصل مع وسائل التواصل الاجتماعي",
    or: "أو",
    select_file: "انقر لتحميل الصورة",
    button_crop_save: "اقتصاص وحفظ",
    button_upload_new_image: "تحميل صورة جديدة",
    login_sub_haeder_text: "أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول",
    create_a_account: "قم بإنشاء حساب",
    login_success: "لقد قمت بتسجيل الدخول بنجاح",
    required: "مطلوب",
    anonymous: "مجهول",
    verify_email_button: "التحقق من البريد الإلكتروني",
    enter_email_want_to_change:
      "الرجاء إدخال عنوان البريد الإلكتروني الذي تريد تغييره!",
    english: "إنجليزي",
    arabic: "عربي",
    no_found: "لم يوجد !",

    // Review and rating common component
    rating_and: "تصنيف و",
    review_and_rating_text1: "1. تصنيف المنتج",
    review_and_rating_text2: "2. ما مدى دقة وصف المنتج؟",
    review_and_rating_text3: "3. ما مدى رضاك ​​عن اتصالات سيلي؟",
    review_and_rating_text4: "4- ما مدى سرعة شحن البائع للسلعة؟",

    report_confirm_review_title: "تقرير الاستعراض",
    report_confirm_review_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا الاستعراض؟",

    out_of_five: "خمسة بعيدا عن المكان",

    // Sign Up/In/Register
    signup: "اشتراك",
    signup_sub_header_text: "لنبدأ بإنشاء حساب جديد",
    identif_your_business: "حدد نشاطك التجاري",
    password_reset_link_text:
      "يرجى إدخال بريدك الإلكتروني المسجل وسنرسل لك رابط إعادة تعيين كلمة المرور",
    password_reset_link_alert_text:
      "تم إرسال رابط إعادة تعيين كلمة المرور بنجاح",
    continue: "يكمل",
    business_name: "الاسم التجاري",
    nick_name: "اسم الشهرة",
    date_of_birth: "تاريخ الولادة",
    password_dont_match: "كلتا كلمة المرور غير متطابقتين",
    account_exist_alert_text: "الحساب موجود بالفعل ، يحاول تسجيل الدخول",

    verification_header_text1: "التحقق من البريد الإلكتروني",
    verification_subheader_text1: "تحقق من عنوان بريدك الإلكتروني",
    verification_header_text2: "التحقق",
    verification_subheader_text2: "اكد على رقم هاتفك او جوالك",
    verification_header_text3: "تحميل المستندات",
    verification_subheader_text3:
      "قبل أن تتمكن من البدء في استخدام Shopez ، يرجى تحميل ما يلي:",

    verification_email_note_text1:
      "حتى نتمكن من تنشيط حسابك وتأخر تسجيل الدخول. نحتاج أولاً إلى التحقق من أن عنوان البريد الإلكتروني الذي قدمته لنا يخصك. لقد قمنا بإرسال بريد إلكتروني إلى (",
    verification_email_note_text2: ") الذي يحتوي على إعجاب يجب النقر فوق ...",

    verification_email_note_text3:
      "لقد أدخلت معرف البريد الإلكتروني الخطأ؟ يمكنك تغيير البريد الإلكتروني الآن",

    verification_email_note_text4:
      "تم تحديث بريدك الإلكتروني الجديد بنجاح! يرجى إعادة تسجيل الدخول للتحقق من بريدك الإلكتروني الجديد.",

    verification_email_resend_btn: "ألم تتلق البريد الإلكتروني؟ إعادة إرسال",
    verification_phone_change_number: "تغيير رقم؟",
    verification_phone_change_number_field_name: "تغيير رقم",
    verification_phone_change_number_field_placeholder: "أدخل رقمك",

    enter_wrong_emailid: "لقد أدخلت معرف البريد الإلكتروني الخطأ",
    email_varified_successfully: "تم التحقق من البريد الإلكتروني بنجاح",
    phone_varified_successfully: "تم التحقق من رقم الهاتف بنجاح",
    resent_email_alert_text:
      "تم إرسال البريد الإلكتروني إلى عنوان البريد الإلكتروني الخاص بالتسجيل!",
    email_exist_alert_text: "البريد الالكتروني موجود مسبقا ! أدخل آخر",

    business_name_not_available: "عذرا ، الاسم التجاري غير متوفر!",
    nick_name_not_available: "عذرا ، الاسم المستعار غير متوفر!",

    // Side Bar
    sales_management: "إدارة المبيعات",
    profile_settings: "إعدادات الملف الشخصي",
    projects: "المشاريع",
    language: "لغة",
    currency: "عملة",
    product_management: "ادارة المنتج",
    revenue_reports: "تقارير الإيرادات",
    coupons_management: "إدارة الكوبونات",
    discounts_management: "إدارة الخصومات",
    ratings_reviews: "التقييمات والمراجعات",
    faq_support: "التعليمات والدعم",
    legal_docs: "المستندات القانونية",
    app_suggestions: "اقتراحات التطبيق",

    // Notification
    notifications: "إشعارات",
    read_all: "اقرأ كل شيء",
    update: "تحديث",
    mark_all_messages_as_read: "وضع علامة على جميع الرسائل كمقروءة",
    no_notification_header: "لا إشعارات)",
    no_notification_subheader: "لم يتم تلقي أي إشعار حتى الآن",

    // Messages
    message_modal_header_text: "رسائل",
    send_message: "أرسل رسالة",
    type_your_message: "اكتب رسالتك",
    no_message_header: "لا توجد رسائل",
    no_message_subheader: "لم يتم استلام أي رسالة حتى الآن",
    message_tab_product: "منتجات",
    message_tab_order: "ترتيب",

    // Forgot Password Modal
    forgot_password: "هل نسيت كلمة السر",
    forgot_password_reset_link_text:
      "يرجى إدخال البريد الإلكتروني المسجل وسوف نرسل لك رابط إعادة تعيين كلمة المرور",
    send_reset_link: "أرسل رابط إعادة التعيين",
    close: "يغلق",
    email_id_not_register_alert: "معرف البريد الإلكتروني غير مسجل لدى Shopez",
    edit_product: "تحرير المنتج",
    update_product: "تحديث المنتج",
    delete_product: "حذف المنتج",

    // Sales Management
    sales_overview: "نظرة عامة على المبيعات",
    sales_report: "تقرير المبيعات",

    // Product Management
    setup_store_header_text: "Setup Store",
    setup_store_setup_btn_text: "Setup",
    setup_store_subheader_text:
      "Please setup our Store before adding a Product",
    product_list_search_by: "البحث عن طريق اسم المنتج أو الأوصاف أو المواصفات",
    sort: "فرز",
    filter: "منقي",
    list: "قائمة",
    grid: "جريد",
    search: "يبحث",
    upload_product: "تحميل المنتج",
    search_product: "البحث عن المنتج",
    upload_new: "تحميل جديد",
    waiting_for_approval: "بانتظار الموافقة",
    rejected: "مرفوض",
    sold: "تم البيع",
    purchased: "تم شراؤها",
    reviews: "المراجعات",
    likes: "الإعجابات",
    en: "إن",
    ar: "أر",
    prooduct_reviews_rating: "مراجعات المنتج وتقييمه",
    no_reviews: "لم يتم تقديم تعليقات",

    about_the_product: "عن المنتج",
    product_variation: "اختلاف المنتج",
    product_specifications: "مواصفات المنتج",
    item_specifications: "مواصفات الصنف",
    sales_formate_and_pricing: "تنسيق المبيعات والتسعير",
    shipping_type_and_service: "نوع الشحن والخدمة",
    set_preferences: "حدد التفضيلات",
    select_a_tab: "حدد علامة تبويب",
    translate_from_arabic: "ترجمة من العربية",
    translate_from_english: "ترجمة من اللغة الإنجليزية",
    successfully_translated_to_english: "تمت ترجمته بنجاح إلى اللغة الإنجليزية",
    successfully_translated_to_arabic: "تمت الترجمة بنجاح إلى اللغة العربية",
    enable_auto_translate:
      "تمكين الترجمة الآلية (قابل للتطبيق في حالة توفر التفاصيل بكلتا اللغتين)",
    scan_barcode_to_autofill: "مسح الرمز الشريطي للتعبئة التلقائية",
    product_title: "عنوان المنتج",
    enter_product_title: "أدخل عنوان المنتج",
    product_subtitle_desc: "وصف العنوان الفرعي للمنتج",
    enter_product_subtitle_desc: "أدخل وصف العنوان الفرعي للمنتج",
    select_product_category_subcategory: "حدد فئة المنتج والفئة الفرعية",
    product_condition: "حالة المنتج",
    select_product_condition: "حدد حالة المنتج",
    new: "جديد",
    used: "تستخدم",
    refurbished: "مجدد",
    next: "التالي",
    is_product_has_variant: "المنتج لديه متغير؟",
    product_variant_small_desc: "المنتج لديه متغير؟",
    standard_description: "الوصف القياسي",
    enter_standard_description: "أدخل الوصف القياسي",
    direct_sale: "بيع مباشر",
    auction: "مزاد علني",
    min_reserve_price: "السعر الأدنى للاحتياطي (٪)",
    enter_min_reserve_price: "أدخل الحد الأدنى لسعر الاحتياطي (٪)",
    start_date: "تاريخ البدء",
    end_date: "تاريخ الانتهاء",
    schedule_auction: "تاريخ الانتهاء",
    incremental_rate: "معدل تزايدي",
    enter_incremental_rate: "أدخل معدل تزايدي",
    accept_cash_on_delivery: "قبول الدفع نقدا عند التسليم",
    product_managemnet_istaxable_text: "هل هذا المنتج خاضع للضريبة؟",
    product_tax_text: "ضريبة المنتج",
    enter_product_tax_tex: "أدخل ضريبة المنتج (٪)",
    item_location: "موقع السلعة",
    shipping_charge: "Shipping Charge",
    shipping: "Shipping",
    specs_small_text: "Type In unique details that your buyer might look for",
    enter_item_location: "أدخل موقع العنصر",
    select_return_time: "حدد وقت العودة",

    selling_for_charity: "البيع للجمعيات الخيرية",
    make_a_donation: "التبرع",
    edit: "يحرر",
    add: "يضيف",
    ban: "المنع",
    delete: "حذف",

    return_type: "نوع الإرجاع",
    return_time: "وقت العودة",
    start_return_type: "حدد نوع الإرجاع",
    select_return_time: "حدد وقت العودة",
    handling_time: "التعامل مع الوقت",
    select_handling_time: "حدد وقت المناولة",
    refund_type: "نوع رد الأموال",
    shipping_cost_breared_by: "استرداد ولدت من قبل",
    select_shipping_cost_breared_by: "حدد حامل تكلفة الشحن",
    select_refund_type: "حدد نوع الاسترداد",
    charity_events: "المناسبات الخيرية",
    select_charity_events: "حدد الأحداث الخيرية",
    donation_percentage: "نسبة التبرع",
    set_donation_percentage: "تحديد نسبة التبرع",
    shipping_service: "خدمة الشحن",
    select_service: "اختر الخدمة",
    package: "طرد",
    select_package: "اختر الباقة",
    flat: "مسطحة",
    calculated: "محسوب",
    shipping_cost: "تكلفة الشحن",
    enter_shipping_cost: "أدخل تكلفة الشحن",
    shipping_cost_by_location: "تكلفة الشحن حسب الموقع",
    set_shipping_cost_by_location: "حدد تكلفة الشحن حسب الموقع",
    no_shipping: "لا يوجد شحن (استلام محلي فقط)",
    enter_price: "أدخل السعر",
    enter_price_placeholder: "أدخل السعر (الخصم ينطبق على النحو المحدد)",
    enable_best_offer: "تفعيل وضع التفاوض",
    enable_best_offer_text: "(سيؤدي هذا إلى تمكين التفاوض لهذا العنصر)",
    add_product_discount: "أضف خصم المنتج",
    product_discount: "خصم المنتج",
    add_new_discount: "أضف خصم جديد",
    edit_discount: "تحرير جديد",
    update_discount: "تحديث الخصم",
    delete_discount: "حذف الخصم",
    shipping_zone_pincode: "توفر الشحن (المنطقة / الرمز السري)",
    select_shipping_zone_pincode: "حدد المنطقة / الرمز السري",

    discount_title: "عنوان الخصم",
    enter_discount_title: "أدخل عنوان الخصم",
    validity_starting_date: "تاريخ بدء الصلاحية",
    select_starting_date: "حدد تاريخ البدء",
    validity_end_date: "تاريخ انتهاء الصلاحية",
    select_end_date: "حدد تاريخ الانتهاء",
    discount: "تخفيض",
    product_category_subcategory: "فئة المنتج ، الفئة الفرعية",

    category_subcategory: "الفئة والفئة الفرعية",
    done: "فعله",
    search_categories: "فئات البحث",
    product_management_modal_subtext:
      "بناءً على عنوان المنتج والعنوان الفرعي ، إليك بعض الفئات والفئات الفرعية المقترحة",
    cate_subcate_error_text: "الرجاء تحديد فئة المنتج ، الفئة الفرعية",
    product_added_successfully: "تمت إضافة المنتج بنجاح",
    product_deleted_successfully: "تم حذف المنتج بنجاح",
    product_updated_successfully: "تم تحديث المنتج بنجاح",
    product_listed_successfully: "المنتج مدرج بنجاح",
    product_unlisted_successfully: "المنتج غير مدرج بنجاح",
    bid_cancelled_successfully: "تم إلغاء العطاء بنجاح",

    no_product_header: "(لا توجد منتجات)",
    no_product_subheader: "لم تقم بإنشاء أي منتج حتى الآن ، قم بتحميل بعض",

    leave_the_page_confirmation_header_text: "انتباه",
    leave_the_page_confirmation_suheader_text:
      "هل أنت متأكد أنك تريد مغادرة الصفحة؟",

    // Variation Details
    variant_details_has_variant_confirmation_header_text: "يرجى الملاحظة",
    variant_details_has_variant_confirmation_suheader_text:
      "سيؤدي تغيير هذا الخيار إلى حذف التباين",
    variation_details_max_duration_error_text:
      "الحد الأقصى لمدة الفيديو هو: # ثانية",

    variation_details_max_image_size_limit_in_bytes_text:
      "الحد الأقصى لحجم الصورة: # بايت",

    variation_details_file_error_text:
      "يجب أن يحتوي كل شكل على صورة أو مقطع فيديو واحد على الأقل",

    // Upload Product [Product Variants Tab]
    product_variation_name: "اسم الاختلاف",
    product_noof_variants: "عدد المتغيرات",
    enter_the_variant_details: "أدخل تفاصيل البديل",

    isVariationDeatilsopen_name: "اسم",
    isVariationDeatilsopen_priceunit: "السعر / الوحدة",
    isVariationDeatilsopen_qty: "الكمية",
    isVariationDeatilsopen_unit: "وحدة",
    variant_images_max: "صور متنوعة (5 كحد أقصى)",
    add_more_variation_btn_text: "أضف المزيد من المتغير",
    update_variation_details_btn_text: "تحديث تفاصيل الشكل",
    variation_details: "تفاصيل الاختلاف",
    quantity: "كمية",
    variation_details_name: "اسم",

    delete_variant: "حذف المتغير؟",
    delete_variant_confirm_text:
      "سيؤدي الإجراء إلى إزالة جميع البيانات والوسائط ذات الصلة",
    media_delete_sucess: "تم حذف الوسائط بنجاح",

    update_variation_details_error_text: "بعض الاختلافات الخاصة بك مفقودة!",

    media_delete_confirmation_header_text: "حذف الوسائط",
    media_delete_confirmation_suheader_text:
      "هل أنت متأكد أنك ترغب في حذفه؟ لا يمكن التراجع عن هذا الإجراء",

    product_cat_sub_cateegory_alert:
      "يجب أن تكون المنتجات مبطنة بالفئة والفئة الفرعية",
    product_variation_alert: "الرجاء إضافة شكل واحد على الأقل من منتجك",

    product_delete_confirmation_header_text: "حذف المنتج؟",
    product_delete_confirmation_suheader_text: "هل أنت متأكد من حذف المنتج؟",

    cancel_bid_confirmation_header_text: "إلغاء العطاء؟",
    cancel_bid_confirmation_suheader_text: "هل أنت متأكد من إلغاء العطاء؟",

    actualprice_greater_than_discountprice_alert:
      "لا يمكن أن يكون السعر المخفض سعرًا حقيقيًا أكبر! يرجى التحقق من تفاصيل البديل تحت علامة تبويب متغير المنتج",

    upload_product_enable_best_price_alert_text:
      "انتباه! تحتوي العناصر الخاصة بك على خطأ تسعير محتمل. يرجى مراجعة وتأكيد أو تحديث السعر الخاص بك. تواصل مع السعر المحدد | مراجعة السعر",
    upload_product_variation_notadded_alert:
      "انتباه! تحتوي العناصر الخاصة بك على خطأ تسعير محتمل. يرجى مراجعة وتأكيد أو تحديث السعر الخاص بك. تواصل مع السعر المحدد | مراجعة السعر",
    upload_products_error_all_required_fields: "Please add all required fields",
    upload_products_error_for_auction_product:
      "Incremental Rate, Auction Duration and Minimum reserve Price are required",
    upload_products_error_variants_and_specifications:
      "Please add all Variant and Required Specification Details",
    upload_products_error_variants_details: "Please add all Variant Details",
    upload_products_error_shipping_details: "Please Add All shipping Details",
    upload_products_error_onevariant_details:
      "Please Add Details of atleast One Variant",
    upload_products_error_shippingcost_required:
      "Shipping cost Bearer is required",
    upload_products_error_vriation_value: "Please Add Variation Value",
    upload_products_error_addcharity_for_donation:
      "Please add a charity to make donation",
    upload_products_error_required_specs:
      "Please add all required specifications",
    upload_products_error_imageaddition:
      "Please add atleast one image for each variants",
    upload_products_error_required_price_qa_un:
      "Required Price ,Units and Quantity of each variations",
    upload_products_no_variationvalue: "No Variation Value Added",
    upload_products_addvalues: "Add Values",
    upload_products_price_perunit: "Price Per Unit",
    upload_products_price_stocks: "Stock / Quantity",
    upload_products_error__draft_add_productname:
      "Please Add Product Name To Save As Draft",
    upload_products_error_draft_add_productname:
      "Please Add Product Name To Save As Draft",
    upload_products_error_draft_add_variation_details:
      "Please add Variation Details to save draft",

    product_fee: "رسوم المنتج",
    confirm_btn_text: "تؤكد",
    product_description: "وصف المنتج",
    specification: "تخصيص",
    view_all_details: "عرض كافة التفاصيل",
    category: "فئة",
    subcategory: "تصنيف فرعي",
    selected_variant: "المتغير المحدد",
    condition: "شرط",
    listing_fee: "رسوم الإدراج",
    product_listing_price: "سعر قائمة المنتجات",
    product_Sent_approval_text:
      "سيتم إرسال منتجك للموافقة عليه وسيتم عرض القائمة حتى شهر واحد من وقت الموافقة",
    tax_inclusive_text: "شامل جميع الضرائب والرسوم",

    product_fees_listing_detailed_variants_thead_text1: "اسم",
    product_fees_listing_detailed_variants_thead_text2: "السعر",

    // Product Details
    product_details: "تفاصيل المنتج",
    return_policy: "سياسة العائدات",
    return_or_replacement: "الإرجاع / الاستبدال",
    questions_answers: "أسئلة و أجوبة",
    questions_answers_subtext: "هل تريد معرفة المزيد عن المنتج؟",
    ask_a_question: "طرح سؤال",
    answer: "إجابة",
    view_all_questions_answers: "عرض جميع الأسئلة والأجوبة",
    type_your_question: "اكتب سؤالك",
    type_your_answer: "أكتب أجابتك",
    your_answer_submitted_successfully: "تم تقديم إجابتك بنجاح",
    your_question_submitted_successfully: "تم إرسال سؤالك بنجاح",
    your_comment_submitted_successfully: "تم إرسال تعليقك بنجاح",
    your_comment_updated_successfully: "تم تحديث تعليقك بنجاح",
    commnet_deleted_successfully: "تم حذف Commnet بنجاح",
    comments: "تعليقات",
    mentions: "يذكر",
    type_your_comment: "اكتب تعليقك",
    no_comment_header: "بدون تعليقات)",
    no_comment_yet: "ليس هناك أي تعليق (ق) حتى الآن",
    relist: "ريليست",
    unlist: "غير من القائمة",
    payment_status: "حالة السداد",
    listing_status: "Listans status",
    approved_with_expairy_date: "تمت الموافقة (تاريخ الصلاحية - #)",

    // Fetured / Unfetured
    fetured_success: "هذا المنتج مدرج الآن على أنه محبوب!",
    unfetured_success: "هذا المنتج مدرج الآن على أنه غير محدد!",
    fetured: "متميز",
    unfetured: "غير مؤكد",
    isfetured: "متميز ؟",
    isunfetured: "غير مؤكد؟",

    report_confirm_questions_answers_title: "الإبلاغ عن السؤال",
    report_confirm_questions_answers_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا السؤال؟",

    // Comment
    report_confirm_comment_title: "الإبلاغ عن السؤال",
    report_confirm_comment_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا التعليق؟",

    // Payment status
    payment_approve: "وافق",
    payment_pending: "قيد الانتظار",
    payment_failed: "باءت بالفشل",
    payment_declined: "رفض",
    payment_pending_confirmation_bank: "في انتظار التأكيد من البنك",
    payment_cancelled: "ألغيت",

    repayment_screen_header_text: "ادفع الآن",

    productfee_screen_listing_note_text:
      "Din produkt kommer att skickas för godkännande och annonsen kommer att vara giltig till 1 månad från tidpunkten för godkännandet",

    // Product Management Variants Listing
    bids: "العطاءات",
    expired: "منتهي الصلاحية",
    ended: "انتهى",
    active: "نشيط",
    deactivate: "تعطيل",
    title: "لقب",
    pricing: "التسعير",
    status: "الحالة",
    action: "عمل",

    // Language
    change_language: "تغيير اللغة",

    // Product upload [Item Specification Tab]
    starts_in: "يبدأ في",
    ends_in: "ينتهي بـ",
    bid_not_started: "العطاء لم يبدأ",
    bid_over: "انتهى",

    buying_history_bids: "العطاءات",
    buying_history_bids_placed: "وضعت العطاءات",
    buying_history_no_bids: "لم يتم تقديم العطاءات حتى الآن",
    buying_history_last_bid: "آخر عطاء تم وضعه",

    no_item_specification_found: "لم يتم العثور على مواصفات العنصر",
    add_product: "أضف منتج",

    // Loader Texts
    preparing_sales_management: "جارٍ تحضير إدارة المبيعات ...",
    preparing_sales_neworders: "جاري تحضير أوامر جديدة ...",
    preparing_sales_deliveredorders: "جاري تحضير الطلبات المسلمة ...",
    preparing_sales_salescancreturepl:
      "تحضير الأوامر الملغاة / المرتجعة / المستبدلة ...",
    preparing_sales_negotiationsoffers: "جاري تحضير عروض المفاوضات ...",
    preparing_sales_notifications: "يتم الآن تحضير الإخطارات ...",
    preparing_messages: "جاري تحضير الرسائل ...",

    preparing_profile_settings: "يتم الآن تحضير إعدادات ملف التعريف ...",
    preparing_account_settings: "جارٍ تحضير إعدادات الحساب ...",
    preparing_language: "جاري تحضير اللغة ...",
    preparing_currency: "جاري تحضير العملة ...",
    preparing_product_management: "يتم الآن تحضير إدارة المنتج ...",
    preparing_product_details: "جاري تحضير تفاصيل المنتج ...",
    preparing_revenue_reports: "جارٍ تحضير تقارير الإيرادات ...",
    preparing_sales_reports: "جاري تحضير تقارير المبيعات ...",
    preparing_coupons_management: "جاري تحضير إدارة القسائم ...",
    preparing_discounts_management: "جارٍ تحضير إدارة الخصومات ...",
    preparing_ratings_reviews: "جارٍ تحضير التقييمات والمراجعات ...",
    preparing_faq_support: "جارٍ تحضير الأسئلة الشائعة والدعم ...",
    preparing_legal_docs: "يتم الآن تحضير المستندات القانونية ...",
    preparing_transaction_history: "يتم الآن تحضير سجل المعاملات ...",
    preparing_app_suggestions: "جارٍ تحضير اقتراحات التطبيق ...",
    preparing_store_verification: "يتم الآن تحضير التحقق من المتجر ...",
    preparing_store_information: "تحضير معلومات المتجر...",
    preparing_my_followers: "جارٍ تحضير المتابعين ...",
    preparing_profile_information: "يتم الآن تحضير معلومات ملف التعريف ...",
    preparing_product_comments: "جاري تحضير تعليقات المنتج ...",
    preparing_product_likes: "جارٍ تحضير إعجابات المنتج ...",
    preparing_qa: "جارٍ تحضير الأسئلة والأجوبة ...",

    // Saler Management
    saler_tab_text_alltime: "كل الوقت",
    saler_tab_text_daily: "يوميا",
    saler_tab_text_weekly: "أسبوعي",
    saler_tab_text_monthly: "شهريا",
    saler_btn_text_viewreport: "عرض التقرير",
    preparing_vendor_statistics: "إعداد إحصاءات البائعين",

    canceled_orders: "الطلبات الملغاة",
    new_orders: "نيو أوردرز",
    open_orders: "افتح الأوامر",
    delivered_orders: "الطلبات المسلمة",
    negotiation_offers: "عروض التفاوض",
    delivered: "تم التوصيل",
    clubbed: "استبدال / إرجاع / إلغاء",
    negotiation: "تفاوض",
    replace: "يستبدل",
    total_earnings: "الأرباح الكلية",
    all: "الجميع",

    tab_text_cancelled: "ألغيت",
    tab_text_returned: "عاد",
    tab_text_returns: "عائدات",
    tab_text_replaced: "تم استبداله",

    alert_head_cancel: "يلغي",
    alert_order_cancel_subtext: "هل أنت متأكد أنك تريد الإلغاء؟",
    alert_refund_initiate_subtext:
      "هل أنت متأكد من أنك تريد الشروع في استرداد الأموال؟",

    // New Orders
    delivery_to: "تسليم الى",
    copy_email: "نسخ البريد الإلكتروني",
    order_id: "رقم التعريف الخاص بالطلب",
    image: "صورة",
    order_date: "تاريخ الطلب",
    order_date1: "تاريخ الطلب",
    order_by: "ترتيب حسب",
    order_total: "الطلب الكلي",
    order_details: "تفاصيل الطلب",
    sale_status: "حالة البيع",
    sale_contact_no: "رقم الاتصال",
    email_buyer: "المشتري عبر البريد الإلكتروني",
    message_buyer: "مراسلة المشتري",
    qty: "الكمية",
    feedback_received: "تم استلام التعليقات",
    feedback_pending: "التعليقات معلقة",
    price_details: "تفاصيل السعر",
    product_price: "سعر المنتج",

    replacement_requested: "طلب الاستبدالd",
    in_transit: "في مرحلة انتقالية",
    shipping_fee: "رسوم الشحن",
    tax_charges: "الضرائب والرسوم",
    payment_method: "طريقة الدفع او السداد",
    total: "مجموع",
    update_order_status: "تحديث حالة الطلب",
    order_details_re_ship_item_text: "إعادة شحن البند",
    product_shipping_text: "شحن المنتج (أضف رقم التتبع قبل التحديث)",
    tracking_number: "أرقام التتبع",
    mark_as_shipped: "اضف علامة - تم الشحن",
    mark_delivered: "تم التسليم مارك",
    mark_received: "تم استلام العلامة",
    return_reason: "سبب عودة",

    cancellation_reason: "سبب الإلغاء",
    refund_reject_reason: "سبب رفض رد الأموال",
    return_replace_reason: "Return / replace reason",
    replace_reason: "استبدال السبب",
    cancel_reason: "إلغاء السبب",
    cancel_reason_desc: "يرجى تحديد سبب الإلغاء الخاص بك هنا",
    enter_reason: "أدخل السبب",
    cancel_order: "الغاء الطلب",
    download_invoice: "تحميل فاتورة",
    cancel_reason_error_text: "سبب الإلغاء مفقود",
    cancel_reason_error_subtext: "الرجاء إدخال سبب إلغاء",
    tracking_number_error_text: "رقم التتبع مفقود",
    tracking_number_error_subtext: "يرجى إدخال رقم التتبع",
    cancel_reason_successfully: "تم إلغاء الطلب!",
    refund_initiated_successfully: "تم بدء عملية الاسترداد بنجاح!",
    shipping_status: "حالة الشحن",
    marked_asshipped_successfully: "تم وضع علامة تم الشحن بنجاح",
    marked_asreshipped_successfully: "تم وضع علامة إعادة الشحن بنجاح",
    mark_delivered_successfully: "وضع علامة تم تسليمها بنجاح",
    initiate_pickup_successfully: "بدء الاستلام بنجاح",
    marked_received_successfully: "تم وضع علامة على أنه تم استلامه بنجاح",
    pickup_confirmed_successfully: "تم تأكيد الالتقاط بنجاح",

    // Negotation
    negotiation_status: "حالة",
    negotiation_accept: "قبول",
    negotiation_reject: "رفض",

    shipping_and_taxes_extra: "الشحن والضرائب الإضافية",

    // Status Array Text
    order_negotiated: "التفاوض",
    order_closed: "مغلق",
    order_initiated: "بدأت",
    order_responded: "تم الرد",
    order_requested: "مطلوب",

    order_delivered: "أجل تسليم",
    order_dispatched: "تم إرسال الطلب",
    order_Placed: "تم الطلب",
    order_cancelled: "تم الغاء الأمر او الطلب",
    initiate_refund: "بدء استرداد الأموال",
    initiate_pickup: "بدء الالتقاط",
    return_received: "تم استلام الإرجاع",
    return_picked: "تم اختيار العودة",
    return_requested: "طلب الإرجاع",
    refund_initiated: "بدأ رد الأموال",

    refunded: "استرداد",
    payment_pending: "قيد الانتظار",
    refund_rejected: "تم رفض رد الأموال",
    reshipdelivered: "أجل تسليم",
    replace_reshippped: "استبدال reshippped",

    confirm_pickeup: "تأكيد الاستلام",

    cancel_refund: "بدأ إلغاء استرداد",
    cancel_refunded: "إلغاء ردها",
    cancel_refundrejected: "تم رفض رد الأموال",

    initiate_replace_pickup: "بدء الاستلام البديل",
    return_cancelled: "بدء الاستلام البديل",
    replacement_picked: "التقطت الاستبدال",
    replacement_received: "تم استلام الاستبدال",

    // Delies and Weeks
    this_week: "هذا الاسبوع",
    today: "اليوم",
    last_week: "الأسبوع الماضي",
    weeks_back: "قبل أسابيع",
    after: "بعد، بعدما",
    weeks: "أسابيع",
    next_week: "الاسبوع المقبل",
    yesterday: "أمس",

    // Months Nmae
    january: "يناير",
    february: "شهر فبراير",
    march: "مارس",
    april: "أبريل",
    may: "يمكن",
    june: "يونيه",
    july: "تموز",
    august: "أغسطس",
    september: "شهر تسعة",
    october: "اكتوبر",
    november: "شهر نوفمبر",
    december: "ديسمبر",

    /**** Discount Management ****/
    discount_thead_title: "لقب",
    discount_thead_discount: "تخفيض",
    discount_thead_applied_on: "تم التطبيق",
    discount_thead_valide_until: "صالح حتى",
    discount_applicable_rule: "القاعدة السارية للخصم",
    discount_applicable_rule_subtext: "حدد القاعدة التي ينطبق الخصم عليها",
    select_discount_area: "حدد منطقة الخصم",
    discount_product_category: "فئة المنتج",
    discount_product_subcategory: "فئة المنتج الفرعية",
    discount_product: "منتج",
    discount_added_successfully: "تمت إضافة الخصم بنجاح",
    discount_type_require: "اختر نوع الخصم",
    similar_discount_already_available: "خصم مماثل متاح بالفعل",
    select_validity_from: "حدد الصلاحية من",
    select_validity_until: "حدد الصلاحية حتى",
    discount_percentage_not_valide: `إدخال غير صالح لـ "نسبة الخصم"`,
    discount_product_category_error_text: `اختر من "فئة المنتج" كما حددت الفئة كـ "منطقة الخصم"`,
    discount_product_subcategory_error_text: `اختر من "فئة المنتج الفرعية" حيث حددت فئة فرعية باسم "منطقة الخصم"`,
    discount_product_error_text: `اختر من "المنتج" كما حددت المنتج "منطقة الخصم"`,
    discount_int_validation: "يجب أن يكون الخصم أكبر من 0",
    discount_invalid: "قيمة الخصم غير صالحة!",

    discount_title_required: "عنوان الخصم إلزامي!",

    discount_accept_confirm_title: "قبول ؟",
    discount_accept_confirm_message: "هل أنت متأكد أنك تريد قبول هذا الخصم؟",

    discount_reject_confirm_title: "رفض ؟",
    discount_reject_confirm_message: "هل أنت متأكد أنك تريد رفض هذا الخصم؟",

    discount_accepted_success: "Discount has been accepted!",
    discount_rejected_success: "Discount has been rejected!",

    /**** Coupons Management ****/
    add_new_coupons: "أضف قسيمة جديدة",
    coupons_tab_text_active: "نشيط",
    coupons_tab_text_inactive: "غير نشط",
    coupons_thead_coupon: "قسيمة",
    coupons_thead_coupon_code: "رمز الكوبون",
    coupons_thead_above: "في الاعلى",
    edit_coupon: "تحرير القسيمة",
    update_coupon: "تحديث القسيمة",
    coupon_title: "عنوان القسيمة",
    enter_coupon_title: "أدخل عنوان القسيمة",
    coupon_coupon_desc: "وصف القسيمة",
    enter_coupon_desc: "أدخل وصف القسيمة",
    coupon_code: "رمز الكوبون",
    enter_coupon_code: "أدخل رمز القسيمة",
    coupon_generate_text: "انشاء",
    coupon_applicable_rule: "القسيمة المعمول بها القاعدة",
    coupon_applicable_rule_subtext: "حدد القاعدة التي القسيمة قابلة للتطبيق",
    select_criteria: "حدد المعايير",
    applicable_time: "الوقت المناسب",
    applicable_time_text: "عدد الوقت المطبق",
    cart_amount: "مبلغ عربة التسوق",
    enter_cart_amount: "أدخل مبلغ سلة التسوق",
    coupon_added_successfully: "تمت إضافة القسيمة بنجاح",
    coupon_updated_successfully: "تم تحديث القسيمة بنجاح",
    deactivate_confirmation_header_text: "قم بإلغاء تنشيط القسيمة",
    deactivate_confirmation_suheader_text:
      "هل أنت متأكد أنك ترغب في إلغاء تنشيطه؟ لا يمكن التراجع عن هذا الإجراء",
    coupon_deactivated_successfully: "تم إلغاء تنشيط القسيمة بنجاح",
    delete_confirmation_header_text: "حذف القسيمة",
    delete_confirmation_suheader_text:
      "هل أنت متأكد أنك ترغب في حذفه؟ لا يمكن التراجع عن هذا الإجراء",
    coupon_deleted_successfully: "تم حذف القسيمة بنجاح",

    validity_end_date_and_greater_date_validation_text:
      "يجب أن يكون تاريخ انتهاء الصلاحية أكبر من تاريخ بدء الصلاحية! نطاق التاريخ غير صالح!",

    /**** Profile Settings ****/
    profile_settings_list_text1: "معلومات الملف الشخصي",
    profile_settings_list_subtext1: "إدارة حساب البائع",

    profile_settings_list_text2: "معلومات المتجر",
    profile_settings_list_subtext2: "إدارة الملف الشخصي العام للمتجر",

    profile_settings_list_text3: "التحقق من المتجر",
    profile_settings_list_subtext3: "احصل على متجرك تم التحقق منه على shopez",

    profile_settings_list_text4: "إعدادت الحساب",
    profile_settings_list_subtext4: "إدارة حسابات الدفع",

    profile_settings_list_text5: "أتباعي",
    profile_settings_list_subtext5: "انظر من يتبعك",

    update_account: "تحديث الحساب",
    profile_settings_actsettings_terms_text:
      "يتم تحصيل جميع أرباحك على shopez وسيتم إرسالها إليك وفقًا لدورة مدتها 7 أيام. الرجاء إضافة تفاصيل حسابك المصرفي حيث ترغب في تلقي المدفوعات",

    beneficiary_name: "أسم المستفيد",
    beneficiary_full_name: "الاسم الكامل للمستفيد",

    bank_name: "اسم البنك",
    beneficiary_bank_name: "المستفيد اسم البنك",

    act_settings_act_number_lbl: "رقم الحساب / رقم الحساب بصيغة إيبان",
    act_settings_act_number_placeholder: "رقم حساب المستفيد / IBAN",

    act_settings_swift_code_lbl: "كود السويفت / كود بيك / كود الفرز",
    act_settings_swift_code_placeholder: "كود السويفت / كود بيك / كود الفرز",

    select_country: "حدد الدولة",
    country_code: "الرقم الدولي",
    country: "دولة",

    building_no: "رقم المبنى",
    enter_building_no: "أدخل رقم المبنى",

    zone_no: "رقم المنطقة",
    enter_zone_no: "أدخل رقم المنطقة",

    street_no: "رقم الشارع",
    enter_street_no: "أدخل رقم الشارع",

    pobox_no: "رقم الوحدة / ص. علبة",
    enter_pobox_no: "أدخل رقم الوحدة / ص. علبة",

    act_settings_contact_number_lbl: "رقم الاتصال",
    act_settings_contact_number_placeholder: "رقم جهة اتصال المستفيد (اختياري)",

    // My Followers
    text_you: "أنت",
    btn_follow: "يتبع",
    btn_unfollow: "الغاء المتابعة",

    profile_settings_myfollowers_sheardwhishlist: "قائمة الرغبات المشتركة",
    profile_settings_myfollowers_Following: "التالية",

    no_shared_wishlist: "لا توجد قائمة (قوائم) الرغبات المشتركة",
    user_havenot_shared_wishlist: "لم يشارك المستخدم أي قائمة أمنيات",

    no_followers_yet: "لا يوجد متابعون حتى الآن",
    no_following: "لا يوجد متابعون",
    user_not_following_user: "المستخدم لا يتابع أي مستخدم",

    items: "العناصر",

    password_updated_successfully:
      "تم تحديث كلمة السر بنجاح ! الرجاء إعادة تسجيل الدخول!",

    report_as_abusive: "الإبلاغ عن إساءة",
    report_confirm_consumer_title: "أبلغ عن مستخدم",
    report_confirm_consumer_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا المستخدم؟",
    report_success: "لقد نجحت في الإبلاغ عن إساءة",

    // Profile Information
    update_profile: "تحديث الملف",
    get_verified: "الحصول على التحقق",
    no_rating: "لا يوجد تقييم",
    your_profile_is_unverified: "ملفك الشخصي لم يتم التحقق منه",
    profile_updated_successfully: "تم تحديث معلومات الملف الشخصي بنجاح",
    add_phone_number: "أضف رقم الهاتف",
    update_password: "تطوير كلمة السر",
    update_phone_number: "تحديث رقم الهاتف",
    send_code_success: "تم إرسال OTP إلى الرقم المحدد",
    otp_has_been_sent_success: "تم إرسال OTP",
    verification_code_mismatch: "رمز التحقق لا يتطابق",
    otp_verified_successfully: "تم التحقق من OTP بنجاح",
    otp_not_verified: "لم يتم التحقق من OTP",
    mobile_not_verified: "لم تقم بالتحقق من رقم الهاتف المحمول حتى الآن.",
    phone_updated_successfully: "تم تحديث رقم الهاتف بنجاح",

    // Store Information
    update_store_info: "تحديث معلومات المتجر",
    contact_person_label: "جهة الاتصال",
    contact_person_placeholder: "اسم الشخص المسؤول",
    contact_number_label: "رقم الاتصال",
    another_number_label: "رقم آخر",
    add_another_number_btn: "أضف رقمًا آخر",
    select_away_time: "حدد الوقت بعيدا",
    store_info_start_date_label: "تاريخ البدء",
    store_info_end_date_label: "تاريخ الانتهاء",
    set_automatic_response_text: "ضبط الاستجابة التلقائية",
    set_automatic_response_subtext:
      "(الرد التلقائي متاح لأي شخص يتواصل من خلال الرسائل أثناء فترة غيابه)",
    set_automatic_response_placeholder: "مرحباً ، كيف هو يومك",
    store_updated_successfully: "تم تحديث معلومات المتجر بنجاح",
    delete_contact_confirm: "حذف رقم جهة الاتصال",
    delete_contact_number_confirm_text:
      "هل أنت متأكد أنك ترغب في حذفه؟ لا يمكن التراجع عن هذا الإجراء",
    contact_deleted_successfully: "تم حذف جهة الاتصال بنجاح",
    plus_add_another_number: "+ أضف رقمًا آخر",
    location_header: "موقع",
    store_location_text: "موقع المتجر",
    search_places: "البحث في الأماكن ...",
    search_address: "عنوان البحث",
    search_your_location: "ابحث في موقعك",
    latitude: "خط العرض",
    longitude: "خط الطول",
    zoom: "تكبير",
    address: "عنوان",
    current_password: "كلمة المرور الحالي",
    confirm_password: "تأكيد كلمة المرور",
    security_code: "رمز الحماية",
    phone_number: "رقم الهاتف",
    update_phone_number_text: "الرجاء إدخال الرقم الذي ترغب في تحديثه",
    current_number: "العدد الحالي",
    send_code: "أرسل الرمز",
    phone_number_verfication_text:
      "الرجاء إدخال رمز التحقق الذي أرسلناه إلى رقم هاتفك.",
    verification_code_mismatch: "رمز التحقق غير متطابق",
    store_location_verfication_text: "الرجاء إدخال موقع المتجر",

    store_image_upload_icon_notes1: "يوصى بعرض 1920 بكسل وطول 1080 بكسل",
    store_image_upload_icon_notes2: "اضبط موضع الصورة أثناء التحميل",

    store_information_upload_store_image_warning:
      "يرجى رفع صورة المتجر أو النقر فوق زر إلغاء التحميل للمضي قدمًا!",
    store_information_phoneno_error_alert:
      "يرجى رفع صورة المتجر أو النقر فوق زر إلغاء التحميل للمضي قدمًا!",

    // Store Verification
    submit_verification: "إرسال التحقق",
    attach_files: "إرفاق ملفات",
    attach_document: "إرفاق المستندات",
    commercial_registration_document: "وثيقة السجل التجاري",
    agreement_template_duly_filled_and_signed:
      "نموذج الاتفاقية معبأ وموقع حسب الأصول",
    store_verification_form_header:
      "إذا كنت تريد أن تصبح بائعًا موثوقًا به وتكسب المزيد من الأموال المخصصة ، فقم بالتحقق من حسابك على shopez!",
    store_verification_form_subheader:
      "قم بتحميل المستندات واحصل على علامة تم التحقق منها",
    on_your_profile: "على ملفك الشخصي",
    vendor_verified_text: "أنت مستخدم معتمد و معتمد",
    download_agreement_template: "تحميل نموذج الاتفاقية",
    valid_id_proof: "إثبات هوية ساري المفعول",
    upload_only_doc_and_pdf_file_error_text:
      "ملف غير صالح! تحميل ملف doc و pdf فقط",
    attachfile_to_update_error_text: "إرفاق الملفات للتحديث",

    // Account Settings
    check_digits: "تحقق من الأرقام",
    bank_code: "رمز بنكي",
    account_number: "رقم الحساب المصرفي",
    bank_account_number_error_text: "الأرقام والأحرف الكبيرة فقط",
    add_account: "إنشاء حساب",
    account_add_success: "تمت إضافة الحساب بنجاح!",
    account_update_success: "تم تحديث الحساب بنجاح!",

    // App Suggestions
    app_suggestions_header_text: "حدد اقتراحاتك",

    app_suggestions_lbl_email_address: "عنوان البريد الإلكتروني",
    app_suggestions_plshldr_email_address: "عنوان البريد الإلكتروني",

    app_suggestions_lbl_suggestion_desc: "وصف الاقتراح",
    app_suggestions_plshldr_suggestion_desc: "اضف وصفا",

    app_suggestions_footer_text:
      "سيتم إرسال اقتراحك عبر البريد الإلكتروني إلى Shopez وسيتم اتخاذ الإجراءات اللازمة",
    send_suggestion: "إرسال اقتراح",

    file_attached: "ملف مرفق",
    files_attached: "الملفات المرفقة",

    bug: "حشرة",
    app_feature: "ميزة التطبيق",
    app_suggestions_submit_success: "قدمت اقتراحاتك بنجاح",

    // FAQ & Support
    raise_a_ticket: "رفع تذكرة",
    faq_footer_head_text: "هل تريد المزيد من المساعدة؟ اتصل بنا",
    call_us: "اتصل بنا:",
    write_to_us: "اكتب لنا:",
    raise_tacket_sub_header_text:
      "إذا لم تحصل على إجابة لمشاكلك ، فيرجى الكتابة إلينا-",
    write_your_problems: "اكتب مشاكلك",
    write_your_questions: "اكتب سؤالك؟",

    faq_support_submit_success: "تم تقديم قلقك بنجاح",
    search_text_faq_support: "ابحث في الأسئلة الشائعة",

    // Legal Docs
    legal_docs_list_text1: "البنود و الظروف",
    legal_docs_list_subtext1: "عرض الشروط والأحكام الخاصة بنا",
    legal_docs_accept_tnc_text: "أوافق على الشروط والأحكام",
    please_accept_tnc: "يرجى التحقق من الشروط والأحكام!",

    legal_docs_list_text2: "سياسة خاصة",
    legal_docs_list_subtext2: "عرض سياسة الخصوصية",

    legal_docs_list_text3: "سياسة المبيعات الخاصة بك",
    legal_docs_list_subtext3:
      "عرض وتحديث سياسة الإرجاع / الصرف / العطاءات الخاصة بك",

    legal_docs_list_text4: "سياسة مبيعات Shopez",
    legal_docs_list_subtext4: "سياسة مبيعات Shopez",

    sales_policy_requires_en: "سياسة المبيعات مطلوبة للغة الإنجليزية!",
    sales_policy_requires_ar: "سياسة المبيعات مطلوبة للغة العربية!",

    sales_policy_requires_text: "سياسة المبيعات إلزامية",

    tnc_email_me_a_copy: "أرسل لي نسخة من الشروط والأحكام",
    email_not_sent_msg:
      "لم يتم إرسال البريد الإلكتروني ، يرجى التحقق من البريد الإلكتروني أو الإنترنت",
    copy_emil_sent_successfully_msg:
      "تم إرسال نسخة من الشروط والأحكام إلى بريدك الإلكتروني!",

    tnc_email_me_a_copy: "أرسل لي نسخة من الشروط والأحكام",

    // Revenue Reports
    revenue_reports_list_text1: "تقرير الإيرادات",

    revenue_reports_list_text2: "تقرير المبيعات",

    revenue_reports_list_text3: "تاريخ المعاملات",

    download_report: "تنزيل التقرير",
    sales_report_footer_text: "مجموع البنود المباعة",
    revenue_report_footer_text: "مجموع الأرباح",

    qty_sold: "الكمية المباعة",
    in_stock: "في الأوراق المالية",
    deal_amount: "مبلغ الصفقة",
    variant: "متغير",

    transaction_id: "رقم المعاملة",
    transaction_amount: "قيمة التحويل",
    transaction_date: "تاريخ الصفقة",
    transaction_product: "المنتج",
    transaction_type: "نوع",
    transaction_status: "حالة",

    search_by_transaction_id: "البحث عن طريق معرف المعاملة",
    revenue_report_revenue_search_by:
      "البحث عن طريق اسم المنتج أو الأوصاف أو المواصفات",
    revenue_report_sales_search_by:
      "البحث عن طريق اسم المنتج أو الأوصاف أو المواصفات",
    sales_policy_successfully: "تم تحديث سياسة المبيعات بنجاح",

    // Rating & Review
    play_store: "متجر Play",
    google_play: "تطبيقات جوجل",
    app_store: "متجر التطبيقات",
    rate_us_text: "قيمنا على متجر التطبيقات ومتجر التطبيقات",

    //Change Currency
    currency_of_preference: "عملة التفضيل",
    currency_of_preference_note: "لا يمكنك التراجع عن هذا الاختيار",
    change_currency: "تغيير العملة",
    select_currency: "اختر العملة",
    qatari_riyal: "ريال قطري",
    dollar: "دولار الولايات المتحدة",

    // repayment
    repayment_nourlfound: "No payment url found",
    repayment_paymentfailed: "Payment Failed , something went wrong",
    // Language
    language_of_preference: "لغة التفضيل",
  },
});

// module.exports = LocaleStrings;
export default LocaleStrings;
