import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import LocaleStrings from "@language";
import _ from "lodash";

import {
  updateMRP,
  classNames,
  abbreviateNumber,
  ProductTextLocalized,
} from "@commonFunction";
import { APP_API_KEY, BASE_IMAGES_URL, timeDiffCalc } from "@constant";
// Import Actions
import { fetchProductDetails, productbyid } from "@productmanagementActions";
import {
  currencyCode,
  currencyRate,
  isCurrencyRateApplicable,
} from "@currencyActions";

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeText: "",
      timeInterval: 0,
    };
  }

  changeRouteTo = (e, productObj) => {
    let { session } = this.props;
    // console.log("productObj", productObj);
    // console.log("variantid", productObj.variantid);
    //this.props.fetchProductDetails(productObj);
    // this.props.productbyid(session, productObj.id, false,(callback) => {
    //   this.props.showUploadSection(e, "PD");
    // });

    this.props.history.push(
      `/dashboard/productmanagement/product-details/${productObj.id}-${productObj.variantid}`
    );
  };

  componentDidMount() {
    this.interVal = setInterval(() => {
      this.setState({ timeInterval: this.state.timeInterval + 1 });
    }, 2000);
  }

  componentWillUnmount() {
    this.state = {
      timeText: "",
      timeInterval: 0,
    };
    this.clearInterval();
  }

  clearInterval = () => {
    clearInterval(this.interVal);
  };

  render() {
    let { localCurrency, productObj, language, vendorDetails } = this.props;

    // Banned Items- If a product, person is banned and we have a reference link, then show temporarily not available
    const productUnvailableTest =
      productObj?.isactive === false || vendorDetails?.isactive === "0"
        ? LocaleStrings.temporarily_unavailable
        : null;

    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    /* Product currency */
    let productCurrency = productObj.currency;

    /*
    If user currency and product currency dont match 
    return currencyrate other waise no need to convert currency value so return 1
    */

    let item = this.props.productObj;

    /* product name => productname_en /  productname_ar */
    var cardTitle =
      ProductTextLocalized(
        productObj.productname_en,
        productObj.productname_ar,
        language
      ) +
      "-" +
      ProductTextLocalized(
        productObj.variantname_en,
        productObj.variantname_ar,
        language
      );

    /* product sub title name => product_desc_en /  product_desc_ar */
    let productDescTitle = ProductTextLocalized(
      productObj.product_desc_en,
      productObj.product_desc_ar,
      language
    );

    let bidcancelled = productObj.bidcancelled ? productObj.bidcancelled : 0;

    var productImageUrl = `${BASE_IMAGES_URL}/vendor/variations/${this.props.productObj.productimage}?api_key=${APP_API_KEY}`;

    let data = this.props.productObj;

    let selectedVariant = _.filter(data.all_variants, function (o) {
      return o.id == data.variantid;
    });
    let variantId = data.variantid;
    let itemPrice =
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].price_unit
        ? selectedVariant[0].price_unit
        : 0;

    let isavailable =
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].isavailable
        ? selectedVariant[0].isavailable
        : 0;
    let priceObj = updateMRP(data, variantId);

    let discountedPrice = priceObj.discountedPrice;

    // Convert the discounted amount
    // discountedPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   discountedPrice
    // );
    // Convert the itemPrice discounted amount
    // itemPrice = isCurrencyRateApplicable(
    //   localCurrency,
    //   productCurrency,
    //   itemPrice
    // );

    let strProductDiscount = priceObj.strProductDiscount;

    /*** SHOW AUCTION DATE ***/
    var auctionText = "";
    var endDate = "";
    if (item.saletype == "auction") {
      let currentDate = moment();
      if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isAfter(moment(item.auction_start_date)) &&
        currentDate.isBefore(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.ends_in;
        endDate = item.auction_end_date;
      } else if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isBefore(moment(item.auction_start_date)) &&
        currentDate.isBefore(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.starts_in;
        endDate = item.auction_start_date;
      } else if (
        item.auction_start_date &&
        item.auction_end_date &&
        currentDate.isAfter(moment(item.auction_start_date)) &&
        currentDate.isAfter(moment(item.auction_end_date))
      ) {
        auctionText = LocaleStrings.expired;
        endDate = "";
      }

      if (bidcancelled === "1" || bidcancelled === 1) {
        auctionText = LocaleStrings.cancelled;
      }
    }

    let outofstock = false;
    let variant = _.filter(this.props.productObj.all_variants, {
      id: this.props.productObj.variantid,
    });

    if (variant.length > 0 && variant[0].outofstock == 1) {
      outofstock = true;
    }

    return (
      <>
        <div
          id={`grid${this.props.position}`}
          key={`grid${this.props.position}`}
          className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer"
          onClick={(e) => this.changeRouteTo(e, this.props.productObj)}>
          <div
            key={`grid-inner-1-${this.props.position}`}
            className="flex-shrink-0 relative">
            <img
              loading="lazy"
              className="h-60 w-full object-cover "
              src={productImageUrl}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images2/default.jpeg";
              }}
            />
            <div className="absolute bottom-20 w-full" align="center">
              {this.props.productObj.listed == 0 ||
              this.props.productObj.listed == "0" ? (
                <div className="card-badge-danger mt-2">
                  {LocaleStrings.unlisted}
                </div>
              ) : (
                ""
              )}

              {this.props.productObj.saletype == "auction" &&
              outofstock === true ? (
                <span className="card-badge-danger">
                  {LocaleStrings.purchased}
                </span>
              ) : outofstock === true ? (
                <span className="card-badge-danger">
                  {LocaleStrings.out_of_stock}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="absolute bottom-12 w-full" align="center">
              {isavailable == "0" || isavailable == 0 ? (
                <span className="card-badge-danger">Unavailable</span>
              ) : (
                ""
              )}
            </div>
            <div className="absolute bottom-14 w-full" align="center">
              {parseInt(this.props.productObj.isactive) === 0 &&
              parseInt(this.props.productObj.isapproved) === 1 ? (
                <span className="card-badge-danger">
                  {LocaleStrings.banned}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="absolute bottom-1 w-full" align="center">
              {parseInt(this.props.productObj.isapproved) === 0 &&
              parseInt(this.props.productObj.isdraft) == 0 ? (
                <span className="card-badge">
                  {LocaleStrings.waiting_for_approval}
                </span>
              ) : this.props.productObj.saletype == "auction" &&
                parseInt(this.props.productObj.isdraft) == 0 ? (
                <>
                  {/* auction */}

                  <span className="p-2">
                    <div className="text-primary block text-sm px-4 textcenter mt-4">
                      {auctionText}
                    </div>
                    <div
                      className={classNames(
                        endDate === "" ? "card-badge-danger" : "card-badge-3",
                        ""
                      )}>
                      {endDate === ""
                        ? LocaleStrings.bid_over
                        : timeDiffCalc(new Date(endDate), new Date())}
                    </div>
                  </span>
                  <span className="card-badge">
                    {this.props.productObj.numbids} {LocaleStrings.bids}
                  </span>
                  {/* <span className="card-badge">
                    <img
                    loading="lazy"
                      className="badge-icon"
                      src={"/images2/product_management_images/eye.svg"}
                    />
                    {this.props.productObj.numwatching}
                  </span> */}
                </>
              ) : (
                <>
                  {/* Directsale */}
                  <span className="card-badge">
                    <img
                      loading="lazy"
                      className="badge-icon"
                      src={
                        "/images2/product_management_images/rating-review.svg"
                      }
                    />
                    {this.props.productObj.ratings.avgrating}
                  </span>
                  <span className="card-badge">
                    <img
                      loading="lazy"
                      className="badge-icon"
                      src={
                        "/images2/product_management_images/Filled-Heart-circle.svg"
                      }
                    />
                    {abbreviateNumber(this.props.productObj.numlikes)}
                  </span>
                  <span className="card-badge">
                    {abbreviateNumber(this.props.productObj.numsold)}{" "}
                    {LocaleStrings.sold}
                  </span>
                </>
              )}
            </div>
          </div>
          <div
            key={`grid-inner-2-${this.props.position}`}
            className="flex-1 bg-white p-2 flex flex-col justify-between">
            <div className="flex-1">
              <a className="block mt-2">
                {/* Product or user unavailbale then of warning text */}
                {_.size(productUnvailableTest) > 0 ? (
                  <p className="text-xs font-semibold text-danger">
                    {productUnvailableTest}
                  </p>
                ) : null}

                <p className="text-xs font-semibold text-secondary">
                  {cardTitle.length > 80
                    ? cardTitle.substring(0, 30) + "..."
                    : cardTitle}
                </p>
                <p className="mt-1 text-xs text-ternary">
                  {productDescTitle.length > 80
                    ? productDescTitle.substring(0, 60) + "..."
                    : productDescTitle}
                </p>
              </a>
            </div>
          </div>
          <div
            key={`grid-inner-3-${this.props.position}`}
            className="grid grid-cols-3 gap-4 mb-2 p-2">
            <div>
              <p className="text-xs font-semibold text-secondary">
                {currency} {parseFloat(discountedPrice).toFixed(2)}
              </p>
            </div>
            <div>
              {strProductDiscount ? (
                <p className="text-xs line-through ...">
                  {currency} {parseFloat(itemPrice).toFixed(2)}
                </p>
              ) : (
                ""
              )}
            </div>
            <div>
              <p className="text-xs text-danger">{strProductDiscount}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  localCurrency: state.currency,
  language: state.language,
  vendorDetails: state.vendorDetails
    ? state.vendorDetails.data
      ? state.vendorDetails.data
      : []
    : [],
});

export default connect(mapStateToProps, {
  fetchProductDetails,
  productbyid,
})(ProductsList);
