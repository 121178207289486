import React from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  change,
  FieldArray,
  getFormValues,
} from "redux-form";

import { XIcon, TrashIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import _, { forEach } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import LocaleStrings from "@language";
import TailwindAlert from "../../../../common/tailwindalert";
import { confirmAlert } from "react-confirm-alert";
//import cloneDeep from "lodash.clonedeep";
import CharityViewModal from "./charityviewmodal";
import {
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
  MIN_DATE_FORMAT,
  validatePercent,
  CURRENCY_RATE_QR,
  MIN_TIME_FORMAT,
  BASE_IMAGES_URL,
  APP_API_KEY,
} from "@constant";
import { format, addDays, parse } from "date-fns";
// Import All pages
import CategorySubcategory from "./addcategorysubcategory";
import UpdateVariationDetails from "./updatevariationdetails";
// Import components
import Drop from "./drop";

// Import All actions
import {
  isProductDiscountopen,
  isExcludeshippingmodalopen,
  isProductCateSubCateModalOpen,
  productTitleSubTitleData,
  isVariationDeatilsopen,
  addproduct,
  uploadMediaLargeVendorVariation,
  productFeeModal,
  fetchProductFeeDetails,
  allSelectedSubcategorisID,
  unSetTobeEditProductStore,
  tabListWithSelection,
  currentShippngType,
  productAddedDiscount,
  fetchVariationMaster,
  enabledbestoffer,
  clearDiscountList,
  reinitializeProduxtSpecification, // Clear item specification
  fetchZoneAndPincode,
  getTranslatedResults, // Translate API call
  toggleunlistproduct,
  relistwhileUpdatingProduct,
  fetchShippingCharges,
  makepaymentPersonalvendor,
  updateMastervariationListArray,
  continentLength,
  productExculdelocation,
  charityviewModalopen,
  iscatsubCatUpdated,
} from "@productmanagementActions";

import {
  addDiscountMaster,
  updateDiscountMaster,
} from "@discountsmanagementActions";

import { currencyCode, currencyRate } from "@currencyActions";
import { fetchSettingsMaster } from "@legaldocsActions";

// Common Functions
import {
  isEmpty,
  ProductTextLocalized,
  classNames,
  randomString,
  getTotalPriceToPay,
} from "@commonFunction";
import { ar } from "date-fns/locale";

/**** Item / Product specification Object ****/

class UploadProduct extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedServiceId: this.props.productData
        ? this.props.productData.shipping_service
          ? this.props.productData.shipping_service.id
          : 0
        : 0,
      productTitle: "",
      productSubTitle: "",
      showerror: false,
      vatiantsFeildArrState: [],
      defaultSaleType: this.props.productData.saletype,
      returnType: "",
      incrementalTypeState: "percentage",
      btnLoader: false,
      draftbtnLoader: false,
      isBtnDisable: false,
      variantVariationsState: {},
      specificationState: [],
      enar: "en",
      makeDonationState: this.props.productData.make_donation,
      showDescAutocomplete: false,
      newVariantAdded: false,
      istaxable: this.props?.productData?.istaxable,
      defaultHasvariant:
        this.props.productData && this.props.productData.hasvariant
          ? this.props.productData.hasvariant
          : 1,
      tanslateBtnLoader: false,
      variationValue: [],
      finalVariationArr: [],
      order_processed_status: "self",
      selected_charity_image: null,
      selected_charity_description_en: null,
      selected_charity_description_ar: null,
      selected_charity_name_en: null,
      selected_charity_name_ar: null,
      charity_donation_percentage: null,
      charity_iscustom: false,
      newVariantimageRemove: false,
      onEditUpdatedvariant: false,
      auctiontypestate: false,
    };

    // All Binded Functions
    this._handleSelectService = this._handleSelectService.bind(this);
    this.addProductDiscount_click = this.addProductDiscount_click.bind(this);
    this.excludeShipping_click = this.excludeShipping_click.bind(this);
    this.onCateSubCateModalOpen = this.onCateSubCateModalOpen.bind(this);
    this.handleProductTitleKeyUp = this.handleProductTitleKeyUp.bind(this);
    this.handleProductSubTitleKeyUp =
      this.handleProductSubTitleKeyUp.bind(this);
    this.updateVariationDeatilsClick =
      this.updateVariationDeatilsClick.bind(this);
    this._handleShippingType = this._handleShippingType.bind(this);
    this._handleReturnType = this._handleReturnType.bind(this);
    this.handelIncrementalType = this.handelIncrementalType.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
    this._handleIstaxable = this._handleIstaxable.bind(this);
  }

  componentDidMount() {
    let { isEdit, productData, session } = this.props;
    // console.log('productData',productData);
    this.props.fetchSettingsMaster(session, (callback) => {});
    this.props.fetchShippingCharges(session, (callback) => {});
    this.props.fetchZoneAndPincode(session, (callback) => {});

    if (isEdit) {
      // Time Of Edit
      // console.log("productData", productData);
      let product_variants = productData.product_variants;
      let all_variants = productData.all_variants;
      this.setState({ vatiantsFeildArrState: product_variants }); //
      this.setState({ variantVariationsState: all_variants });
      this.setState({ order_processed_status: productData.order_processedby });
      this.setState({ auctiontypestate: productData.auctiontype });

      if (!_.isEmpty(productData.incremental_type)) {
        // When Sales Type Auction ->Change Default State
        this.setState({ incrementalTypeState: productData.incremental_type }); // Action Incremental Rate
      }

      // Set State Language on edit mode from db
      this.setState({
        enar:
          productData.autotranslate === "0" || productData.autotranslate === 0
            ? "en"
            : "ar",
      });

      this.setState({
        autotranslate_ischeck:
          productData.autotranslate_ischeck === "0" ||
          productData.autotranslate_ischeck === 0
            ? false
            : true,
      });

      /* fetch 'variation_master' */
      this.props.fetchVariationMaster(
        session,
        productData.categoryids,
        productData.subcategoryids,
        (callback) => {}
      );

      // update exclude location

      if (
        productData &&
        productData.product_exclude_country &&
        Object.keys(productData.product_exclude_country).length > 0
      ) {
        // let dataval = productData.product_exclude_country;
        this.props.productExculdelocation(productData.product_exclude_country);

        let continentLength = Object.keys(
          productData.product_exclude_country
        ).length;

        let countrylength = 0;
        let continentArr = [];
        let countryArr = [];
        _.map(productData.product_exclude_country, (item, index) => {
          let objcontinet = {
            index: index,
          };
          let objcountry = {
            value: item[0],
          };
          continentArr.push(objcontinet);
          countryArr.push(objcountry);
          countrylength += item.length;
        });
        // countrylength = countrylength;
        let countobj = {
          countrylength: countrylength,
          continentLength: continentLength,
          countryid: countryArr,
          countinentid: continentArr,
        };
        this.props.continentLength(countobj);
      }

      let selectedCharity = [];
      if (
        this.props.defaultSettings.data &&
        this.props.defaultSettings.data.charity_events &&
        this.props.defaultSettings.data.charity_events.length > 0 &&
        productData.idcharityevents != "" &&
        productData.idcharityevents != null
      ) {
        selectedCharity = _.filter(
          this.props.defaultSettings.data.charity_events,
          function (o) {
            return o.id == productData.idcharityevents;
          }
        );

        if (selectedCharity.length > 0) {
          this.setState({
            selected_charity_image: selectedCharity[0].image,
            selected_charity_description_en: selectedCharity[0].description_en,
            selected_charity_description_ar: selectedCharity[0].description_ar,
            selected_charity_name_en: selectedCharity[0].name_en,
            selected_charity_name_ar: selectedCharity[0].name_ar,
            charity_donation_percentage: selectedCharity[0].percentage,
            charity_iscustom: selectedCharity[0].iscustom,
          });
        }
      }

      this.setState({ returnType: productData.return_time }); // latest add
    } else {
      let currentDatetime = new Date(
        moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
      );
      this.props.dispatch(
        change("UploadProductForm", "auction_start_date", currentDatetime)
      );
      // Time Add
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isCatsubcatupdatedData) {
      this.setState({ variantVariationsState: {} });
      this.setState({ vatiantsFeildArrState: [] });
      this.props.iscatsubCatUpdated(false);
    }
  }

  componentWillUnmount() {
    this.state = {
      selectedServiceId: this.props.productData
        ? this.props.productData.shipping_service
          ? this.props.productData.shipping_service.id
          : 0
        : 0,
      productTitle: "",
      productSubTitle: "",
      showerror: false,
      vatiantsFeildArrState: [],
      defaultSaleType: this.props.productData.saletype,
      returnType: "",
      incrementalTypeState: "percentage",
      btnLoader: false,
      draftbtnLoader: false,
      isBtnDisable: false,
      variantVariationsState: {},
      specificationState: [],
      enar: "en",
      makeDonationState: this.props.productData.make_donation,
      showDescAutocomplete: false,
      newVariantAdded: false,
      istaxable: 0,
      tanslateBtnLoader: false,
      newVariantimageRemove: false,
      onEditUpdatedvariant: false,
    };
    this.props.productAddedDiscount({}); // Removing discount object
    this.props.clearDiscountList({}); // Clear discount list
    this.props.reinitializeProduxtSpecification({}); // Clear product item specification
    this.props.relistwhileUpdatingProduct(false);
  }

  /*  Get Tabs bilinaguale text for tabs [as its not reflecting] */
  getBilingualTabName = (tab, viewtype) => {
    let textName = "";
    if (tab.tabindex === 1) {
      if (viewtype == "xs") {
        textName = "About";
      } else {
        textName = LocaleStrings.about_the_product;
      }
    } else if (tab.tabindex === 2) {
      // textName = LocaleStrings.product_variation;
      if (viewtype == "xs") {
        textName = "Specs";
      } else {
        textName = LocaleStrings.item_specifications;
      }
    } else if (tab.tabindex === 3) {
      if (viewtype == "xs") {
        textName = "Shipping";
      } else {
        textName = LocaleStrings.shipping_type_and_service;
      }
    } else if (tab.tabindex === 4) {
      if (viewtype == "xs") {
        textName = "Preferences";
      } else {
        textName = LocaleStrings.set_preferences;
      }
    } else if (tab.tabindex === 5) {
      textName = LocaleStrings.sales_formate_and_pricing;
    } else if (tab.tabindex === 6) {
      textName = LocaleStrings.shipping_type_and_service;
    } else if (tab.tabindex === 7) {
      textName = LocaleStrings.set_preferences;
    } else {
      return textName;
    }

    return textName;
  };

  /**** INPUT LANGUAGE CHANGE en - ar ****/
  handleInputLanguageChange(e) {
    if (parseInt(e.target.value) === 1) {
      this.setState({ enar: "ar" });
    } else {
      this.setState({ enar: "en" });
    }
  }

  /* Handel  The product has variant toggle */
  handleHasvariantChange = (e, comesfrom) => {
    let { vatiantsFeildArrState } = this.state;

    // new addittion to fix crash
    // if (!this.props.isEdit) {
    // debugger;
    this.setState({ vatiantsFeildArrState: [], variantVariationsState: [] });

    // }

    // confirmAlert({
    //   title: LocaleStrings.variant_details_has_variant_confirmation_header_text,
    //   message: `${LocaleStrings.variant_details_has_variant_confirmation_suheader_text}`,
    //   buttons: [
    //     {
    //       label: LocaleStrings.button_yes,
    //       onClick: () => {

    //     },
    //     {
    //       label: LocaleStrings.button_no,
    //       onClick: () => {
    //         // Do nothing
    //         e.preventDefault();
    //       },
    //     },
    //   ],
    // });

    // 1st time is its set a dummy set automatically for [Product only field]
    if (
      parseInt(e.target.value) === 0 ||
      comesfrom == "auctionsaletype"
      // && _.size(vatiantsFeildArrState) === 0 //commented to fix error
    ) {
      // set dummy variants data
      let dummyNameEn = "dummy";
      let dummyNameAr = "dummy";
      let variantnamesRefkey = randomString(6);
      // debugger;
      let newvatiantsFeildArrState = [
        {
          name_en: dummyNameEn,
          name_ar: dummyNameAr,
          quantity: 1,
          refkey: randomString(6),
          variantnames: [
            {
              name_en: dummyNameEn,
              name_ar: dummyNameAr,
              refkey: variantnamesRefkey,
            },
          ],
        },
      ];
      // debugger;
      this.setState({ vatiantsFeildArrState: newvatiantsFeildArrState });
      // this.setState({
      //   vatiantsFeildArrState: [
      //     {
      //       name_en: dummyNameEn,
      //       name_ar: dummyNameAr,
      //       quantity: 1,
      //       refkey: randomString(6),
      //       variantnames: [
      //         {
      //           name_en: dummyNameEn,
      //           name_ar: dummyNameAr,
      //           refkey: variantnamesRefkey,
      //         },
      //       ],
      //     },
      //   ],
      // });
      // Set dummy variant variation data
      // debugger;

      let newvariantVariationsState = [
        {
          id: 0,
          isavailable: 1,
          isdeleted: false,
          name_ar: dummyNameEn,
          name_en: dummyNameAr,
          price_unit: 0,
          quantity: 1,
          refkey: variantnamesRefkey,
          units: "",
          variations_media_raw: [
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
          ],
          variations_media: [],
        },
      ];
      // console.log("newvariantVariationsState", newvariantVariationsState);
      this.setState({ variantVariationsState: newvariantVariationsState });
      // this.setState({
      //   variantVariationsState: [
      //     {
      //       id: 0,
      //       isavailable: 1,
      //       isdeleted: false,
      //       name_ar: dummyNameEn,
      //       name_en: dummyNameAr,
      //       price_unit: 0,
      //       quantity: 1,
      //       refkey: variantnamesRefkey,
      //       units: "",
      //       variations_media_raw: [
      //         {
      //           type: "",
      //           file_type: "",
      //           file_name: "",
      //           file_thumbnail_name: "",
      //           file_path: "",
      //           thumbnamil_path: "",
      //           raw: "",
      //           raw_thumbnail: "",
      //         },
      //         {
      //           type: "",
      //           file_type: "",
      //           file_name: "",
      //           file_thumbnail_name: "",
      //           file_path: "",
      //           thumbnamil_path: "",
      //           raw: "",
      //           raw_thumbnail: "",
      //         },
      //         {
      //           type: "",
      //           file_type: "",
      //           file_name: "",
      //           file_thumbnail_name: "",
      //           file_path: "",
      //           thumbnamil_path: "",
      //           raw: "",
      //           raw_thumbnail: "",
      //         },
      //         {
      //           type: "",
      //           file_type: "",
      //           file_name: "",
      //           file_thumbnail_name: "",
      //           file_path: "",
      //           thumbnamil_path: "",
      //           raw: "",
      //           raw_thumbnail: "",
      //         },
      //         {
      //           type: "",
      //           file_type: "",
      //           file_name: "",
      //           file_thumbnail_name: "",
      //           file_path: "",
      //           thumbnamil_path: "",
      //           raw: "",
      //           raw_thumbnail: "",
      //         },
      //       ],
      //       variations_media: [],
      //     },
      //   ],
      // });
    }
    if (comesfrom == "auctionsaletype") {
      this.setState({ defaultHasvariant: "0" });
      this.setState({ newVariantAdded: true });
      this.props.dispatch(change("UploadProductForm", "all_variants", []));
    } else if (comesfrom == "directsaletype") {
      this.setState({ newVariantAdded: true });
      this.props.dispatch(change("UploadProductForm", "all_variants", []));
      // making array blank if its a directsaletype
      this.setState({
        defaultHasvariant: "1",
        vatiantsFeildArrState: [],
        variantVariationsState: [],
      });
    } else {
      this.setState({ defaultHasvariant: e.target.value });
    }
  };

  /* UPDATE VARINT VARIATION STATE FROM DETAILS PAGE */
  variantVariationsStateUpdate = (arr) => {
    this.setState({ variantVariationsState: arr, newVariantAdded: false });
  };
  /* UPDATE VARINT VARIATION STATE FROM DETAILS PAGE */
  vatiantsFeildArrupdateState = (arr, callfrom) => {
    this.setState({ vatiantsFeildArrState: arr, newVariantAdded: false });
  };

  /* Update when variant add/remove */
  updateNewVariantAddedFlag = () => {
    this.setState({ newVariantAdded: false });
  };

  /**** Handel Increment Type Change ****/
  handelIncrementalType(param) {
    this.setState({ incrementalTypeState: param });
  }

  /***** Category Subcategory Modal Open *****/
  onCateSubCateModalOpen(e) {
    var titleSubTitleObj = {
      product_title: this.state.productTitle,
      product_subtitle: this.state.productSubTitle,
    };
    this.props.productTitleSubTitleData(titleSubTitleObj);
    this.props.isProductCateSubCateModalOpen(true);
  }

  // Add product dicount Modal click
  addProductDiscount_click() {
    this.props.isProductDiscountopen(true);
  }
  // Add product dicount Modal click
  excludeShipping_click() {
    this.props.isExcludeshippingmodalopen(true);
  }

  // Update variation detilas Modal Click
  updateVariationDeatilsClickold() {
    let { formValues } = this.props;

    let isError = false;

    _.forEach(formValues?.variants, function (value) {
      if (
        (value.name_en === "" && value.name_ar === "") ||
        _.size(value.variantnames) < 1
      ) {
        isError = true;
        return false;
      }

      _.forEach(value.variantnames, function (underValue) {
        if (underValue.name_en === "" && underValue.name_ar === "") {
          isError = true;
          return false;
        }
      });
    });

    if (isError) {
      // Variation field is blank
      toast.error(LocaleStrings.update_variation_details_error_text);
    } else {
      this.setState({
        vatiantsFeildArrState: formValues?.variants ? formValues?.variants : [],
      }); // putting form variant array values into state [getFormValues.variants]

      this.props.isVariationDeatilsopen(true);
    }
  }
  updateVariationDeatilsClick() {
    // let { formValues } = this.props;
    let { vatiantsFeildArrState } = this.state;
    // let isError = false;

    // _.forEach(formValues?.variants, function (value) {
    //   if (
    //     (value.name_en === "" && value.name_ar === "") ||
    //     _.size(value.variantnames) < 1
    //   ) {
    //     isError = true;
    //     return false;
    //   }

    //   _.forEach(value.variantnames, function (underValue) {
    //     if (underValue.name_en === "" && underValue.name_ar === "") {
    //       isError = true;
    //       return false;
    //     }
    //   });
    // });

    // if (isError) {
    //   // Variation field is blank
    //   toast.error(LocaleStrings.update_variation_details_error_text);
    // } else {
    //   this.setState({
    //     vatiantsFeildArrState: formValues?.variants ? formValues?.variants : [],
    //   }); // putting form variant array values into state [getFormValues.variants]

    this.props.isVariationDeatilsopen(true);
    // }
  }

  // ON KEY UP SET PRODUCT TITLE VALUE TO STATE
  handleProductTitleKeyUp(e) {
    this.setState({ productTitle: e.target.value });
  }

  // On Change PRODUCT SUB TITLE VALUE TO STATE
  handleProductSubTitleKeyUp(e) {
    this.setState({ productSubTitle: e.target.value });
  }

  onFocus(e) {
    this.setState({ showDescAutocomplete: true });
  }

  // Function For Product Description
  onFocusOut(e) {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ showDescAutocomplete: false }); //After 1 second, set render to true
      }.bind(this),
      1000
    );
  }

  // Function For Product Description
  getValue(e, item) {
    let { enar } = this.state;
    this.props.autofill("product_desc_" + enar, item);
  }

  // On TABS CHANGE
  onTabsChange = (e, tabindex, callfrom, tabs) => {
    let { formValues, isEdit, productData } = this.props;
    let { enar, variantVariationsState } = this.state;
    // console.log('tabindex',tabindex)
    let selectedIndex = 0;

    // if (callfrom === "lg") {
    //   // For tabs
    //   selectedIndex = tabindex;
    // } else {
    //   // For Select box
    //   selectedIndex = parseInt(e.target.value) + 1;
    // }

    // let newTabs = _.map(tabs, (value, index) => {
    //   if (value.tabindex === selectedIndex) {
    //     value.current = true;
    //   } else {
    //     value.current = false;
    //   }
    //   return value;
    // });

    // this.props.tabListWithSelection(newTabs);
    // console.log("tabindex", tabindex);
    // console.log("productData", productData);
    // commented for development
    if (
      isEdit == true &&
      productData &&
      (productData.isdraft == 0 || productData.isdraft == "0")
    ) {
      if (callfrom === "lg") {
        // For tabs
        selectedIndex = tabindex;
      } else {
        // For Select box
        selectedIndex = parseInt(e.target.value) + 1;
      }

      let newTabs = _.map(tabs, (value, index) => {
        if (value.tabindex === selectedIndex) {
          value.current = true;
        } else {
          value.current = false;
        }
        return value;
      });

      this.props.tabListWithSelection(newTabs);
    } else {
      if (tabindex > 1) {
        if (formValues != undefined) {
          if (
            tabindex == 2 &&
            (formValues.productname_en || formValues.productname_ar) &&
            formValues.product_condition &&
            (formValues.product_desc_en || formValues.product_desc_ar) &&
            formValues.saletype &&
            (formValues.productname_en != "" ||
              formValues.productname_ar != "") &&
            formValues.product_condition != "" &&
            (formValues.product_desc_en != "" ||
              formValues.product_desc_ar != "") &&
            formValues.saletype != "" &&
            !_.isEmpty(this.props.selectedSubcategorids)
          ) {
            if (callfrom === "lg") {
              // For tabs
              selectedIndex = tabindex;
            } else {
              // For Select box
              selectedIndex = parseInt(e.target.value) + 1;
            }

            let newTabs = _.map(tabs, (value, index) => {
              if (value.tabindex === selectedIndex) {
                value.current = true;
              } else {
                value.current = false;
              }
              return value;
            });

            this.props.tabListWithSelection(newTabs);
          } else if (
            tabindex == 2 &&
            (!formValues.productname_en ||
              !formValues.productname_ar ||
              !formValues.product_condition ||
              !formValues.product_desc_en ||
              !formValues.product_desc_ar ||
              !formValues.saletype ||
              formValues.productname_en == "" ||
              formValues.productname_ar == "" ||
              formValues.product_condition == "" ||
              formValues.product_desc_en == "" ||
              formValues.product_desc_ar == "" ||
              formValues.saletype != "" ||
              _.isEmpty(this.props.selectedSubcategorids))
          ) {
            toast.error(
              LocaleStrings.upload_products_error_all_required_fields
            );
          }

          if (tabindex == 3) {
            let error = 0;
            if (variantVariationsState.length > 0) {
              _.forEach(variantVariationsState, (v, i) => {
                if (v.price_unit == "" || v.quantity == "" || v.units == "") {
                  error += 1;
                }

                let filteredArrvideo_raw = _.filter(
                  v.variations_media_raw,
                  function (o) {
                    return o.file_type || o.type == "video";
                  }
                );

                let filteredArrImage_raw = _.filter(
                  v.variations_media_raw,
                  function (o) {
                    return o.file_type || o.type == "image";
                  }
                );
                let filteredArrvideo_media = _.filter(
                  v.variations_media,
                  function (o) {
                    return o.file_type || o.type == "video";
                  }
                );

                let filteredArrImage_media = _.filter(
                  v.variations_media,
                  function (o) {
                    return o.file_type || o.type == "image";
                  }
                );

                if (
                  filteredArrvideo_raw.length == 0 &&
                  filteredArrImage_raw.length == 0 &&
                  filteredArrvideo_media.length == 0 &&
                  filteredArrImage_media.length == 0
                ) {
                  error = 1;
                }

                if (
                  this.props.storedItemSpecifications &&
                  this.props.storedItemSpecifications.required &&
                  this.props.storedItemSpecifications.required.length > 0
                ) {
                  _.map(
                    this.props.storedItemSpecifications.required,
                    (item, index) => {
                      let keyNameEn = "itemspec_name_en_" + item.id;
                      let keyNameAr = "itemspec_name_ar_" + item.id;

                      if (
                        !formValues.hasOwnProperty(keyNameEn) &&
                        !formValues.hasOwnProperty(keyNameAr)
                      ) {
                        error = 1;
                      }

                      if (formValues[keyNameEn]) {
                        if (
                          formValues[keyNameEn] == "" ||
                          formValues[keyNameEn] == null ||
                          formValues[keyNameEn] == undefined
                        ) {
                          error = 1;
                        }
                      }
                      if (formValues[keyNameAr]) {
                        if (
                          formValues[keyNameAr] == "" ||
                          formValues[keyNameAr] == null ||
                          formValues[keyNameAr] == undefined
                        ) {
                          error = 1;
                        }
                      }
                    }
                  );
                }
                if (
                  formValues != undefined &&
                  formValues.saletype == "auction"
                ) {
                  if (
                    !formValues.incremental_rate ||
                    !formValues.auctiondays ||
                    !formValues.auction_min_price_percentage ||
                    formValues.incremental_rate == "" ||
                    formValues.auctiondays == "" ||
                    formValues.auction_min_price_percentage == ""
                  ) {
                    error = 2;
                  }
                }

                if (error == 0) {
                  if (callfrom === "lg") {
                    // For tabs
                    selectedIndex = tabindex;
                  } else {
                    // For Select box
                    selectedIndex = parseInt(e.target.value) + 1;
                  }

                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === selectedIndex) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else if (error == 2) {
                  toast.error(
                    LocaleStrings.upload_products_error_for_auction_product
                  );
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_variants_and_specifications
                  );
                }
              });
            } else if (Object.keys(variantVariationsState).length == 0) {
              toast.error(LocaleStrings.upload_products_error_variants_details);
            }
            // new validation for auction product
          }

          if (tabindex == 4) {
            if (
              formValues != undefined &&
              formValues.location_countryid &&
              (formValues.location_state_en || formValues.location_state_ar) &&
              (formValues.location_city_en || formValues.location_city_ar) &&
              formValues.shipping_type &&
              formValues.location_countryid != "" &&
              (formValues.location_state_en != "" ||
                formValues.location_state_ar) &&
              (formValues.location_city_en != "" ||
                formValues.location_city_ar != "") &&
              formValues.shipping_type != ""
            ) {
              if (formValues.shipping_type == "flat") {
                if (
                  formValues.idcompany_package &&
                  formValues.idcompany_package != "" &&
                  formValues.delivery_company &&
                  formValues.delivery_company != "" &&
                  formValues.shipping_cost_f &&
                  formValues.shipping_cost_f != ""
                ) {
                  // if(formValues.shipping_type == )
                  if (callfrom === "lg") {
                    // For tabs
                    selectedIndex = tabindex;
                  } else {
                    // For Select box
                    selectedIndex = parseInt(e.target.value) + 1;
                  }

                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === selectedIndex) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_shippingdetails
                  );
                }
              } else if (formValues.shipping_type == "calculated") {
                if (
                  formValues.idcompany_package &&
                  formValues.idcompany_package != "" &&
                  formValues.delivery_company &&
                  formValues.delivery_company != ""
                  // && formValues.shipping_cost_c &&
                  // formValues.shipping_cost_c != ""
                ) {
                  // if(formValues.shipping_type == )
                  if (callfrom === "lg") {
                    // For tabs
                    selectedIndex = tabindex;
                  } else {
                    // For Select box
                    selectedIndex = parseInt(e.target.value) + 1;
                  }

                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === selectedIndex) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_shipping_details
                  );
                }
              } else {
                if (callfrom === "lg") {
                  // For tabs
                  selectedIndex = tabindex;
                } else {
                  // For Select box
                  selectedIndex = parseInt(e.target.value) + 1;
                }

                let newTabs = _.map(tabs, (value, index) => {
                  if (value.tabindex === selectedIndex) {
                    value.current = true;
                  } else {
                    value.current = false;
                  }
                  return value;
                });

                this.props.tabListWithSelection(newTabs);
              }
            } else if (
              !formValues.location_countryid ||
              (!formValues.location_state_en &&
                !formValues.location_state_ar) ||
              (!formValues.location_city_en && !formValues.location_city_ar) ||
              !formValues.shipping_type ||
              formValues.location_countryid == "" ||
              (formValues.location_state_en == "" &&
                formValues.location_state_ar == "") ||
              (formValues.location_city_en == "" &&
                formValues.location_city_ar == "") ||
              formValues.shipping_type == ""
            ) {
              toast.error(
                LocaleStrings.upload_products_error_all_required_fields
              );
            }
          }
        }
      } else {
        // console.log("tabindex2", tabindex);
        if (callfrom === "lg") {
          // For tabs
          selectedIndex = tabindex;
        } else {
          // For Select box
          selectedIndex = parseInt(e.target.value) + 1;
        }

        let newTabs = _.map(tabs, (value, index) => {
          if (value.tabindex === selectedIndex) {
            value.current = true;
          } else {
            value.current = false;
          }
          return value;
        });

        this.props.tabListWithSelection(newTabs);
      }
    }
  };

  // On NEXT BUTTON CHNAGE TO NEXT TAB
  onNextBtnTabChange = (e, currentTab) => {
    let { tabs, formValues, isEdit, productData } = this.props;
    let { variantVariationsState } = this.state;
    let tabIndextoShow = currentTab < 7 ? parseInt(currentTab) + 2 : 0;

    if (
      isEdit == true &&
      productData &&
      (productData.isdraft == 0 || productData.isdraft == "0")
    ) {
      let newTabs = _.map(tabs, (value, index) => {
        if (value.tabindex === tabIndextoShow) {
          value.current = true;
        } else {
          value.current = false;
        }
        return value;
      });

      this.props.tabListWithSelection(newTabs);
    } else {
      if (tabIndextoShow > 1) {
        if (formValues != undefined) {
          if (
            tabIndextoShow == 2 &&
            (formValues.productname_en || formValues.productname_ar) &&
            formValues.product_condition &&
            (formValues.product_desc_en || formValues.product_desc_ar) &&
            formValues.saletype &&
            (formValues.productname_en != "" ||
              formValues.productname_ar != "") &&
            formValues.product_condition != "" &&
            (formValues.product_desc_en != "" ||
              formValues.product_desc_ar != "") &&
            formValues.saletype != "" &&
            !_.isEmpty(this.props.selectedSubcategorids)
          ) {
            let newTabs = _.map(tabs, (value, index) => {
              if (value.tabindex === tabIndextoShow) {
                value.current = true;
              } else {
                value.current = false;
              }
              return value;
            });

            this.props.tabListWithSelection(newTabs);
          } else if (
            tabIndextoShow == 2 &&
            (!formValues.productname_en ||
              !formValues.productname_ar ||
              !formValues.product_condition ||
              !formValues.product_desc_en ||
              !formValues.product_desc_ar ||
              !formValues.saletype ||
              formValues.productname_en == "" ||
              formValues.productname_ar == "" ||
              formValues.product_condition == "" ||
              formValues.product_desc_en == "" ||
              formValues.product_desc_ar == "" ||
              formValues.saletype != "" ||
              _.isEmpty(this.props.selectedSubcategorids))
          ) {
            toast.error(
              LocaleStrings.upload_products_error_all_required_fields
            );
          }

          if (tabIndextoShow == 3) {
            let error = 0;
            if (variantVariationsState.length > 0) {
              _.forEach(variantVariationsState, (v, i) => {
                if (v.price_unit == "" || v.quantity == "" || v.units == "") {
                  error += 1;
                }

                let filteredArrvideo_raw = _.filter(
                  v.variations_media_raw,
                  function (o) {
                    return o.file_type || o.type == "video";
                  }
                );

                let filteredArrImage_raw = _.filter(
                  v.variations_media_raw,
                  function (o) {
                    return o.file_type || o.type == "image";
                  }
                );
                let filteredArrvideo_media = _.filter(
                  v.variations_media,
                  function (o) {
                    return o.file_type || o.type == "video";
                  }
                );

                let filteredArrImage_media = _.filter(
                  v.variations_media,
                  function (o) {
                    return o.file_type || o.type == "image";
                  }
                );

                if (
                  filteredArrvideo_raw.length == 0 &&
                  filteredArrImage_raw.length == 0 &&
                  filteredArrvideo_media.length == 0 &&
                  filteredArrImage_media.length == 0
                ) {
                  error = 1;
                }

                if (
                  this.props.storedItemSpecifications &&
                  this.props.storedItemSpecifications.required &&
                  this.props.storedItemSpecifications.required.length > 0
                ) {
                  _.map(
                    this.props.storedItemSpecifications.required,
                    (item, index) => {
                      let keyNameEn = "itemspec_name_en_" + item.id;
                      let keyNameAr = "itemspec_name_ar_" + item.id;

                      if (
                        !formValues.hasOwnProperty(keyNameEn) &&
                        !formValues.hasOwnProperty(keyNameAr)
                      ) {
                        error = 1;
                      }
                      if (formValues[keyNameEn]) {
                        if (
                          formValues[keyNameEn] == "" ||
                          formValues[keyNameEn] == null ||
                          formValues[keyNameEn] == undefined
                        ) {
                          error = 1;
                        }
                      }

                      if (formValues[keyNameAr]) {
                        if (
                          formValues[keyNameAr] == "" ||
                          formValues[keyNameAr] == null ||
                          formValues[keyNameAr] == undefined
                        ) {
                          error = 1;
                        }
                      }
                    }
                  );
                }
                // console.log('errorerror24',error)
                // return false;

                if (
                  formValues != undefined &&
                  formValues.saletype == "auction"
                ) {
                  if (
                    !formValues.incremental_rate ||
                    !formValues.auctiondays ||
                    !formValues.auction_min_price_percentage ||
                    formValues.incremental_rate == "" ||
                    formValues.auctiondays == "" ||
                    formValues.auction_min_price_percentage == ""
                  ) {
                    error = 2;
                  }
                }

                if (error == 0) {
                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === tabIndextoShow) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else if (error == 2) {
                  toast.error(
                    LocaleStrings.upload_products_error_for_auction_product
                  );
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_variants_and_specifications
                  );
                }
              });
            } else if (Object.keys(variantVariationsState).length == 0) {
              toast.error(LocaleStrings.upload_products_error_variants_details);
            }
          }

          if (tabIndextoShow == 4) {
            if (
              formValues != undefined &&
              formValues.location_countryid &&
              (formValues.location_state_en || formValues.location_state_ar) &&
              (formValues.location_city_en || formValues.location_city_ar) &&
              formValues.shipping_type &&
              formValues.location_countryid != "" &&
              (formValues.location_state_en != "" ||
                formValues.location_state_ar) &&
              (formValues.location_city_en != "" ||
                formValues.location_city_ar != "") &&
              formValues.shipping_type != ""
            ) {
              if (formValues.shipping_type == "flat") {
                if (
                  formValues.idcompany_package &&
                  formValues.idcompany_package != "" &&
                  formValues.delivery_company &&
                  formValues.delivery_company != "" &&
                  formValues.shipping_cost_f &&
                  formValues.shipping_cost_f != ""
                ) {
                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === tabIndextoShow) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_shipping_details
                  );
                }
              } else if (formValues.shipping_type == "calculated") {
                if (
                  formValues.idcompany_package &&
                  formValues.idcompany_package != "" &&
                  formValues.delivery_company &&
                  formValues.delivery_company != ""
                  // && formValues.shipping_cost_c &&
                  // formValues.shipping_cost_c != ""
                ) {
                  // if(formValues.shipping_type == )
                  let newTabs = _.map(tabs, (value, index) => {
                    if (value.tabindex === tabIndextoShow) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }
                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                } else {
                  toast.error(
                    LocaleStrings.upload_products_error_shipping_details
                  );
                }
              } else {
                let newTabs = _.map(tabs, (value, index) => {
                  if (value.tabindex === tabIndextoShow) {
                    value.current = true;
                  } else {
                    value.current = false;
                  }
                  return value;
                });

                this.props.tabListWithSelection(newTabs);
              }
            } else if (
              !formValues.location_countryid ||
              (!formValues.location_state_en &&
                !formValues.location_state_ar) ||
              (!formValues.location_city_en && !formValues.location_city_ar) ||
              !formValues.shipping_type ||
              formValues.location_countryid == "" ||
              (formValues.location_state_en == "" &&
                formValues.location_state_ar == "") ||
              (formValues.location_city_en == "" &&
                formValues.location_city_ar == "") ||
              formValues.shipping_type == ""
            ) {
              toast.error(
                LocaleStrings.upload_products_error_all_required_fields
              );
            }
          }
        }
      }
    }

    //this.setState({tabIndextoShow: ((parseInt(currentTab) === 7) ? 1 : parseInt(currentTab)+1) });
  };

  previousTabChange = (e, currentTab) => {
    let { tabs } = this.props;
    let tabIndextoShow = currentTab;

    let newTabs = _.map(tabs, (value, index) => {
      if (value.tabindex === tabIndextoShow) {
        value.current = true;
      } else {
        value.current = false;
      }
      return value;
    });
    this.props.tabListWithSelection(newTabs);
  };

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-danger text-xs" : ""
    }`;

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  renderSelectSpecial(field) {
    var divClassName = `form-group row ${
      field.meta.touched && field.meta.error ? "has-danger" : ""
    }`;

    var labelClasses = field.classNameLabel;
    var inputClasses = "col-sm-9 col-sm-offset-0";

    if (field.labelposition === LABEL_POSITION_TOP) {
      labelClasses = field.classNameLabel;
      inputClasses = "col-sm-12 col-sm-offset-0";
    }
    return (
      <div className={divClassName}>
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
        <div className={inputClasses}>
          <input
            className={field.className}
            {...field.input}
            onClick={field.fc}
            placeholder={field.placeholder}
            readOnly
          />
          <div className="text-help label-text-help">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  }

  /***** SELECT SERVICE ON CHNAGE *****/
  _handleSelectService(e) {
    var serviceVal = e.target.value;

    if (typeof serviceVal !== "undefined") {
      this.setState({ selectedServiceId: parseInt(serviceVal) });
    }
  }

  /**** ON RETURN TYPE SELECT ****/
  _handleReturnType(e) {
    var returnTime = e.target.value;

    if (returnTime === "no_return") {
      /*
      Changing Return Time to "no return" 
      make "Refund type" & "Shipping cost bearer"
      disabled & default non selected option
    */
      this.props.dispatch(change("UploadProductForm", "refund_type", ""));
      this.props.dispatch(
        change("UploadProductForm", "shippingcostbearer", "")
      );
    }
    this.setState({ returnType: returnTime });
  }

  /***** SELECT SHIPING TYPE ONCHANGE *****/
  _handleShippingType(e) {
    let shippingType = e.target.value;

    if (shippingType === "flat") {
      // Clear Value
      this.props.dispatch(change("UploadProductForm", "shipping_cost_c", ""));
    } else if (shippingType === "calculated") {
      // Clear Value
      this.props.dispatch(change("UploadProductForm", "shipping_cost_f", ""));
    } else {
      //Clear    [entry shipping cost & Shiping cost by location & Shipping cost bearer]
      this.props.dispatch(change("UploadProductForm", "shipping_cost_f", ""));
      this.props.dispatch(change("UploadProductForm", "shipping_cost_c", ""));
      this.props.dispatch(
        change("UploadProductForm", "shippingcostbearer", "")
      );

      /**** No shipping (Local pickup only) [OnSelect Clear Shipping service and Package] ****/
      this.props.dispatch(change("UploadProductForm", "delivery_company", ""));
      this.props.dispatch(change("UploadProductForm", "idcompany_package", ""));
    }

    this.props.currentShippngType(shippingType);
  }

  /***** SELECT 'Is product taxable' option change handel
   * Note:- Only show when "Product tax(in %)" when 'Is product taxable' is selected
   * *****/
  _handleIstaxable(e) {
    let { initialValues, isEdit } = this.props;
    let istaxableVal = e.target.value;

    if (istaxableVal === "1" || istaxableVal === 1) {
      this.setState({ istaxable: 1 });
      if (isEdit) {
        this.props.dispatch(
          change("UploadProductForm", "producttax", initialValues.producttax)
        );
      }
    } else {
      this.setState({ istaxable: 0 });
      this.props.dispatch(change("UploadProductForm", "producttax", ""));
    }
  }

  /***** SALES TYPE ON CHNAGE *****/
  onSaletypeChange(e) {
    // this.setState({ defaultSaleType: e.target.value }); old code
    // latest code
    let returnTime = "no_return";
    if (e.target.value == "auction") {
      this.props.dispatch(change("UploadProductForm", "refund_type", ""));
      this.props.dispatch(
        change("UploadProductForm", "shippingcostbearer", "")
      );
      this.props.dispatch(
        change("UploadProductForm", "return_time", "no_return")
      );

      let quantityFieldname = `all_variants_quantity[0]`; //adding quantity 1 in form
      this.props.dispatch(change("UploadProductForm", quantityFieldname, 1)); //adding quantity 1 in form

      this.setState({ returnType: returnTime });
      this.setState({ defaultSaleType: e.target.value });
      this.handleHasvariantChange(e, "auctionsaletype");
      // this.setState({defaultHasvariant:0});
    } else {
      this.setState({ defaultSaleType: e.target.value });
      this.handleHasvariantChange(e, "directsaletype");
    }
  }

  /***** ITEM SPECIFICATIONS ****/
  renderItemSpecFields = () => {
    let { enar } = this.state;
    var item = _.map(
      this.props.storedItemSpecifications,
      (itemSpecificationVal, index) => {
        let name_en = itemSpecificationVal.name_en;
        let name_ar = itemSpecificationVal.name_ar;

        let idspecification = itemSpecificationVal.id;
        let local_lang = this.props.language;
        let isrequired =
          itemSpecificationVal.ismandatory === "0" ||
          itemSpecificationVal.ismandatory === 0
            ? ""
            : "true";

        let fieldText = ProductTextLocalized(name_en, name_ar, local_lang); // language
        return (
          <div className="">
            <Field
              name={"itemspec_name_" + enar + "_" + idspecification}
              label={fieldText}
              placeholder={fieldText}
              type="text"
              component={this.renderFieldText}
              mandatory={isrequired}
              labelposition={LABEL_POSITION_TOP}
              className=""
              classNameLabel="text-box-lable"
            />
          </div>
        );
      }
    );

    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return (
        <p className="paragraph-seconday">
          {LocaleStrings.no_item_specification_found}
        </p>
      );
    }

    return item;
  };
  renderItemSpecFieldRequired = () => {
    let { enar } = this.state;
    var item = _.map(
      this.props.storedItemSpecifications.required,
      (itemSpecificationVal, index) => {
        let name_en = itemSpecificationVal.name_en;
        let name_ar = itemSpecificationVal.name_ar;

        let idspecification = itemSpecificationVal.id;
        let local_lang = this.props.language;
        let isrequired =
          itemSpecificationVal.ismandatory === "0" ||
          itemSpecificationVal.ismandatory === 0
            ? ""
            : "true";

        let itemspecificdatalist_en = [];

        if (itemSpecificationVal.value_en.length > 0) {
          _.forEach(itemSpecificationVal.value_en, (val, index) => {
            var obj = {};
            let itemspecificdatalist_ar = itemSpecificationVal.value_ar;
            // latest code

            obj = {
              // value: val + "-" + itemspecificdatalist_ar[index],
              // name: val + "-" + itemspecificdatalist_ar[index],
              value: val,
              name: val,
            };

            itemspecificdatalist_en.push(obj);
          });
          // let fieldname = "itemspec_name_" + enar + "_" + idspecification;
          // this.props.autofill(fieldname, itemSpecificationVal.value_en[0]);
        }
        let fieldText = ProductTextLocalized(name_en, name_ar, local_lang); // language
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 m-2">
            <div className="mt-2">{_.startCase(fieldText)}</div>
            <div>
              <Field
                name={"itemspec_name_" + enar + "_" + idspecification}
                type="text"
                placeholder="Select or add value"
                component={this.renderDatalistSelect}
                labelposition={LABEL_POSITION_TOP}
                datalistoptn={itemspecificdatalist_en}
                classNameLabel="text-box-lable"
                list={"itemspec_name_" + enar + "_" + idspecification}
                onChange={this._handleDataList}
              />
            </div>
          </div>
        );
      }
    );

    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return (
        <p className="paragraph-seconday">
          {LocaleStrings.no_item_specification_found}
        </p>
      );
    }

    return item;
  };

  renderItemSpecFieldAdditional = () => {
    let { enar } = this.state;
    var item = _.map(
      this.props.storedItemSpecifications.additional,
      (itemSpecificationVal, index) => {
        let name_en = itemSpecificationVal.name_en;
        let name_ar = itemSpecificationVal.name_ar;

        let idspecification = itemSpecificationVal.id;
        let local_lang = this.props.language;
        let isrequired =
          itemSpecificationVal.ismandatory === "0" ||
          itemSpecificationVal.ismandatory === 0
            ? ""
            : "true";

        let itemspecificdatalist_en = [];
        // console.log("itemSpecificationVal", itemSpecificationVal);
        if (itemSpecificationVal.value_en.length > 0) {
          _.forEach(itemSpecificationVal.value_en, (val, index) => {
            var obj = {};
            let itemspecificdatalist_ar = itemSpecificationVal.value_ar;
            // latest code
            obj = {
              // value: val + "-" + itemspecificdatalist_ar[index],
              value: val,
              name: val,
            };
            itemspecificdatalist_en.push(obj);
          });
          let fieldname = "itemspec_name_" + enar + "_" + idspecification;
          // this.props.autofill(fieldname, itemSpecificationVal.value_en[0]);
        }
        let fieldText = ProductTextLocalized(name_en, name_ar, local_lang); // language
        // console.log("itemspecificdatalist_en", itemspecificdatalist_en);
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 m-2">
              <div className="mt-2">{_.startCase(fieldText)}</div>
              <div>
                <Field
                  name={"itemspec_name_" + enar + "_" + idspecification}
                  type="text"
                  placeholder="Select or add value"
                  component={this.renderDatalistSelect}
                  labelposition={LABEL_POSITION_TOP}
                  datalistoptn={itemspecificdatalist_en}
                  classNameLabel="text-box-lable"
                  list={"itemspec_name_" + enar + "_" + idspecification}
                  onChange={this._handleDataList}
                />
              </div>
            </div>
          </>
        );
      }
    );

    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return (
        <p className="paragraph-seconday">
          {LocaleStrings.no_item_specification_found}
        </p>
      );
    }

    return item;
  };

  renderMasterVariation = () => {
    let { enar, vatiantsFeildArrState } = this.state;
    var item = _.map(
      this.props.variationMasterList,
      (itemVariationVal, index) => {
        let name_en = itemVariationVal.name_en;
        let name_ar = itemVariationVal.name_ar;

        let idvariation = itemVariationVal.id;
        let local_lang = this.props.language;

        let itemspecificdatalist_en = [];
        let filteredArr = [];
        if (this.state.variationValue.length > 0) {
          filteredArr = _.filter(this.state.variationValue, function (o) {
            return o.id == idvariation;
          });
        }
        // let fieldText = ProductTextLocalized(name_en, name_ar, local_lang); // language
        let fieldText = ProductTextLocalized(name_en, name_ar, enar); // language
        return (
          <>
            <div
              className={classNames(
                filteredArr.length > 0 ? "bg-gray-200" : "bg-white",
                "border-b-2 border-color-gray py-4 px-2 text-sm cursor-pointer"
              )}
              onClick={(e) => this._handelVariationValue(e, itemVariationVal)}>
              {_.startCase(fieldText)}
            </div>
          </>
        );
      }
    );

    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return (
        <p className="paragraph-seconday">
          {LocaleStrings.no_item_specification_found}
        </p>
      );
    }

    return item;
  };

  renderstateVariationformed = () => {
    let { vatiantsFeildArrState, enar } = this.state;
    var item = _.map(vatiantsFeildArrState, (data, index) => {
      let name_en = data.name_en;
      let name_ar = data.name_ar;
      let local_lang = this.props.language;

      let arr = [];
      arr.push(data);
      let fieldText = ProductTextLocalized(name_en, name_ar, enar); // language
      return (
        <>
          <div className="col-span-2 my-2">{fieldText}</div>
          <div className="col-span-4">
            <div className="grid grid-cols-2 gap-4 mb-4">
              {data.variantnames.map((item) => (
                <>
                  <div
                    className="col-span-1 bg-gray-300 rounded p-2 cursor-pointer text-center relative"
                    onClick={(e) =>
                      this._handelVariationcombinationselection(
                        e,
                        item.name_en,
                        item.name_ar,
                        arr
                      )
                    }>
                    <div className="absolute -top-3.5 -right-3 rounded rounded-full border-2 border-gray-300 bg-white">
                      <img className="h-4 w-4" src={"/images2/xicon.svg"}></img>
                    </div>
                    {ProductTextLocalized(item.name_en, item.name_ar, enar)}
                    {/* {item.name_en} - {item.name_ar} */}
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      );
    });

    if (_.isEmpty(item)) {
      // If No item Spec Found Show Message
      return (
        <p className="paragraph-seconday">
          {LocaleStrings.no_item_specification_found}
        </p>
      );
    }

    return item;
  };

  // renderstatemasterVariationImages = () => {
  //   let { vatiantsFeildArrState } = this.state;
  //   let { isEdit } = this.props;
  //   var item = _.map(vatiantsFeildArrState, (data, index) => {
  //     let name_en = data.name_en;
  //     let name_ar = data.name_ar;
  //     let local_lang = this.props.language;

  //     let fieldText = ProductTextLocalized(name_en, name_ar, local_lang);

  //     let imageLength = 0;
  //     let videoLength = 0;
  //     let filteredArr = [];
  //     let filteredArrImage = [];
  //     let filteredArrvideo = [];

  //     if (
  //       data.variantnames &&
  //       data.variantnames.length > 0 &&
  //       data.variantnames[0].variations_media_raw &&
  //       data.variantnames[0].variations_media_raw.length > 0
  //     ) {
  //       filteredArr = _.filter(
  //         data.variantnames[0].variations_media_raw,
  //         function (o) {
  //           return o.file_type != "";
  //         }
  //       );
  //     }
  //     if (
  //       data.variantnames &&
  //       data.variantnames.length > 0 &&
  //       data.variantnames[0].variations_media_raw &&
  //       data.variantnames[0].variations_media_raw.length > 0
  //     ) {
  //       filteredArrImage = _.filter(
  //         data.variantnames[0].variations_media_raw,
  //         function (o) {
  //           return o.file_type == "image";
  //         }
  //       );
  //     }
  //     if (
  //       data.variantnames &&
  //       data.variantnames.length > 0 &&
  //       data.variantnames[0].variations_media_raw &&
  //       data.variantnames[0].variations_media_raw.length > 0
  //     ) {
  //       filteredArrvideo = _.filter(
  //         data.variantnames[0].variations_media_raw,
  //         function (o) {
  //           return o.file_type == "video";
  //         }
  //       );
  //     }

  //     if (filteredArrImage.length > 0) {
  //       imageLength = filteredArrImage.length;
  //     }
  //     if (filteredArrvideo.length > 0) {
  //       videoLength = filteredArrvideo.length;
  //     }
  //     return (
  //       <>
  //         <div className="cols-span-4 ">
  //           {/* {filteredArr.length > 0 ? (
  //             <>
  //               {filteredArr[0].file_type == "image" ? (
  //                 <>
  //                   <img
  //                     loading="lazy"
  //                     src={filteredArr[0].file_path}
  //                     className="dropimg1 opacity-30"
  //                   />
  //                   <div className="absolute top-8 md:px-12 flex">
  //                     {imageLength != 0 ? (
  //                       <div className="font-bold">{imageLength} photo(s)</div>
  //                     ) : (
  //                       ""
  //                     )}
  //                     {videoLength != 0 ? (
  //                       <div className="font-bold">,{videoLength} video(s)</div>
  //                     ) : (
  //                       ""
  //                     )}
  //                   </div>
  //                 </>
  //               ) : filteredArr[0].file_type == "video" ? (
  //                 <>
  //                   <video
  //                     controls
  //                     controlsList="nodownload"
  //                     className="dropimg1 opacity-30 relative">
  //                     <source
  //                       src={`${filteredArr[0].file_path}#t=0.1`}
  //                       type="video/mp4"
  //                     />
  //                   </video>
  //                   <div className="absolute top-8 md:px-12 flex">
  //                     {imageLength != 0 ? (
  //                       <div className="font-bold">{imageLength} photo(s)</div>
  //                     ) : (
  //                       ""
  //                     )}
  //                     {videoLength != 0 ? (
  //                       <div className="font-bold">,{videoLength} video(s)</div>
  //                     ) : (
  //                       ""
  //                     )}
  //                   </div>
  //                 </>
  //               ) : (
  //                 ""
  //               )}
  //             </>
  //           ) : (
  //             ""
  //           )} */}

  //           {data.variantnames[0].variations_media_raw[0].file_type ==
  //           "image" ? (
  //             <img
  //               loading="lazy"
  //               src={data.variantnames[0].variations_media_raw[0].file_path}
  //               className="dropimg1"
  //             />
  //           ) : data.variantnames[0].variations_media_raw[0].file_type ==
  //             "video" ? (
  //             <video controls controlsList="nodownload" className="dropimg1">
  //               <source
  //                 src={`${data.variantnames[0].variations_media_raw[0].file_path}#t=0.1`}
  //                 type="video/mp4"
  //               />
  //             </video>
  //           ) : isEdit ? (
  //             !_.isEmpty(data.variantnames[0].variations_media[0]) &&
  //             data.variantnames[0].variations_media[0]?.isdeleted === "0" ? (
  //               data.variantnames[0].variations_media[0].type === "image" ? (
  //                 <>
  //                   <div className="img_wrp- mx-auto">
  //                     <img
  //                       loading="lazy"
  //                       src={`${BASE_IMAGES_URL}/vendor/variations/${data.variantnames[0].variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
  //                       className="dropimg1"
  //                     />
  //                   </div>
  //                 </>
  //               ) : (
  //                 <>
  //                   {data.variations_media[0].id ? (
  //                     <div className="img_wrp- mx-auto">
  //                       <img
  //                         src={`${BASE_IMAGES_URL}/vendor/variations/${data.variantnames[0].variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
  //                         className="dropimg1 "
  //                       />
  //                     </div>
  //                   ) : (
  //                     ""
  //                   )}
  //                 </>
  //               )
  //             ) : (
  //               ""
  //             )
  //           ) : (
  //             ""
  //           )}
  //           <div className="mt-2 text-center">{fieldText}</div>
  //         </div>
  //       </>
  //     );
  //   });

  //   return item;
  // };

  renderstatemasterVariationImages = () => {
    let { vatiantsFeildArrState } = this.state;
    let { isEdit, formValues1, formValues, initialValues } = this.props;

    // console.log("formvalues", formValues);
    // console.log("formValues1", formValues1);

    let itemArr = [];

    if (isEdit) {
      if (
        initialValues.hasdefaultphoto == 0 ||
        initialValues.hasdefaultphoto == "0"
      ) {
        itemArr = _.filter(vatiantsFeildArrState, function (o) {
          return o.id == initialValues.variation_photo;
        });
      } else {
        itemArr = vatiantsFeildArrState;
      }
    } else {
      if (
        formValues1 != undefined &&
        formValues1 != null &&
        Object.keys(formValues1).length > 0
      ) {
        if (
          formValues1.hasdefaultphoto == 0 ||
          formValues1.hasdefaultphoto == "0"
        ) {
          if (
            formValues1.variation_photo &&
            formValues1.variation_photo != "" &&
            formValues1.variation_photo != null &&
            formValues1.variation_photo != undefined
          ) {
            itemArr = _.filter(vatiantsFeildArrState, function (o) {
              return o.id == formValues1.variation_photo;
            });
          }
        } else {
          itemArr = vatiantsFeildArrState;
        }
      }
    }
    // console.log("vatiantsFeildArrState", vatiantsFeildArrState);
    if (itemArr.length > 0 && itemArr[0].variantnames) {
      let selectedvariantname = itemArr[0].name_en;

      // return false;
      var item = _.map(itemArr[0].variantnames, (data, index) => {
        let name_en = data.name_en;
        let name_ar = data.name_ar;
        let local_lang = this.props.language;

        let fieldText = ProductTextLocalized(name_en, name_ar, local_lang);

        let imageLength = 0;
        let videoLength = 0;
        let filteredArr = [];
        let filteredArrImage = [];
        let filteredArrvideo = [];

        if (
          data.variantnames &&
          data.variantnames.length > 0 &&
          data.variantnames[0].variations_media_raw &&
          data.variantnames[0].variations_media_raw.length > 0
        ) {
          filteredArr = _.filter(
            data.variantnames[0].variations_media_raw,
            function (o) {
              return o.file_type != "";
            }
          );
        }
        if (
          data.variantnames &&
          data.variantnames.length > 0 &&
          data.variantnames[0].variations_media_raw &&
          data.variantnames[0].variations_media_raw.length > 0
        ) {
          filteredArrImage = _.filter(
            data.variantnames[0].variations_media_raw,
            function (o) {
              return o.file_type == "image";
            }
          );
        }
        if (
          data.variantnames &&
          data.variantnames.length > 0 &&
          data.variantnames[0].variations_media_raw &&
          data.variantnames[0].variations_media_raw.length > 0
        ) {
          filteredArrvideo = _.filter(
            data.variantnames[0].variations_media_raw,
            function (o) {
              return o.file_type == "video";
            }
          );
        }

        if (filteredArrImage.length > 0) {
          imageLength = filteredArrImage.length;
        }
        if (filteredArrvideo.length > 0) {
          videoLength = filteredArrvideo.length;
        }

        return (
          <>
            <div className="cols-span-4 ">
              {index == 0 ? (
                <div className="p-4 text-md">
                  {LocaleStrings.upload_products_photosoff}
                  {selectedvariantname}
                </div>
              ) : (
                <div className="p-4 text-md "></div>
              )}

              {data.variations_media_raw[0].file_type == "image" ? (
                <img
                  loading="lazy"
                  src={data.variations_media_raw[0].file_path}
                  className={classNames(index != 0 ? "mt-6" : "", "dropimg1")}
                />
              ) : data.variations_media_raw[0].file_type == "video" ? (
                <video controls controlsList="nodownload" className="dropimg1">
                  <source
                    src={`${data.variations_media_raw[0].file_path}#t=0.1`}
                    type="video/mp4"
                    className={classNames(index != 0 ? "mt-6" : "", "dropimg1")}
                  />
                </video>
              ) : isEdit ? (
                !_.isEmpty(data.variations_media[0]) &&
                data.variations_media[0]?.isdeleted === "0" ? (
                  data.variations_media[0].type === "image" ? (
                    <>
                      <div className="img_wrp- mx-auto">
                        <img
                          loading="lazy"
                          src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                          className={classNames(
                            index != 0 ? "mt-6" : "",
                            "dropimg1"
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {data.variations_media[0].id ? (
                        <div className="img_wrp- mx-auto">
                          <img
                            src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                            className={classNames(
                              index != 0 ? "mt-6" : "",
                              "dropimg1"
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <div className="mt-2 text-center">{fieldText}</div>
            </div>
          </>
        );
      });
    } else {
      var item = (
        <div className="">
          {LocaleStrings.upload_products_error_onevariant_details}
        </div>
      );
    }

    return item;
  };

  rendervariantVariationsStateList = () => {
    // LOOP OF THE CARTESIAN PRODUCT FORMED
    let { variantVariationsState } = this.state;
    let { isEdit } = this.props;
    let local_lang = this.props.language;
    let localCurrency = this.props.localCurrency;
    var item = _.map(variantVariationsState, (data, index) => {
      let arr = [];
      arr.push(data);
      let fieldText = ProductTextLocalized(
        data.name_en,
        data.name_ar,
        local_lang
      );
      let currency = currencyCode(localCurrency);

      let filteredArr = [];

      if (data.variations_media_raw && data.variations_media_raw.length > 0) {
        filteredArr = _.filter(data.variations_media_raw, function (o) {
          return o.file_type != "";
        });
      }

      return (
        <>
          {/* <div className="hidden md:block">
            <div className="cols-span-1 text-center mt-8 font-bold">
              {index + 1}.
            </div>
            <div className="cols-span-1 text-center">
              {data.variations_media_raw &&
              data.variations_media_raw[0].file_type == "image" ? (
                <img
                  loading="lazy"
                  src={data.variations_media_raw[0].file_path}
                  className="dropimg1"
                />
              ) : data.variations_media_raw &&
                data.variations_media_raw[0].file_type == "video" ? (
                <video controls controlsList="nodownload" className="dropimg1">
                  <source
                    src={`${data.variations_media_raw[0].file_path}#t=0.1`}
                    type="video/mp4"
                  />
                </video>
              ) : isEdit ? (
                data.variations_media_raw &&
                !_.isEmpty(data.variations_media[0]) &&
                data.variations_media[0]?.isdeleted === "0" ? (
                  data.variations_media[0].type === "image" ? (
                    <>
                      <div className="img_wrp- mx-auto">
                        <img
                          loading="lazy"
                          src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                          className="dropimg1"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {data.variations_media_raw &&
                      data.variations_media[0].id ? (
                        <div className="img_wrp- mx-auto">
                          <img
                            src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                            className="dropimg1 "
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  <div className="bg-white p-6 text-center items-center">
                    <img src={"/images2/common/Vector.png"}></img>
                  </div>
                )
              ) : (
                <div className="bg-white p-6 text-center items-center">
                  <img src={"/images2/common/Vector.png"}></img>
                </div>
              )}
            </div>
            <div className="cols-span-1 mt-8 text-center">{fieldText}</div>
            <div className="cols-span-1 mt-8 text-center">Price Per Unit</div>
            {data.price_unit && parseInt(data.price_unit) > 0 ? (
              <div className="cols-span-1 mt-8 text-center">
                {currency}
                {data.price_unit}
              </div>
            ) : (
              <div className="bg-white p-6 text-center items-center mx-auto">
                <img src={"/images2/common/Vector.png"}></img>
              </div>
            )}

            <div className="cols-span-1 mt-8 text-center">Stock / Quantity</div>
            {data.quantity && parseInt(data.quantity) > 0 ? (
              <div className="cols-span-1 mt-8 text-center">
                {data.quantity}
              </div>
            ) : (
              <div className="bg-white p-6 text-center items-center mx-auto">
                <img src={"/images2/common/Vector.png"}></img>
              </div>
            )}
            {data.units && data.units != "" && data.units != null ? (
              <div className="cols-span-1 mt-8">{data.units}</div>
            ) : (
              <div className="bg-white p-6 text-center items-center mx-auto">
                <img src={"/images2/common/Vector.png"}></img>
              </div>
            )}
          </div> */}
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 border-2 border-gray-300 p-4 rounded-lg">
              <div className="flex">
                {data.variations_media_raw &&
                data.variations_media_raw[0].file_type == "image" ? (
                  <img
                    loading="lazy"
                    src={data.variations_media_raw[0].file_path}
                    className="dropimg1"
                  />
                ) : data.variations_media_raw &&
                  data.variations_media_raw[0].file_type == "video" ? (
                  <video
                    controls
                    controlsList="nodownload"
                    className="dropimg1">
                    <source
                      src={`${data.variations_media_raw[0].file_path}#t=0.1`}
                      type="video/mp4"
                    />
                  </video>
                ) : isEdit ? (
                  data.variations_media_raw &&
                  !_.isEmpty(data.variations_media[0]) &&
                  data.variations_media[0]?.isdeleted === "0" ? (
                    data.variations_media[0].type === "image" ? (
                      <>
                        <div className="img_wrp- mx-auto-">
                          <img
                            loading="lazy"
                            src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                            className="dropimg1"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {data.variations_media_raw &&
                        data.variations_media[0].id ? (
                          <div className="img_wrp- mx-auto">
                            <img
                              src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                              className="dropimg1 "
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  ) : (
                    <div className="bg-white p-6 text-center items-center">
                      <img src={"/images2/common/Vector.png"}></img>
                    </div>
                  )
                ) : (
                  <div className="bg-white p-6 text-center items-center">
                    <img src={"/images2/common/Vector.png"}></img>
                  </div>
                )}

                <div className="block ml-4">
                  {data.price_unit && parseInt(data.price_unit) > 0 ? (
                    <div className="flex mt-2 text-center">
                      {currency}
                      {data.price_unit}
                    </div>
                  ) : (
                    <div className="bg-white p-6 text-center items-center mx-auto">
                      <img src={"/images2/common/Vector.png"}></img>
                    </div>
                  )}
                  <div className="flex">
                    {data.quantity && parseInt(data.quantity) > 0 ? (
                      <div className="cols-span-1 mt-2 text-center">
                        {data.quantity}
                      </div>
                    ) : (
                      <div className="bg-white p-6 text-center items-center mx-auto">
                        <img src={"/images2/common/Vector.png"}></img>
                      </div>
                    )}{" "}
                    {data.units && data.units != "" && data.units != null ? (
                      <div className="cols-span-1 mt-2">{data.units}</div>
                    ) : (
                      <div className="bg-white p-6 text-center items-center mx-auto">
                        <img src={"/images2/common/Vector.png"}></img>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-2 mt-2 ">{fieldText}</div>
            </div>
          </div>
        </>
      );
    });

    return item;
  };

  rendervariantVariationsStateListmobile = () => {
    // LOOP OF THE CARTESIAN PRODUCT FORMED
    let { variantVariationsState } = this.state;
    let { isEdit } = this.props;
    let local_lang = this.props.language;
    let localCurrency = this.props.localCurrency;
    var item = _.map(variantVariationsState, (data, index) => {
      let arr = [];
      arr.push(data);
      let fieldText = ProductTextLocalized(
        data.name_en,
        data.name_ar,
        local_lang
      );
      let currency = currencyCode(localCurrency);

      let filteredArr = [];

      if (data.variations_media_raw && data.variations_media_raw.length > 0) {
        filteredArr = _.filter(data.variations_media_raw, function (o) {
          return o.file_type != "";
        });
      }

      return (
        <>
          <div className="cols-span-1 text-center mt-8 font-bold">
            {index + 1}.
          </div>
          <div className="cols-span-1 text-center">
            {data.variations_media_raw &&
            data.variations_media_raw[0].file_type == "image" ? (
              <img
                loading="lazy"
                src={data.variations_media_raw[0].file_path}
                className="dropimg1"
              />
            ) : data.variations_media_raw &&
              data.variations_media_raw[0].file_type == "video" ? (
              <video controls controlsList="nodownload" className="dropimg1">
                <source
                  src={`${data.variations_media_raw[0].file_path}#t=0.1`}
                  type="video/mp4"
                />
              </video>
            ) : isEdit ? (
              data.variations_media_raw &&
              !_.isEmpty(data.variations_media[0]) &&
              data.variations_media[0]?.isdeleted === "0" ? (
                data.variations_media[0].type === "image" ? (
                  <>
                    <div className="img_wrp- mx-auto">
                      <img
                        loading="lazy"
                        src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                        className="dropimg1"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {data.variations_media_raw &&
                    data.variations_media[0].id ? (
                      <div className="img_wrp- mx-auto">
                        <img
                          src={`${BASE_IMAGES_URL}/vendor/variations/${data.variations_media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                          className="dropimg1 "
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )
              ) : (
                <div className="bg-white p-6 text-center items-center">
                  <img src={"/images2/common/Vector.png"}></img>
                </div>
              )
            ) : (
              <div className="bg-white p-6 text-center items-center">
                <img src={"/images2/common/Vector.png"}></img>
              </div>
            )}
          </div>
          <div className="cols-span-1 mt-8 text-center">{fieldText}</div>
          <div className="cols-span-1 mt-8 text-center">
            {LocaleStrings.upload_products_price_perunit}
          </div>
          {data.price_unit && parseInt(data.price_unit) > 0 ? (
            <div className="cols-span-1 mt-8 text-center">
              {currency}
              {data.price_unit}
            </div>
          ) : (
            <div className="bg-white p-6 text-center items-center mx-auto">
              <img src={"/images2/common/Vector.png"}></img>
            </div>
          )}

          <div className="cols-span-1 mt-8 text-center">
            {LocaleStrings.upload_products_price_stocks}
          </div>
          {data.quantity && parseInt(data.quantity) > 0 ? (
            <div className="cols-span-1 mt-8 text-center">{data.quantity}</div>
          ) : (
            <div className="bg-white p-6 text-center items-center mx-auto">
              <img src={"/images2/common/Vector.png"}></img>
            </div>
          )}
          {data.units && data.units != "" && data.units != null ? (
            <div className="cols-span-1 mt-8">{data.units}</div>
          ) : (
            <div className="bg-white p-6 text-center items-center mx-auto">
              <img src={"/images2/common/Vector.png"}></img>
            </div>
          )}
        </>
      );
    });

    return item;
  };

  _handelVariationValue = (e, val) => {
    let { variationMasterList } = this.props;
    let valueArr = [];
    valueArr.push(val);
    this.setState({ variationValue: valueArr });
  };

  _handelVariationcombinationselection = (
    e,
    variantv_en,
    variantv_ar,
    variationValue,
    variant_ar_iscustom,
    variant_en_iscustom
  ) => {
    let { variationMasterList, isEdit, initialValues } = this.props;
    let { vatiantsFeildArrState, variantVariationsState } = this.state;
    let finalArr = vatiantsFeildArrState;
    let filteredArr = [];

    if (vatiantsFeildArrState.length > 0 && variationValue.length > 0) {
      filteredArr = _.filter(vatiantsFeildArrState, function (o) {
        return o.id == variationValue[0].id;
      });
    }

    if (filteredArr.length == 0) {
      let obj = {};

      obj.name_en = variationValue[0].name_en;
      obj.name_ar = variationValue[0].name_ar;
      obj.action = "insert";
      obj.id = variationValue[0].id;
      obj.refkey = randomString(6);
      obj.iscustom =
        variationValue[0].iscustom && variationValue[0].iscustom == true
          ? true
          : false;
      obj.variantnames = [
        {
          refkey: randomString(6),
          name_en: variantv_en,
          name_ar: variantv_ar,
          action: "insert",
          isSelected: true,
          iscustom: variant_en_iscustom,
          match: variationValue[0].id + "-" + variantv_en,
          variations_media_raw: [
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
          ],
          variations_media: [],
        },
      ];

      if (
        variationValue[0].idspecification &&
        variationValue[0].isvariant &&
        variationValue[0].isvariant == 1
      ) {
        obj.idspecification = variationValue[0].idspecification;
      } else {
        obj.mastervariantid = variationValue[0].id;
      }
      finalArr.push(obj);

      this.setState({ vatiantsFeildArrState: finalArr });
      this.setState({ newVariantAdded: true });
    } else {
      let checkToggle = _.filter(filteredArr[0].variantnames, function (o) {
        return o.match == variationValue[0].id + "-" + variantv_en;
      });
      let findIndexv = _.findIndex(finalArr, function (o) {
        return o.id == variationValue[0].id;
      });
      if (checkToggle.length > 0) {
        let uniquev = _.filter(finalArr[findIndexv].variantnames, function (o) {
          return o.match != variationValue[0].id + "-" + variantv_en;
        });
        finalArr[findIndexv].variantnames = uniquev;
        this.setState({ vatiantsFeildArrState: finalArr });
        // this.setState({ newVariantAdded: true });

        // Added to remove array from vatiantsFeildArrState if not even one is selected
        let finalunique = [];
        _.forEach(this.state.vatiantsFeildArrState, (val, i) => {
          if (val.variantnames && val.variantnames.length !== 0) {
            finalunique.push(val);
          }
        });

        this.setState({ vatiantsFeildArrState: finalunique });
        this.setState({ newVariantAdded: true });
      } else {
        finalArr[findIndexv].variantnames.push({
          refkey: randomString(6),
          name_en: variantv_en,
          name_ar: variantv_ar,
          action: "insert",
          isSelected: true,
          iscustom: variant_en_iscustom,
          variantid: isEdit ? filteredArr[0].variantnames[0].variantid : null,
          match: variationValue[0].id + "-" + variantv_en,
          // variations_media: isEdit
          //   ? filteredArr[0].variantnames[0].variations_media
          //   : [],
          // media: isEdit ? filteredArr[0].variantnames[0].media : [],
          variations_media_raw: [
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
            {
              type: "",
              file_type: "",
              file_name: "",
              file_thumbnail_name: "",
              file_path: "",
              thumbnamil_path: "",
              raw: "",
              raw_thumbnail: "",
            },
          ],
          variations_media: [],
        });

        this.setState({ vatiantsFeildArrState: finalArr });
        this.setState({ newVariantAdded: true });
      }
    }
    // removed checked now if variation updates all data needs to be given from first 01/06/23
    // if (isEdit) {
    let newvatiantsFeildArrState = [];
    let newvariantVariationsState = [];

    if (variantVariationsState.length > 0) {
      _.forEach(variantVariationsState, (v, i) => {
        let obj = _.cloneDeep(v);
        obj.variations_media = [];
        obj.variations_media_raw = [
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
          {
            type: "",
            file_type: "",
            file_name: "",
            file_thumbnail_name: "",
            file_path: "",
            thumbnamil_path: "",
            raw: "",
            raw_thumbnail: "",
          },
        ];
        newvariantVariationsState.push(obj);
        // if(obj.variation_media && obj.variation_media.length > 0 ? (
        //   _.forEach(obj.variation_media, (vm1, im1) => {
        //       let vmobj =
        //   })
        // ):(''))
      });
    }
    if (vatiantsFeildArrState.length > 0) {
      _.forEach(vatiantsFeildArrState, (v, i) => {
        let obj = _.cloneDeep(v);
        let newvariantnamesarr = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.forEach(obj.variantnames, (vn1, in1) => {
            let vnobj = _.cloneDeep(vn1);
            vnobj.variations_media = [];
            vnobj.variations_media_raw = [
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
              {
                type: "",
                file_type: "",
                file_name: "",
                file_thumbnail_name: "",
                file_path: "",
                thumbnamil_path: "",
                raw: "",
                raw_thumbnail: "",
              },
            ];
            newvariantnamesarr.push(vnobj);
          });
          obj.variantnames = newvariantnamesarr;
          newvatiantsFeildArrState.push(obj);
        }
      });
    }
    this.setState({ vatiantsFeildArrState: newvatiantsFeildArrState });
    this.setState({ variantVariationsState: [newvariantVariationsState] });
    this.setState({ newVariantimageRemove: true });
    // }
  };

  newVariantimageRemove = () => {
    this.setState({ newVariantimageRemove: false });
    this.setState({ onEditUpdatedvariant: true });
  };

  renderMasterVariationValueList = () => {
    let { enar, variationValue, vatiantsFeildArrState } = this.state;
    // console.log("variationValue[0].value_en", variationValue);
    let splitvariation_arr = [];

    if (variationValue.length > 0) {
      if (variationValue[0].value_en && variationValue[0].value_en.length > 0) {
        // splitvariation_arr = _.split(variationValue[0].value_en, ",");

        var item = _.map(variationValue[0].value_en, (val, index) => {
          let variant_ar = variationValue[0].value_ar[index].val;
          let variant_en = val.val;
          let variant_ar_iscustom = variationValue[0].value_ar[index].iscustom;
          let variant_en_iscustom = val.iscustom;

          let filteredArr = [];
          if (vatiantsFeildArrState.length > 0 && variationValue.length > 0) {
            filteredArr = _.filter(
              this.state.vatiantsFeildArrState,
              function (o) {
                return o.id == variationValue[0].id;
              }
            );
          }

          // Filtered to show border of selected variants
          let newfilteredArr = [];
          if (filteredArr.length > 0) {
            newfilteredArr = _.filter(
              filteredArr[0].variantnames,
              function (o) {
                return o.name_en == variant_en;
              }
            );
          }

          let newfilteredArr1 = [];
          if (filteredArr.length > 0) {
            newfilteredArr1 = _.filter(
              filteredArr[0].variantnames,
              function (o) {
                return o.name_ar == variant_ar;
              }
            );
          }
          let fieldText = ProductTextLocalized(variant_en, variant_ar, enar); // language

          return (
            <>
              <div
                //  className="col-span-1 bg-gray-300 rounded p-2"
                className={classNames(
                  newfilteredArr.length > 0 || newfilteredArr1.length > 0
                    ? "border-2 border-primary"
                    : "",
                  "col-span-1 bg-gray-300 rounded p-2 cursor-pointer text-center relative"
                )}
                onClick={(e) =>
                  this._handelVariationcombinationselection(
                    e,
                    variant_en,
                    variant_ar,
                    variationValue,
                    variant_ar_iscustom,
                    variant_en_iscustom
                  )
                }>
                {/* {variationValue[0].iscustom && variationValue[0].iscustom == true ?(
                  <div className="absolute -top-3.5 -right-3 rounded rounded-full border-2 border-gray-300 bg-white"><img className="h-4 w-4" src={"/images2/xicon.svg"} ></img></div>
              ):('')} */}
                {/* {variant_en} - {variant_ar} */}
                {/* {variant_en} */}
                {fieldText}
              </div>
            </>
          );
        });
      }

      if (_.isEmpty(item)) {
        // If No item Spec Found Show Message
        return (
          <div className="col-span-4  p-2  text-center">
            {" "}
            <p className="paragraph-seconday">
              {LocaleStrings.upload_products_no_variationvalue}
            </p>
          </div>
        );
      }
    }

    return item;
  };

  renderspecsCustom = ({ fields, meta: { error, submitFailed } }) => (
    <ul>
      {fields.map((customspecs, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }
        //  imagePreviewUrl = currentInxedData?.image;
        return (
          <li key={index} style={{ padding: 0 }} className="mt-2">
            <div className="flex">
              <div className="mr-2">
                <Field
                  name={`${customspecs}.key_` + this.state.enar}
                  type="text"
                  component={this.renderFieldText}
                  className="mr-2"
                  placeholder="Add Specification"
                />
              </div>
              <div className="mr-2">
                <Field
                  name={`${customspecs}.value_` + this.state.enar}
                  type="text"
                  component={this.renderFieldText}
                  placeholder="Add Value"
                />
              </div>
              <button
                type="button"
                title="Remove Member"
                className={"text-end" + showDelBtn}
                onClick={() => fields.remove(index)}>
                <TrashIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </button>
            </div>
          </li>
        );
      })}
      <li className="center-item" style={{ paddingTop: 10 }}>
        <button
          className="btn-primary cursor-pointer"
          style={{ width: "130px" }}
          type="button"
          onClick={() => fields.push({})}>
          {LocaleStrings.upload_products_addvalues}
          {/* Add Values */}
        </button>

        {submitFailed && error && <span>{error}</span>}
      </li>
    </ul>
  );

  updateMastervariationList = (e) => {
    let { variationMasterList, formValues } = this.props;

    let variationListArr = [];
    variationListArr = variationMasterList;

    if (
      _.trim(formValues?.custom_variation) != "" &&
      formValues?.custom_variation != undefined
    ) {
      if (variationListArr.length > 0) {
        variationListArr[variationListArr.length] = {
          id: "1002" + randomString(3),
          isSelected: false,
          name_ar: formValues?.custom_variation,
          name_en: formValues?.custom_variation,
          iscustom: true,
          value_ar: [],
          value_en: [],
        };
      } else {
        variationListArr[0] = {
          id: "1002" + randomString(3),
          isSelected: false,
          name_ar: formValues?.custom_variation,
          name_en: formValues?.custom_variation,
          iscustom: true,
          value_ar: [],
          value_en: [],
        };
      }
      this.props.updateMastervariationListArray(variationListArr);
      this.props.dispatch(change("UploadProductForm", "custom_variation", ""));
    } else {
      toast.error("Please add custom variation");
    }
  };

  updateVariationValue = (e) => {
    let { variationMasterList, formValues } = this.props;
    let { variationValue, vatiantsFeildArrState } = this.state;
    if (
      _.trim(formValues?.custom_variation_value) != "" &&
      formValues?.custom_variation_value != undefined
    ) {
      let variationvalueListArr = variationMasterList;
      if (variationMasterList.length > 0 && variationValue.length > 0) {
        let findIndexvv = _.findIndex(variationvalueListArr, function (o) {
          return o.id == variationValue[0].id;
        });
        // console.log(
        //   "variationvalueListArr[findIndexvv]",
        //   variationvalueListArr[findIndexvv]
        // );
        let customobj = {
          val: formValues?.custom_variation_value,
          iscustom: true,
        };
        // variationvalueListArr[findIndexvv].value_en.push(
        //   formValues?.custom_variation_value
        // );
        // variationvalueListArr[findIndexvv].value_ar.push(
        //   formValues?.custom_variation_value
        // );

        variationvalueListArr[findIndexvv].value_en.push(customobj);
        variationvalueListArr[findIndexvv].value_ar.push(customobj);

        // variationvalueListArr[findIndexvv].iscustom.push(1);
      }
      this.props.updateMastervariationListArray(variationvalueListArr);
      this.props.dispatch(
        change("UploadProductForm", "custom_variation_value", "")
      );
    } else {
      // toast.error(LocaleStrings.upload_products_error_vriation_value);
      toast.error("Please Add Variation Value");
    }
  };

  /*** Render Global specification ***/
  renderGlobalSpecFields = () => {
    let { globalSpecifications, language } = this.props;
    let { enar } = this.state;

    let glob_spec_item = _.map(globalSpecifications, (globalSpec, index) => {
      let idglobalspecification = globalSpec.id;
      let name_en = globalSpec.name_en;
      let name_ar = globalSpec.name_ar;
      let fieldText = ProductTextLocalized(name_en, name_ar, language); // language
      let isrequired =
        globalSpec.ismandatory === "0" || globalSpec.ismandatory === 0
          ? ""
          : "true";

      return (
        <div className="mt-4">
          <Field
            name={"globalspec_name_" + enar + "_" + idglobalspecification}
            label={fieldText}
            placeholder={fieldText}
            type="text"
            component={this.renderFieldText}
            mandatory={isrequired}
            labelposition={LABEL_POSITION_TOP}
            className=""
            classNameLabel="text-box-lable"
          />
        </div>
      );
    });
    return glob_spec_item;
  };

  /***** RENDER DISCOUNT LIST (BASED ON CATE SUBCATE) *****/
  renderDiscountList = () => {
    let { discountlist } = this.props;

    var item = _.map(discountlist, (disclist, index) => {
      return (
        <div>
          <p className="text-sm font-medium text-ternary ">
            * {parseFloat(disclist.discount_percentage)}{" "}
            {disclist.discounttype === "fixed"
              ? LocaleStrings.flat
              : LocaleStrings.percent_sign}{" "}
            {LocaleStrings.discount_using} {disclist.title}{" "}
            {LocaleStrings.might_apply}
          </p>
        </div>
      );
    });
    return item;
  };

  /**** Handel MAKE A DONATION ****/
  _handleMakeDonation(e) {
    let value = e.target.value; // Return Oposite Value [OnClick]

    if (value === true || value === "true") {
      // If Remove Tick
      this.props.dispatch(change("UploadProductForm", "idcharityevents", ""));
      this.props.dispatch(
        change("UploadProductForm", "donation_percentage", "")
      );
      this.setState({ makeDonationState: false });
    } else {
      this.setState({ makeDonationState: true });
      this.setState({
        selected_charity_image: null,
        selected_charity_description_en: null,
        selected_charity_description_ar: null,
        selected_charity_name_en: null,
        selected_charity_name_ar: null,
        charity_donation_percentage: null,
        charity_iscustom: null,
      });
    }
  }

  openPost = (url, params) => {
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");
    for (let param in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("name", param);
      hiddenField.setAttribute("value", params[param]);
      formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
  };

  /******* FIELD ARRAY RELATED [STARTS] ********/

  addDynamicVariation(e, fields, variants, index) {
    if (this.state.newVariantAdded === false) {
      // Falg chnage when you add new variant
      this.setState({ newVariantAdded: true });
    }

    let currentVariantObject = fields.get(index);
    let till = 0;
    till = parseInt(e.target.value);

    // Inserting ref key [while chnageing the "No of variations"]
    if (!_.has(currentVariantObject, "refkey")) {
      currentVariantObject["refkey"] = randomString(6);
    }

    if (!_.has(currentVariantObject, "variantnames")) {
      // For first time [key not present]
      currentVariantObject["variantnames"] = []; // Insert the key with blank array

      // Now loop through "No. of variants"
      for (let i = 1; i <= till; i++) {
        currentVariantObject["variantnames"].push({ refkey: randomString(6) });
      }
    } else {
      let currentArrSize = _.size(currentVariantObject["variantnames"]); // Current array size

      if (till > currentArrSize) {
        // Do this operation only when "No. of variants" is greated then current object length
        till = till - currentArrSize; //  given value ['No. of variants'] Minus already created array size
        for (let i = 1; i <= till; i++) {
          // Loop through
          currentVariantObject["variantnames"].push({
            refkey: randomString(6),
          });
        }
      }
    }
  }

  removeDynamicVariation = (fields, index, parrentIndex, parrentfields) => {
    if (this.state.newVariantAdded === false) {
      // Falg chnage when you add new variant
      this.setState({ newVariantAdded: true });
    }

    // Inner [Variation delete]
    let currentVariantObject = parrentfields.get(parrentIndex); // Parent [Variant]

    // Remove clicked variation [which is child]
    fields.remove(index);

    currentVariantObject.quantity = parseInt(currentVariantObject.quantity) - 1; // Get curreny object quantity and do - 1

    this.props.change(
      `variants.${parrentIndex}.quantity`,
      parseInt(currentVariantObject.quantity)
    );
  };

  renderVariations = ({
    fields,
    meta: { error },
    parrentIndex,
    parrentfields,
  }) => (
    <ul>
      {fields.map((variantnames, index) => {
        /* Hidden showing condition */
        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }

        return (
          <li key={index}>
            <div className="grid grid-cols-2 gap-4 px-11 py-2">
              <div className="my-auto">
                <Field
                  name={`${variantnames}.name_` + this.state.enar}
                  label={LocaleStrings.variation_details_name}
                  placeholder={LocaleStrings.variation_details_name}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  className=""
                  classNameLabel="text-box-lable"
                />
              </div>
              <div className="hidden">
                <Field
                  name={`${variantnames}.refkey`}
                  label={"refkey"}
                  placeholder={"refkey"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                />
              </div>
              <div className="">
                <img
                  loading="lazy"
                  //onClick={() => fields.remove(index)}
                  onClick={() =>
                    this.removeDynamicVariation(
                      fields,
                      index,
                      parrentIndex,
                      parrentfields
                    )
                  }
                  // className={classNames(
                  //   index === 0 ? "" : "hidden",
                  //   "sidebar-icons cursor-pointer mt-4"
                  // )}

                  className={"sidebar-icons cursor-pointer mt-4" + showDelBtn}
                  src={"/images2/common/delete.svg"}
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );

  renderVariants = ({
    fields,
    meta: { error, submitFailed },
    option,
    value,
  }) => (
    <ul>
      {fields.map((variants, index) => {
        /* let showDelBtn =
        itemVariant.id !== 0
          ? "hidden"
          : vatiantsFeildArrState.length === 1
          ? "hidden"
          : ""; */

        let showDelBtn = fields.length === 1 ? " hidden" : "";
        if (fields.get(index).id) {
          showDelBtn = " hidden ";
        }

        return (
          <li key={index}>
            <div className="flex justify-between ...">
              <div>
                <p className="paragraph-seconday">
                  {LocaleStrings.enter_the_variant_details}
                </p>
              </div>
              <div>
                <img
                  loading="lazy"
                  onClick={() => fields.remove(index)}
                  className={"sidebar-icons cursor-pointer mt-4" + showDelBtn}
                  src={"/images2/common/delete.svg"}
                />
              </div>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Field
                  name={`${variants}.name_${this.state.enar}`}
                  label={LocaleStrings.product_variation_name}
                  placeholder={LocaleStrings.product_variation_name}
                  type="text"
                  component={this.renderDatalistSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  datalistoptn={option}
                  onChange={this._handleDataList}
                  classNameLabel="text-box-lable"
                  list={`name_${this.state.enar}`}
                />
              </div>
              <div className=" hidden">
                <Field
                  name={`${variants}.refkey`}
                  label={"refkey"}
                  placeholder={"refkey"}
                  type="text"
                  component={this.renderFieldText}
                  mandatory="false"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Field
                  name={`${variants}.quantity`}
                  label={LocaleStrings.product_noof_variants}
                  placeholder={LocaleStrings.product_noof_variants}
                  // type="number"
                  type="text"
                  // pattern="[0-9]*"
                  inputMode="numeric"
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                  min="0"
                  pattern="[0-9]{1,2}"
                  normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                  onChange={(e) =>
                    this.addDynamicVariation(e, fields, variants, index)
                  }
                />
              </div>
            </div>

            <FieldArray
              name={`${variants}.variantnames`}
              parrentIndex={index}
              parrentfields={fields}
              component={this.renderVariations}
            />
          </li>
        );
      })}

      <li style={{ paddingTop: 0 }}>
        <div className="text-right mt-6">
          <button
            type="button"
            className="btn-primary-right"
            onClick={() => fields.push({})}>
            {LocaleStrings.add_more_variation_btn_text}
          </button>
        </div>
      </li>
    </ul>
  );

  /*** Single variant functions  ***/
  handleVariantDetailsKeyUp(e, curIndex, field) {
    let { variantVariationsState } = this.state;
    // let variantVariationsState1 = _.cloneDeep(variantVariationsState);
    // return false;
    let newArr = [];

    if (variantVariationsState.length > 0) {
      if (field === "isavailable") {
        // As Its return 'on' / 'off'
        variantVariationsState[curIndex][field] =
          e.target.checked === true ? 1 : 0;
      } else {
        _.forEach(variantVariationsState, (v, i) => {
          let val = v;
          val[field] = e.target.value;
          newArr.push(val);
        });

        // variantVariationsState[curIndex][field] = e.target.value;
      }
      this.setState({ variantVariationsState: newArr });
    }
  }

  /* update varint variation state from details page */
  variantVariationsStateUpdateFc = (arr1, callfrom) => {
    if (Object.keys(arr1).length > 0) {
      // let variationDetailsArr = JSON.parse(JSON.stringify(arr1));
      // console.log("variationDetailsArr", variationDetailsArr);
      this.setState({ variantVariationsState: arr1 });
      if (callfrom == "delete") {
        this.setState({ newVariantAdded: true });
      }
    }
  };

  /******* FIELD ARRAY RELATED [ENDS] ******/

  /**** On FORM SUBMIT *****/ //basevariants this.state.vatiantsFeildArrState vatiantsFeildArrState
  onFormSubmit(values) {
    console.log("values1", values);
    let {
      vatiantsFeildArrState,
      variantVariationsState,
      incrementalTypeState,
      returnType,
      onEditUpdatedvariant,
      enar,
    } = this.state; // basevariants , detailedvariants

    let {
      selectedSubcategorids,
      storedItemSpecifications,
      globalSpecifications,
      session,
      prodDiscAddedData,
      producvtSubmitConfirm,
      shippingType,
      isEdit,
      localCurrency,
      settingsMasterStore,
      vendorDetails,
      listingamount,
      productexcludeLocationdata,
      formValues1,
    } = this.props;
    let productidForpersonalvendor = "";
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let isError = true;
    let uploaderCounter = 0;
    let allKeysName = _.keysIn(values);

    let product_specifications = [];
    let product_specifications_custom = [];
    let arrPromise = [];
    let filetobeupload = [];
    // console.log("variantVariationsState", variantVariationsState);

    /****  All SUBMIT TYPE VALIDATION ****/
    if (
      variantVariationsState.length == 1 &&
      (variantVariationsState[0].price_unit == 0 ||
        variantVariationsState[0].quantity == "" ||
        variantVariationsState[0].units == "")
    ) {
      toast.error(LocaleStrings.upload_product_variation_notadded_alert);
      return false;
    }

    if (_.isEmpty(vatiantsFeildArrState)) {
      // Base Variants
      toast.error(LocaleStrings.product_variation_alert);
      isError = false;
    }

    if (_.isEmpty(variantVariationsState)) {
      // Detailed Variants Validation
      toast.error(`Some of your variations are missing!`);
      isError = false;
    } else {
      if (
        !_.isEmpty(prodDiscAddedData) &&
        prodDiscAddedData.discounttype === "fixed"
      ) {
        /* 
        Detailed Variant is present check flat discount is less then any amount
        Example - if orginal price is 5 then i should not create FLAT discount 6 then it 
        */
        _.forEach(variantVariationsState, function (dv, dvindex) {
          if (
            parseFloat(prodDiscAddedData.discount_percentage) >
            parseFloat(dv.price_unit)
          ) {
            // Detailed Variants Validation
            toast.error(
              `Discounted price cannot be greater than the actual price! Please check variant details under product variant tab`
            );
            isError = false;
            return false;
          }
        });
      }
    }

    if (_.isEmpty(selectedSubcategorids)) {
      // Category - Subcategory
      toast.error(LocaleStrings.product_cat_sub_cateegory_alert);
      isError = false;
    } else if (
      _.isEmpty(selectedSubcategorids[0]) ||
      _.isEmpty(selectedSubcategorids[1])
    ) {
      // Category - Subcategory
      toast.error(LocaleStrings.product_cat_sub_cateegory_alert);
      isError = false;
    } else {
      // # no code
    }

    if (
      shippingType === "flat" &&
      (values["shipping_cost_f"] === "" || values["shipping_cost_f"] === null)
    ) {
      toast.error(LocaleStrings.enter_shipping_cost);
      isError = false;
    }

    if (
      shippingType === "calculated" &&
      (values["shipping_cost_c"] === "" || values["shipping_cost_c"] === null)
    ) {
      toast.error(LocaleStrings.enter_shipping_cost);
      isError = false;
    }
    if (shippingType !== "local" && returnType !== "no_return") {
      if (
        values.shippingcostbearer == "" ||
        values.shippingcostbearer == null
      ) {
        if (values.saletype !== "auction") {
          toast.error(
            LocaleStrings.upload_products_error_shippingcost_required
          );
          isError = false;
        }
      }
    }
    if (values.make_donation && values.make_donation == true) {
      // latest add
      if (!values.idcharityevents || values.idcharityevents == "") {
        toast.error(
          LocaleStrings.upload_products_error_addcharity_for_donation
        );
        isError = false;
      }
    }
    // latest code for date check
    // if (
    //   values.hasOwnProperty("auction_end_date") &&
    //   values.hasOwnProperty("auction_start_date")
    // ) {

    //   let endate = moment(values.auction_end_date).format(
    //     DATE_FORMAT_WITH_TIME_WITH_SECOND
    //   );
    //   let startdate = moment(values.auction_start_date).format(
    //     DATE_FORMAT_WITH_TIME_WITH_SECOND
    //   );
    //   var curTime = moment();
    //   curTime = curTime.format("YYYY-MM-DD HH:mm:ss");
    //   if (startdate < curTime || endate < curTime) {
    //     toast.error("Please select future date");
    //     isError = false;
    //   }
    // }

    if (isEdit && values.saletype === "auction") {
      if (
        values.auction_start_date == "" ||
        values.auction_start_date == null ||
        values.auction_end_date == "" ||
        values.auction_end_date == null
      ) {
        toast.error("Please select start date to relist auction product");
        return false;
      }
    }

    if (isError) {
      /**** Go for Details Screen ****/

      // ADD COLUMN IF NOT THERE
      if (!values.hasOwnProperty("shippingcostbearer")) {
        values["shippingcostbearer"] = null;
      }
      // if (values.hasOwnProperty("isdraft") && values.isdraft == 1) {
      //   values.isdraft = 0;
      // }
      if (!values.hasOwnProperty("productname_ar")) {
        values["productname_ar"] = "";
      }
      if (!values.hasOwnProperty("product_desc_ar")) {
        values["product_desc_ar"] = "";
      }
      if (!values.hasOwnProperty("specification_desc_ar")) {
        values["specification_desc_ar"] = "";
      }

      if (!values.hasOwnProperty("productname_en")) {
        values["productname_en"] = "";
      }
      if (!values.hasOwnProperty("product_desc_en")) {
        values["product_desc_en"] = "";
      }
      if (!values.hasOwnProperty("specification_desc_en")) {
        values["specification_desc_en"] = "";
      }

      /****  AUCTION ****/

      //incremental Values
      if (values["saletype"] === "auction") {
        values["incremental_type"] = incrementalTypeState;

        // If auction make "bestofferenabled" -> false (below we will convert false to "no")
        values["bestofferenabled"] = false;
      }

      if (values.hasOwnProperty("auction_start_date")) {
        values["auction_start_date"] = moment(values.auction_start_date).format(
          DATE_FORMAT_WITH_TIME_WITH_SECOND
        );
      }

      if (values.hasOwnProperty("auction_end_date")) {
        values["auction_end_date"] = moment(values.auction_end_date).format(
          DATE_FORMAT_WITH_TIME_WITH_SECOND
        );
      }

      /*** Creating global specification [Under product specification] object ***/

      // _.map(globalSpecifications, (item, index) => {
      //   let keyNameEn = "globalspec_name_en_" + item.id;
      //   let keyNameAr = "globalspec_name_ar_" + item.id;

      //   if (
      //     _.indexOf(allKeysName, keyNameEn) > -1 ||
      //     _.indexOf(allKeysName, keyNameAr) > -1
      //   ) {
      //     product_specifications.push({
      //       idspecification: item.id,
      //       value_en: values[keyNameEn] ? values[keyNameEn] : "",
      //       value_ar: values[keyNameAr] ? values[keyNameAr] : "",
      //       name_en: item.name_en,
      //       name_ar: item.name_ar,
      //     });
      //   }
      // });

      /**** Creating Item Specification Object ****/
      let specserror = 0;

      _.map(storedItemSpecifications.required, (item, index) => {
        // console.log("item", item);
        let keyNameEn = "itemspec_name_en_" + item.id;
        let keyNameAr = "itemspec_name_ar_" + item.id;
        // console.log("keyNameEn", keyNameEn);
        if (
          _.indexOf(allKeysName, keyNameEn) > -1 ||
          _.indexOf(allKeysName, keyNameAr) > -1
        ) {
          // console.log("allKeysNamevalues[keyNameEn]", allKeysName);
          if (
            (!values[keyNameEn] || _.trim(values[keyNameEn]) == "") &&
            (!values[keyNameAr] || _.trim(values[keyNameAr]) == "")
          ) {
            // console.log("values[keyNameEn]", values[keyNameEn]);
            specserror += 1;
          }
          let masterid = parseInt(item.id);
          let v_en = values[keyNameEn];
          let v_ar = values[keyNameAr];
          let isencustom = false;
          let isarcustom = false;

          let filteredArr = _.filter(
            storedItemSpecifications.required,
            function (o) {
              return parseInt(o.id) == masterid;
            }
          );

          if (filteredArr.length > 0) {
            // console.log("values[keyNameEn]", values[keyNameEn]);
            // console.log("values[v_en]", v_en);
            // console.log("v_en", !filteredArr[0].value_en.includes(v_en));
            // console.log("v_ar", !filteredArr[0].value_ar.includes(v_ar));

            if (v_en != "" && !filteredArr[0].value_en.includes(v_en)) {
              isencustom = true;
            }

            if (v_ar != "" && !filteredArr[0].value_ar.includes(v_ar)) {
              isarcustom = true;
            }
          }
          // debugger;
          product_specifications.push({
            idspecification: item.id,
            value_en:
              values[keyNameEn] && values[keyNameEn] != ""
                ? values[keyNameEn]
                : "",
            value_ar: values[keyNameAr] ? values[keyNameAr] : "",
            name_en: item.name_en,
            name_ar: item.name_ar,
            isencustom: isencustom,
            isarcustom: isarcustom,
          });
        } else {
          specserror += 1;
        }
      });

      if (specserror != 0) {
        toast.error(LocaleStrings.upload_products_error_required_specs);
        return false;
      }

      _.map(storedItemSpecifications.additional, (item, index) => {
        let keyNameEn = "itemspec_name_en_" + item.id;
        let keyNameAr = "itemspec_name_ar_" + item.id;

        if (
          _.indexOf(allKeysName, keyNameEn) > -1 ||
          _.indexOf(allKeysName, keyNameAr) > -1
        ) {
          let masterid = parseInt(item.id);
          let v_en = values[keyNameEn];
          let v_ar = values[keyNameAr];

          let isencustom = false;
          let isarcustom = false;

          let filteredArr = _.filter(
            storedItemSpecifications.additional,
            function (o) {
              return parseInt(o.id) == masterid;
            }
          );
          // debugger;
          if (filteredArr.length > 0) {
            if (v_en != "" && !filteredArr[0].value_en.includes(v_en)) {
              isencustom = true;
            }

            if (_.trim(v_ar) != "" && !filteredArr[0].value_ar.includes(v_ar)) {
              isarcustom = true;
            }
          }
          // debugger;
          product_specifications.push({
            idspecification: item.id,
            isencustom: isencustom,
            isarcustom: isarcustom,
            value_en: values[keyNameEn] ? values[keyNameEn] : "",
            value_ar: values[keyNameAr] ? values[keyNameAr] : "",
            name_en: item.name_en,
            name_ar: item.name_ar,
          });
        }
      });

      if (
        values.product_specification_custom &&
        Object.keys(values.product_specification_custom).length > 0
      ) {
        _.map(values.product_specification_custom, (val, i) => {
          // console.log("val", val);
          if (!_.isEmpty(val) && (val.value_en != "" || val.value_ar != "")) {
            product_specifications.push({
              idspecification: null,
              iscustom: true,
              value_en: val.value_en ? val.value_en : "",
              value_ar: val.value_ar ? val.value_ar : val.value_en,
              name_en: val.key_en ? val.key_en : "",
              name_ar: val.key_ar ? val.key_ar : "",
            });
          }
        });
      }
      // console.log(
      //   "product_specification_custom",
      //   values.product_specification_custom
      // );
      // console.log("product_specifications", product_specifications);
      // return false;

      /**** 
        Put Item Specification / global Specification Object
        Note : - product_specifications => blank array / key is required
      ****/

      values["product_specifications"] = product_specifications; // If empty it will go with blank array | else with data

      /**** 
      Mainly at time of add product
      Product Currency 
      ****/
      values.currency = localCurrency; // Inserting localcurrency

      // Category and sub-category
      if (_.isEmpty(selectedSubcategorids)) {
        toast.error(LocaleStrings.cate_subcate_error_text);
      } else {
        values["categoryids"] = _.join(selectedSubcategorids[1], ",");
        values["subcategoryids"] = _.join(selectedSubcategorids[0], ",");
      }

      // Removing 'Enable Auto translate'
      if (values.hasOwnProperty("autotranslate_ischeck")) {
        let e = "";
        let enar1 = enar == "en" ? "ar" : "en";
        setTimeout(() => {
          this.translate(e, enar1, "insidesubmit");
        }, 2000);
        // values = _.omit(values, ["autotranslate_ischeck"]);
      }

      // return false;

      // Enable best offer (Sales formate and pricing)
      if (values.hasOwnProperty("bestofferenabled")) {
        if (values["bestofferenabled"]) {
          values["bestofferenabled"] = "yes";
        } else {
          values["bestofferenabled"] = "no";
        }
      }

      // Removing Make a Donation (Set preferences)
      if (values.hasOwnProperty("make_donation")) {
        values = _.omit(values, ["make_donation"]);
      }

      // Removing Shipping service (Sales formate and pricing)
      if (values.hasOwnProperty("delivery_company")) {
        values = _.omit(values, ["delivery_company"]);
      }

      let shipping_cost = 0;

      if (values.hasOwnProperty("shipping_cost_f")) {
        shipping_cost = values["shipping_cost_f"];
        values = _.omit(values, ["shipping_cost_f"]);
      } else if (values.hasOwnProperty("shipping_cost_c")) {
        shipping_cost = values["shipping_cost_c"];
        values = _.omit(values, ["shipping_cost_c"]);
      }
      let error_pr_unit_qa_check = 0;
      // detailedvariants / detailedVariants  [Media only]
      _.forEach(variantVariationsState, function (value, outter_index) {
        if (
          value.price_unit == "" ||
          value.quantity == "" ||
          value.units == ""
        ) {
          error_pr_unit_qa_check += 1;
        }
        // here value = variations like -> red-xl and 5 media

        if (!isEdit || onEditUpdatedvariant) {
          // If its add make blank array and insert the new one into it
          value.variations_media = [];
        }

        if (!parseInt(values.hasvariant) || values.saletype == "auction") {
          // If product has no variant change the name to product name
          value.name_en = values.productname_en;
          value.name_ar = values.productname_ar;
        }

        _.forEach(
          value.variations_media_raw,
          function (innerValue, inner_index) {
            if (innerValue.file_type) {
              // Main File
              let coverJson = {
                type: innerValue.type,
                name: innerValue.file_name,
                media: innerValue.raw.url,
              };

              // Thumbnail
              let coverJson1 = {
                type: "image/jpeg",
                name: innerValue.file_thumbnail_name,
                media: innerValue.raw_thumbnail.url,
              };

              filetobeupload.push(coverJson); //
              filetobeupload.push(coverJson1); //

              // If Edit
              if (isEdit) {
                // Putting data into
                value.variations_media.push({
                  type: innerValue.file_type,
                  mediafilename: innerValue.file_name,
                  thumbfilename: innerValue.file_thumbnail_name,
                  isdeleted: false,
                  id: 0,
                });
              } else {
                // Putting data into
                // value.variations_media[inner_index] = {
                value.variations_media.push({
                  type: innerValue.file_type,
                  mediafilename: innerValue.file_name,
                  thumbfilename: innerValue.file_thumbnail_name,
                  isdeleted: false,
                  id: 0,
                });
              }
            } else {
            }
          }
        );

        /* If no chnage while update create same media which is there already */
        _.forEach(value.media, function (item, index) {
          if (!isEdit) {
            // no idea
            if ((!_.has(value), "variations_media")) {
              value["variations_media"] = [];
            }
          }
        });
      });

      // return false;

      values["shipping_cost"] = shipping_cost; // Adding Shipping cost from 2 fileds

      /* IF ANY VALUES IS MISSING ADD THOSE  HERE*/

      _.forEach(vatiantsFeildArrState, (variantObj, vindex) => {
        if (
          formValues1 &&
          formValues1.hasdefaultphoto &&
          (formValues1.hasdefaultphoto == "0" ||
            formValues1.hasdefaultphoto == 0) &&
          formValues1.variation_photo
        ) {
          if (variantObj.id == formValues1.variation_photo) {
            variantObj["hasdefaultphoto"] = 0;
          }
        }

        if (!_.has(variantObj, "name_en")) {
          variantObj["name_en"] = "";
        }

        if (!_.has(variantObj, "name_ar")) {
          variantObj["name_ar"] = "";
        }

        if (!_.has(variantObj, "isdeleted")) {
          variantObj["isdeleted"] = false;
        }

        if (!_.has(variantObj, "id") || !isEdit) {
          variantObj["id"] = 0;
        }

        // If no variant the change variant names to product name [Example color ]
        if (!parseInt(values.hasvariant) || values.saletype == "auction") {
          variantObj["name_en"] = values.productname_en;
          variantObj["name_ar"] = values.productname_ar;
        }

        _.forEach(variantObj.variantnames, (variantnamesObj, vvindex) => {
          if (!_.has(variantnamesObj, "id" || !isEdit)) {
            variantnamesObj["id"] = 0;
          }

          if (!_.has(variantnamesObj, "name_en")) {
            variantnamesObj["name_en"] = "";
          }

          if (!_.has(variantnamesObj, "name_ar")) {
            variantnamesObj["name_ar"] = "";
          }

          if (!_.has(variantnamesObj, "isdeleted")) {
            variantnamesObj["isdeleted"] = false;
          }

          // If no variant the change variant names to product name [Example - red]
          if (!parseInt(values.hasvariant) || values.saletype == "auction") {
            variantnamesObj["name_en"] = values.productname_en;
            variantnamesObj["name_ar"] = values.productname_ar;
          }
        });
      });
      // console.log("variantVariationsState;", variantVariationsState);
      values["basevariants"] = vatiantsFeildArrState;
      values["detailedvariants"] = variantVariationsState;

      let counter = filetobeupload.length; // Count Of tobe upload files

      if (!_.isEmpty(productexcludeLocationdata)) {
        const entries = Object.entries(productexcludeLocationdata);
        let mainarr = [];
        _.forEach(entries, function (v, i) {
          let label = v[0];
          let value = v[1];
          let obj = {};
          obj[label] = value;
          mainarr.push(obj);
        });

        values["product_exclude_country"] = mainarr;
      }

      if (formValues1 && formValues1.hasdefaultphoto) {
        values.hasdefaultphoto = formValues1.hasdefaultphoto;
      }

      let imageError = 0;
      if (values.detailedvariants && values.detailedvariants.length > 0) {
        let deletecounter = 0;
        _.forEach(values.detailedvariants, function (innerValue1) {
          _.forEach(innerValue1.variations_media, function (innerValue2) {
            if (innerValue2.isdeleted == 1 || innerValue2.isdeleted == "1") {
              deletecounter++;
            }
          });
          if (deletecounter === innerValue1.variations_media.length) {
            imageError = 1;
          }
        });
      }
      if (imageError != 0) {
        toast.error(LocaleStrings.upload_products_error_imageaddition);
        return false;
      }
      if (error_pr_unit_qa_check != 0) {
        toast.error(LocaleStrings.upload_products_error_required_price_qa_un);
        return false;
      }
      console.log("values", values);
      debugger;
      // return false;

      let addEdit = () => {
        // Ok to go for edit and add
        this.props.fetchProductFeeDetails(values);
        if (!isEdit) {
          //If Not EDIT SHOW DETAILS PAGE
          /**** Show Free Product And Send Selected data to Store  ****/
          // values["source"] = "web";

          values["isapproved"] = session.usertype === "personal" ? 0 : 1; //
          values["isactive"] = session.usertype === "personal" ? 0 : 1; //
          values["listed"] = session.usertype === "personal" ? 0 : 1; //
        }

        if (values.auctiontype && values.auctiontype == true) {
          values.listed = 0;
        }

        if (session.usertype === "personal") {
          values["order_processedby"] = "sa";
        }
        // if (producvtSubmitConfirm || isEdit ) {
        if (
          producvtSubmitConfirm ||
          (isEdit && parseInt(values.isdraft) == 0)
        ) {
          // Its confirmed
          this.setState({ btnLoader: true }); // Start The button Loader
          this.setState({ isBtnDisable: true }); // Set Button Disabled

          if (counter > 0) {
            // Store File to server first
            filetobeupload.map((item, index) => {
              arrPromise.push(
                new Promise((resolve, reject) => {
                  this.props.uploadMediaLargeVendorVariation(
                    this.props.session,
                    item,
                    (response) => {
                      if (response.success === 0) {
                        resolve();
                      } else {
                        uploaderCounter++;
                        resolve();
                      }
                    }
                  );
                })
              );
            });

            Promise.all(arrPromise).then(() => {
              if (uploaderCounter === counter) {
                /* UPLOAD AT TIME OF ADD */
                // console.log("values-1-", values);
                // return false;
                values.isdraft = 0;
                this.props.addproduct(
                  this.props.session,
                  values,
                  (callback) => {
                    let productid = callback.productid;
                    productidForpersonalvendor = callback.productid;
                    /* Add Discount after product add */
                    if (_.isEmpty(prodDiscAddedData)) {
                      toast.success(
                        isEdit
                          ? LocaleStrings.product_updated_successfully
                          : LocaleStrings.product_added_successfully
                      );
                      this.props.unSetTobeEditProductStore(); //latest code
                      this.setState({ btnLoader: false });
                      this.setState({ isBtnDisable: false }); // Set Button Disabled false
                    } else {
                      let discountObject = {
                        discounttype: prodDiscAddedData.discounttype,
                        title: prodDiscAddedData.discount_title,
                        validity_from: moment(
                          prodDiscAddedData.validity_from
                        ).format(DATE_FORMAT_WITH_TIME_WITH_SECOND),
                        validity_end: moment(
                          prodDiscAddedData.validity_end
                        ).format(DATE_FORMAT_WITH_TIME_WITH_SECOND),
                        discount_percentage:
                          prodDiscAddedData.discount_percentage,
                        createdby: this.props.session.userid,
                        discountarea_by_iddiscount: [
                          { idproduct: productid, discounareaid: 4 },
                        ],
                        fromproduct: 1,
                        callfrom: "makediscountvendor", // latest code
                      };

                      if (isEdit) {
                        discountObject["id"] = prodDiscAddedData.id;

                        // If Product Discount Is there Add It with Product ID
                        this.props.updateDiscountMaster(
                          this.props.session,
                          discountObject,
                          (callBackInner) => {
                            toast.success(
                              isEdit
                                ? LocaleStrings.product_updated_successfully
                                : LocaleStrings.product_added_successfully
                            );
                            this.props.unSetTobeEditProductStore(); //latest code
                            this.setState({ btnLoader: false });
                            this.setState({ isBtnDisable: false }); // Set Button Disabled false
                          }
                        );
                      } else {
                        // If Product Discount Is there Add It with Product ID
                        this.props.addDiscountMaster(
                          this.props.session,
                          discountObject,
                          (callBackInner) => {
                            toast.success(
                              isEdit
                                ? LocaleStrings.product_updated_successfully
                                : LocaleStrings.product_added_successfully
                            );
                            this.props.reset();
                            this.setState({ btnLoader: false });
                            this.setState({ isBtnDisable: false }); // Set Button Disabled false
                          }
                        );
                      }
                    }

                    // Clear All Sate and reinitialize redux

                    this.setState({ selectedServiceId: 0 });
                    this.setState({ productTitle: "" });
                    this.setState({ productSubTitle: "" });
                    this.setState({ showerror: false });
                    this.setState({ vatiantsFeildArrState: [] });
                    this.setState({ defaultSaleType: "" });
                    this.props.currentShippngType("flat");
                    this.setState({ returnType: "" });
                    this.setState({
                      incrementalTypeState: "percentage",
                    });
                    console.log(3);
                    this.setState({ variantVariationsState: {} });
                    this.setState({ specificationState: [] });
                    this.setState({ enar: "en" });
                    this.setState({ istaxable: 0 });

                    /**** Item / Product specification Object ****/
                    product_specifications = [];

                    this.props.allSelectedSubcategorisID({});
                    this.props.fetchProductFeeDetails({});

                    this.props.productAddedDiscount({});
                    this.props.clearDiscountList({}); // Clear discount list
                    this.props.continentLength({}); // Clear continent length
                    this.props.productExculdelocation({}); //

                    if (
                      session.usertype === "personal" &&
                      settingsMasterStore.listing_perproduct > 0
                    ) {
                      let totalAmountToPAy = getTotalPriceToPay(
                        settingsMasterStore,
                        vendorDetails.tax
                      );

                      /* If persobal user charge for listing product [Send to payment page] */
                      let orderids = session.userid + "o" + productid; // this should be useridoproductid, userid and productid concatenated with o

                      /* Create a product array similar to this, you can send quantity=1, price= actual payment price */
                      let productArray = [
                        {
                          productname_en: values.productname_en,
                          price: totalAmountToPAy,
                          quantity: 1,
                        },
                      ];

                      var params = [];

                      //params["total_amount"] = totalAmountToPAy / currencyrate; // send the Total Payment price here
                      params["total_amount"] =
                        localCurrency === "USD"
                          ? totalAmountToPAy * CURRENCY_RATE_QR
                          : totalAmountToPAy; // send the Total Payment price here
                      params["userid"] = session.userid;
                      params["orderids"] = orderids;
                      params["products"] = JSON.stringify(productArray);
                      params["origin"] = "web";

                      // this.openPost(
                      //   "https://shopez.teks.co.in/payment/personalpay.php",
                      //   params
                      // ); // Replace this with https://shopez.teks.co.in/payment/personalpay.php
                      // Latest code personal vendor after create redirected to fatorapay
                      let params1 = {};
                      params1.productid = productidForpersonalvendor;
                      params1.amount = listingamount;
                      params1.variantid = null;
                      params1.callfrom = "web";
                      // params1.paytype = "makepayment";
                      params1.paytype = "makepersonalpayment"; //latest
                      params1.relist = 0;
                      params1.callbackurl = `http://shopezvendor.staging.teks.co.in/dashboard/productmanagement`;

                      if (!isEdit) {
                        this.props.makepaymentPersonalvendor(
                          session,
                          params1,
                          (callback) => {
                            if (callback.success == 1) {
                              // toast.error('Payment Failed , something went wrong2');
                              if (
                                callback.data &&
                                (callback.data.success == 1 ||
                                  callback.data.success == true)
                              ) {
                                if (
                                  callback.data?.checkout_url &&
                                  (callback.data?.checkout_url !== null ||
                                    callback.data?.checkout_url !== "")
                                ) {
                                  // console.log('callback',callback)
                                  let checkouturl = callback.data?.checkout_url;
                                  window.location.href = checkouturl;
                                } else {
                                  toast.error(
                                    LocaleStrings.repayment_nourlfound
                                  );
                                }
                              } else {
                                toast.error(
                                  LocaleStrings.repayment_paymentfailed
                                );
                              }
                            } else {
                              toast.error(
                                LocaleStrings.repayment_paymentfailed
                              );
                            }
                          }
                        );
                      } else {
                        this.props.parentCallBack();
                        let newTabs = _.map(this.props.tabs, (value, index) => {
                          if (value.tabindex === 1) {
                            value.current = true;
                          } else {
                            value.current = false;
                          }

                          return value;
                        });

                        this.props.tabListWithSelection(newTabs);
                        setTimeout(() => {
                          window.location.reload();
                        }, 4000);
                      }
                    } else {
                      if (this.props.fromApp) {
                        window.location.replace(
                          "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                        );
                        // this.props.parentCallBack();
                      } else {
                        // window.location.replace(
                        //   "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                        // );
                        this.props.parentCallBack();
                        let newTabs = _.map(this.props.tabs, (value, index) => {
                          if (value.tabindex === 1) {
                            value.current = true;
                          } else {
                            value.current = false;
                          }

                          return value;
                        });

                        this.props.tabListWithSelection(newTabs);
                        setTimeout(() => {
                          window.location.reload();
                        }, 4000);
                      }
                    }
                  }
                );
              } else {
                toast.error(LocaleStrings.something_went_wrong);
                this.setState({ btnLoader: false });
                this.setState({ isBtnDisable: false }); // Set Button Disabled false
              }
            });
          } else {
            if (isEdit) {
              console.log("values-2-", values);
              // console.log("relist_1", this.props.relist_while_product_upload);
              if (this.props.relist_while_product_upload == true) {
                values.listed = 1;
                // this.props.toggleunlistproduct(session, 1, values.id, (callback) => {
                // });
              }

              /* UPLOAD AT TIME OF EDIT */
              values.isdraft = 0;
              this.props.addproduct(this.props.session, values, (callback) => {
                let productid = callback.productid;
                productidForpersonalvendor = callback.productid;
                this.props.relistwhileUpdatingProduct(false);
                if (_.isEmpty(prodDiscAddedData)) {
                  toast.success(
                    isEdit
                      ? LocaleStrings.product_updated_successfully
                      : LocaleStrings.product_added_successfully
                  );
                  this.props.unSetTobeEditProductStore(); //latest code
                  this.setState({ btnLoader: false }); // End The button Loader
                  this.setState({ isBtnDisable: false }); // unSet Button Disabled
                } else {
                  let discountObject = {
                    discounttype: prodDiscAddedData.discounttype,
                    title: prodDiscAddedData.discount_title,
                    validity_from: moment(
                      prodDiscAddedData.validity_from
                    ).format(DATE_FORMAT_WITH_TIME_WITH_SECOND),
                    validity_end: moment(prodDiscAddedData.validity_end).format(
                      DATE_FORMAT_WITH_TIME_WITH_SECOND
                    ),
                    discount_percentage: prodDiscAddedData.discount_percentage,
                    createdby: this.props.session.userid,
                    discountarea_by_iddiscount: [
                      { idproduct: productid, discounareaid: 4 },
                    ],
                    fromproduct: 1,
                    callfrom: "makediscountvendor", // latest code
                  };

                  if (isEdit && _.has(prodDiscAddedData, "id")) {
                    // Insert Discount ID If its edit & discount is aleady there
                    discountObject["id"] = prodDiscAddedData.id;
                  }

                  if (_.has(discountObject, "id")) {
                    // Update product -> update discount

                    // If Product Discount Is there Add It with Product ID
                    this.props.updateDiscountMaster(
                      this.props.session,
                      discountObject,
                      (callBackInner) => {
                        toast.success(
                          isEdit
                            ? LocaleStrings.product_updated_successfully
                            : LocaleStrings.product_added_successfully
                        );
                        this.props.unSetTobeEditProductStore(); //latest code
                        this.setState({ btnLoader: false });
                        this.setState({ isBtnDisable: false }); // Set Button Disabled false
                      }
                    );
                  } else {
                    this.props.addDiscountMaster(
                      this.props.session,
                      discountObject,
                      (callBackInner) => {
                        toast.success(
                          isEdit
                            ? LocaleStrings.product_updated_successfully
                            : LocaleStrings.product_added_successfully
                        );
                        this.props.unSetTobeEditProductStore(); //latest code
                        this.setState({ btnLoader: false });
                        this.setState({ isBtnDisable: false }); // Set Button Disabled false
                      }
                    );
                  }
                }
                // {new added to }
                if (
                  (this.props.initialValues.isdraft == 1 ||
                    this.props.initialValues.isdraft == "1") &&
                  session.usertype === "personal" &&
                  settingsMasterStore.listing_perproduct > 0
                ) {
                  let params1 = {};
                  params1.productid = productidForpersonalvendor;
                  params1.amount = listingamount;
                  params1.variantid = null;
                  params1.callfrom = "web";
                  // params1.paytype = "makepayment";
                  params1.paytype = "makepersonalpayment"; //latest
                  params1.relist = 0;
                  params1.callbackurl = `http://shopezvendor.staging.teks.co.in/dashboard/productmanagement`;

                  this.props.makepaymentPersonalvendor(
                    session,
                    params1,
                    (callback) => {
                      if (callback.success == 1) {
                        // toast.error('Payment Failed , something went wrong2');
                        if (
                          callback.data &&
                          (callback.data.success == 1 ||
                            callback.data.success == true)
                        ) {
                          if (
                            callback.data?.checkout_url &&
                            (callback.data?.checkout_url !== null ||
                              callback.data?.checkout_url !== "")
                          ) {
                            // console.log('callback',callback)
                            let checkouturl = callback.data?.checkout_url;
                            window.location.href = checkouturl;
                          } else {
                            toast.error(LocaleStrings.repayment_nourlfound);
                          }
                        } else {
                          toast.error(LocaleStrings.repayment_paymentfailed);
                        }
                      } else {
                        toast.error(LocaleStrings.repayment_paymentfailed);
                      }
                    }
                  );
                }

                // Clear All Sate and reinitialize redux

                this.setState({ selectedServiceId: 0 });
                this.setState({ productTitle: "" });
                this.setState({ productSubTitle: "" });
                this.setState({ showerror: false });
                this.setState({ vatiantsFeildArrState: [] });
                this.setState({ defaultSaleType: "" });
                this.props.currentShippngType("flat");
                this.setState({ returnType: "" });
                this.setState({ incrementalTypeState: "percentage" });
                console.log(4);
                this.setState({ variantVariationsState: {} });
                this.setState({ specificationState: [] });
                this.setState({ enar: "en" });
                this.setState({ istaxable: 0 });

                /**** Item specification Object ****/
                product_specifications = [];

                this.props.allSelectedSubcategorisID({});
                this.props.fetchProductFeeDetails({});
                // this.props.parentCallBack(); // After Add Redirect

                if (this.props.fromApp) {
                  window.location.replace(
                    "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                  );
                  // this.props.parentCallBack();
                } else {
                  // window.location.replace(
                  //   "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                  // );
                  this.props.parentCallBack();
                  let newTabs = _.map(this.props.tabs, (value, index) => {
                    if (value.tabindex === 1) {
                      value.current = true;
                    } else {
                      value.current = false;
                    }

                    return value;
                  });

                  this.props.tabListWithSelection(newTabs);
                  setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                }

                this.props.productAddedDiscount({});
                this.props.clearDiscountList({}); // Clear discount list
                this.props.continentLength({}); // Clear continent length
                this.props.productExculdelocation({}); // Clear productExculdelocation list
              });
            }
          }
        } else {
          // Go for Cinfirmation
          this.props.productFeeModal(true);
        }
      };

      // ***** validation with enble best price [if yes or true] *****/
      // if (
      //   values.hasOwnProperty("bestofferenabled") &&
      //   values["bestofferenabled"] === "yes"
      // ) {
      //   let minprice = _.minBy(values["detailedvariants"], function (o) {
      //     return o.price_unit;
      //   }).price_unit;
      //   let maxprice = _.maxBy(values["detailedvariants"], function (o) {
      //     return o.price_unit;
      //   }).price_unit;

      //   let bestPriceObjectParams = {
      //     categoryids: values["categoryids"],
      //     subcategoryids: values["subcategoryids"],
      //     price: minprice + "-" + maxprice,
      //   };

      //   this.props.enabledbestoffer(
      //     this.props.session,
      //     bestPriceObjectParams,
      //     (callback) => {
      //       if (callback.status === 1 || callback.status === "1") {
      //         if (callback.data.valid === 1 || callback.data.valid === "1") {
      //           // If best price enable is true / yes
      //           addEdit(values); // a block function
      //         } else {
      //           toast.error(
      //             LocaleStrings.upload_product_enable_best_price_alert_text
      //           );
      //           return false;
      //         }
      //       } else {
      //         toast.error(LocaleStrings.common_fail_message);
      //         return false;
      //       }
      //     }
      //   );
      // } else {
      //   // If best price enable is false / no

      // }

      addEdit(values); // a block function
    } else {
    }
  }

  onSaveasDraft = (e) => {
    // ************************* ALL RESTRICTIONS AND CHECKS ARE REMOVED FOR DRAFT **************************
    let {
      vatiantsFeildArrState,
      variantVariationsState,
      incrementalTypeState,
      returnType,
      onEditUpdatedvariant,
    } = this.state; // basevariants , detailedvariants

    let {
      selectedSubcategorids,
      storedItemSpecifications,
      globalSpecifications,
      session,
      prodDiscAddedData,
      producvtSubmitConfirm,
      shippingType,
      isEdit,
      localCurrency,
      settingsMasterStore,
      vendorDetails,
      listingamount,
      formValues,
      productexcludeLocationdata,
      formValues1,
    } = this.props;
    let productidForpersonalvendor = "";
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    // debugger;

    let values = formValues;

    if (!values.productname_en && !values.productname_ar) {
      // console.log("1");
      toast.error(`Please add product name to add draft`);
      return false;
    }
    if (values.productname_en == "" && !values.productname_ar == "") {
      // console.log("2");
      toast.error(`Please add product name to add draft`);
      return false;
    }

    values.isdraft = 1;

    let isError = true;
    let uploaderCounter = 0;
    let allKeysName = _.keysIn(values);

    let product_specifications = [];
    let arrPromise = [];
    let filetobeupload = [];

    /****  All SUBMIT TYPE VALIDATION ****/
    if (
      variantVariationsState.length == 1 &&
      (variantVariationsState[0].price_unit == 0 ||
        variantVariationsState[0].quantity == "" ||
        variantVariationsState[0].units == "")
    ) {
      // toast.error(LocaleStrings.upload_product_variation_notadded_alert);
      // return false;
    }
    if (_.isEmpty(vatiantsFeildArrState)) {
      // Base Variants
      // toast.error(LocaleStrings.product_variation_alert);
      // isError = false;
    }
    // if (_.isEmpty(variantVariationsState)) {
    if (
      !_.isEmpty(vatiantsFeildArrState) &&
      _.isEmpty(variantVariationsState)
    ) {
      // Detailed Variants Validation
      toast.error("Some of your variations are missing!");
      isError = false;
    } else {
      if (
        !_.isEmpty(prodDiscAddedData) &&
        prodDiscAddedData.discounttype === "fixed"
      ) {
        /* 
        Detailed Variant is present check flat discount is less then any amount
        Example - if orginal price is 5 then i should not create FLAT discount 6 then it 
        */
        _.forEach(variantVariationsState, function (dv, dvindex) {
          if (
            parseFloat(prodDiscAddedData.discount_percentage) >
            parseFloat(dv.price_unit)
          ) {
            // Detailed Variants Validation
            // toast.error(
            //   LocaleStrings.actualprice_greater_than_discountprice_alert
            // );
            // isError = false;
            // return false;
          }
        });
      }
    }

    if (_.isEmpty(selectedSubcategorids)) {
      // Category - Subcategory
      // toast.error(LocaleStrings.product_cat_sub_cateegory_alert);
      // isError = false;
    } else if (
      _.isEmpty(selectedSubcategorids[0]) ||
      _.isEmpty(selectedSubcategorids[1])
    ) {
      // Category - Subcategory
      // toast.error(LocaleStrings.product_cat_sub_cateegory_alert);
      // isError = false;
    } else {
      // # no code
    }

    if (
      shippingType === "flat" &&
      (values["shipping_cost_f"] === "" || values["shipping_cost_f"] === null)
    ) {
      values = _.omit(values, ["shipping_cost_f"]);
      // toast.error(LocaleStrings.enter_shipping_cost);
      // isError = false;
    }

    if (
      shippingType === "calculated" &&
      (values["shipping_cost_c"] === "" || values["shipping_cost_c"] === null)
    ) {
      values = _.omit(values, ["shipping_cost_c"]);
      // toast.error(LocaleStrings.enter_shipping_cost);
      // isError = false;
    }
    // if (shippingType !== "local" && returnType !== 'no_return') {
    //   if (values.shippingcostbearer == '' || values.shippingcostbearer == null) {
    //     if (values.saletype !== 'auction') {
    //       toast.error('Shipping cost Bearer is required');
    //       isError = false;
    //     }

    //   }
    // }
    // latest code for date check
    // if (
    //   values.hasOwnProperty("auction_end_date") &&
    //   values.hasOwnProperty("auction_start_date")
    // ) {
    //   let endate = moment(values.auction_end_date).format(
    //     DATE_FORMAT_WITH_TIME_WITH_SECOND
    //   );
    //   let startdate = moment(values.auction_start_date).format(
    //     DATE_FORMAT_WITH_TIME_WITH_SECOND
    //   );
    //   var curTime = moment();
    //   curTime = curTime.format("YYYY-MM-DD HH:mm:ss");
    //   if (startdate < curTime || endate < curTime) {
    //     toast.error("Please select future date");
    //     isError = false;
    //   }
    // }

    if (isError) {
      /**** Go for Details Screen ****/

      // ADD COLUMN IF NOT THERE
      if (!values.hasOwnProperty("shippingcostbearer")) {
        values["shippingcostbearer"] = null;
      }

      if (!values.hasOwnProperty("productname_ar")) {
        values["productname_ar"] = "";
      }
      if (!values.hasOwnProperty("product_desc_ar")) {
        values["product_desc_ar"] = "";
      }
      if (!values.hasOwnProperty("specification_desc_ar")) {
        values["specification_desc_ar"] = "";
      }

      if (!values.hasOwnProperty("productname_en")) {
        values["productname_en"] = "";
      }
      if (!values.hasOwnProperty("product_desc_en")) {
        values["product_desc_en"] = "";
      }
      if (!values.hasOwnProperty("specification_desc_en")) {
        values["specification_desc_en"] = "";
      }

      /****  AUCTION ****/
      // debugger;
      //incremental Values
      if (values["saletype"] === "auction") {
        values["incremental_type"] = incrementalTypeState;

        // If auction make "bestofferenabled" -> false (below we will convert false to "no")
        values["bestofferenabled"] = false;
      }

      if (
        values.hasOwnProperty("auction_start_date") &&
        values.auction_start_date != "0000-00-00 00:00:00"
      ) {
        values["auction_start_date"] = moment(values.auction_start_date).format(
          DATE_FORMAT_WITH_TIME_WITH_SECOND
        );
      }

      if (
        values.hasOwnProperty("auction_end_date") &&
        values.auction_end_date != "0000-00-00 00:00:00"
      ) {
        values["auction_end_date"] = moment(values.auction_end_date).format(
          DATE_FORMAT_WITH_TIME_WITH_SECOND
        );
      }

      /*** Creating global specification [Under product specification] object ***/

      // _.map(globalSpecifications, (item, index) => {
      //   let keyNameEn = "globalspec_name_en_" + item.id;
      //   let keyNameAr = "globalspec_name_ar_" + item.id;

      //   if (
      //     _.indexOf(allKeysName, keyNameEn) > -1 ||
      //     _.indexOf(allKeysName, keyNameAr) > -1
      //   ) {
      //     product_specifications.push({
      //       idspecification: item.id,
      //       value_en: values[keyNameEn] ? values[keyNameEn] : "",
      //       value_ar: values[keyNameAr] ? values[keyNameAr] : "",
      //       name_en: item.name_en,
      //       name_ar: item.name_ar,
      //     });
      //   }
      // });

      /**** Creating Item Specification Object ****/
      _.map(storedItemSpecifications.required, (item, index) => {
        let keyNameEn = "itemspec_name_en_" + item.id;
        let keyNameAr = "itemspec_name_ar_" + item.id;

        if (
          _.indexOf(allKeysName, keyNameEn) > -1 ||
          _.indexOf(allKeysName, keyNameAr) > -1
        ) {
          let masterid = parseInt(item.id);
          let v_en = values[keyNameEn];
          let v_ar = values[keyNameAr];
          let isencustom = false;
          let isarcustom = false;

          let filteredArr = _.filter(
            storedItemSpecifications.required,
            function (o) {
              return parseInt(o.id) == masterid;
            }
          );

          if (filteredArr.length > 0) {
            if (v_en != "" && !filteredArr[0].value_en.includes(v_en)) {
              isencustom = true;
            }

            if (v_ar != "" && !filteredArr[0].value_ar.includes(v_ar)) {
              isarcustom = true;
            }
          }

          product_specifications.push({
            idspecification: item.id,
            value_en: values[keyNameEn] ? values[keyNameEn] : "",
            value_ar: values[keyNameAr] ? values[keyNameAr] : "",
            name_en: item.name_en,
            name_ar: item.name_ar,
            isencustom: isencustom,
            isarcustom: isarcustom,
          });
        }
      });

      _.map(storedItemSpecifications.additional, (item, index) => {
        let keyNameEn = "itemspec_name_en_" + item.id;
        let keyNameAr = "itemspec_name_ar_" + item.id;

        if (
          _.indexOf(allKeysName, keyNameEn) > -1 ||
          _.indexOf(allKeysName, keyNameAr) > -1
        ) {
          let masterid = parseInt(item.id);
          let v_en = values[keyNameEn];
          let v_ar = values[keyNameAr];

          let isencustom = false;
          let isarcustom = false;

          let filteredArr = _.filter(
            storedItemSpecifications.additional,
            function (o) {
              return parseInt(o.id) == masterid;
            }
          );

          if (filteredArr.length > 0) {
            if (v_en != "" && !filteredArr[0].value_en.includes(v_en)) {
              isencustom = true;
            }

            if (_.trim(v_ar) != "" && !filteredArr[0].value_ar.includes(v_ar)) {
              isarcustom = true;
            }
          }
          product_specifications.push({
            idspecification: item.id,
            isencustom: isencustom,
            isarcustom: isarcustom,
            value_en: values[keyNameEn] ? values[keyNameEn] : "",
            value_ar: values[keyNameAr] ? values[keyNameAr] : "",
            name_en: item.name_en,
            name_ar: item.name_ar,
          });
        }
      });

      if (
        values.product_specification_custom &&
        Object.keys(values.product_specification_custom).length > 0
      ) {
        _.forEach(values.product_specification_custom, (val, i) => {
          if (!_.isEmpty(val) && (val.value_en != "" || val.value_ar != "")) {
            product_specifications.push({
              idspecification: null,
              iscustom: true,
              value_en: val.value_en ? val.value_en : "",
              value_ar: val.value_ar ? val.value_ar : "",
              name_en: val.key_en ? val.key_en : "",
              name_ar: val.key_ar ? val.key_ar : "",
            });
          }
        });
      }

      /**** 
        Put Item Specification / global Specification Object
        Note : - product_specifications => blank array / key is required
      ****/

      values["product_specifications"] = product_specifications; // If empty it will go with blank array | else with data

      /**** 
      Mainly at time of add product
      Product Currency 
      ****/
      values.currency = localCurrency; // Inserting localcurrency

      // Category and sub-category
      if (_.isEmpty(selectedSubcategorids)) {
        // toast.error(LocaleStrings.cate_subcate_error_text);
      } else {
        values["categoryids"] = _.join(selectedSubcategorids[1], ",");
        values["subcategoryids"] = _.join(selectedSubcategorids[0], ",");
      }

      // Removing 'Enable Auto translate'
      if (values.hasOwnProperty("autotranslate_ischeck")) {
        // values = _.omit(values, ["autotranslate_ischeck"]);
      }

      // Enable best offer (Sales formate and pricing)
      if (values.hasOwnProperty("bestofferenabled")) {
        if (values["bestofferenabled"]) {
          values["bestofferenabled"] = "yes";
        } else {
          values["bestofferenabled"] = "no";
        }
      }

      // Removing Make a Donation (Set preferences)
      if (values.hasOwnProperty("make_donation")) {
        values = _.omit(values, ["make_donation"]);
      }

      // Removing Shipping service (Sales formate and pricing)
      if (values.hasOwnProperty("delivery_company")) {
        values = _.omit(values, ["delivery_company"]);
      }

      let shipping_cost = 0;

      if (values.hasOwnProperty("shipping_cost_f")) {
        shipping_cost = values["shipping_cost_f"];
        values = _.omit(values, ["shipping_cost_f"]);
      } else if (values.hasOwnProperty("shipping_cost_c")) {
        shipping_cost = values["shipping_cost_c"];
        values = _.omit(values, ["shipping_cost_c"]);
      }

      // detailedvariants / detailedVariants  [Media only]
      let detailserror = 0;
      if (variantVariationsState.length > 0) {
        _.forEach(variantVariationsState, function (value, outter_index) {
          // console.log("value", value);
          // debugger;

          if (
            value.quantity == "" ||
            value.price == 0 ||
            value.units == "" ||
            value.variations_media_raw.file_path == ""
          ) {
            detailserror = 1;
          }
          // here value = variations like -> red-xl and 5 media

          if (!isEdit || onEditUpdatedvariant) {
            // If its add make blank array and insert the new one into it
            value.variations_media = [];
          }

          if (!parseInt(values.hasvariant) || values.saletype == "auction") {
            // If product has no variant change the name to product name
            value.name_en = values.productname_en;
            value.name_ar = values.productname_ar;
          }

          _.forEach(
            value.variations_media_raw,
            function (innerValue, inner_index) {
              if (innerValue.file_type) {
                // Main File
                let coverJson = {
                  type: innerValue.type,
                  name: innerValue.file_name,
                  media: innerValue.raw.url,
                };

                // Thumbnail
                let coverJson1 = {
                  type: "image/jpeg",
                  name: innerValue.file_thumbnail_name,
                  media: innerValue.raw_thumbnail.url,
                };

                filetobeupload.push(coverJson); //
                filetobeupload.push(coverJson1); //

                // If Edit
                if (isEdit) {
                  // Putting data into
                  value.variations_media.push({
                    type: innerValue.file_type,
                    mediafilename: innerValue.file_name,
                    thumbfilename: innerValue.file_thumbnail_name,
                    isdeleted: false,
                    id: 0,
                  });
                } else {
                  // Putting data into
                  // value.variations_media[inner_index] = {
                  value.variations_media.push({
                    type: innerValue.file_type,
                    mediafilename: innerValue.file_name,
                    thumbfilename: innerValue.file_thumbnail_name,
                    isdeleted: false,
                    id: 0,
                  });
                }
              } else {
              }
            }
          );

          /* If no chnage while update create same media which is there already */
          _.forEach(value.media, function (item, index) {
            if (!isEdit) {
              // no idea
              if ((!_.has(value), "variations_media")) {
                value["variations_media"] = [];
              }
            }
          });
        });
      }
      // return false;

      values["shipping_cost"] = shipping_cost; // Adding Shipping cost from 2 fileds

      /* IF ANY VALUES IS MISSING ADD THOSE  HERE*/
      if (vatiantsFeildArrState.length > 0) {
        _.forEach(vatiantsFeildArrState, (variantObj, vindex) => {
          if (
            formValues1 &&
            formValues1.hasdefaultphoto &&
            (formValues1.hasdefaultphoto == "0" ||
              formValues1.hasdefaultphoto == 0) &&
            formValues1.variation_photo
          ) {
            if (variantObj.id == formValues1.variation_photo) {
              variantObj["hasdefaultphoto"] = 0;
            }
          }

          if (!_.has(variantObj, "name_en")) {
            variantObj["name_en"] = "";
          }

          if (!_.has(variantObj, "name_ar")) {
            variantObj["name_ar"] = "";
          }

          if (!_.has(variantObj, "isdeleted")) {
            variantObj["isdeleted"] = false;
          }

          if (!_.has(variantObj, "id") || !isEdit) {
            variantObj["id"] = 0;
          }

          // If no variant the change variant names to product name [Example color ]
          if (!parseInt(values.hasvariant) || values.saletype == "auction") {
            variantObj["name_en"] = values.productname_en;
            variantObj["name_ar"] = values.productname_ar;
          }

          _.forEach(variantObj.variantnames, (variantnamesObj, vvindex) => {
            if (!_.has(variantnamesObj, "id") || !isEdit) {
              variantnamesObj["id"] = 0;
            }

            if (!_.has(variantnamesObj, "name_en")) {
              variantnamesObj["name_en"] = "";
            }

            if (!_.has(variantnamesObj, "name_ar")) {
              variantnamesObj["name_ar"] = "";
            }

            if (!_.has(variantnamesObj, "isdeleted")) {
              variantnamesObj["isdeleted"] = false;
            }

            // If no variant the change variant names to product name [Example - red]
            if (!parseInt(values.hasvariant) || values.saletype == "auction") {
              variantnamesObj["name_en"] = values.productname_en;
              variantnamesObj["name_ar"] = values.productname_ar;
            }
          });
        });
      }
      values["basevariants"] = vatiantsFeildArrState;

      values["detailedvariants"] = variantVariationsState;

      let counter = filetobeupload.length; // Count Of tobe upload files

      if (!_.isEmpty(productexcludeLocationdata)) {
        const entries = Object.entries(productexcludeLocationdata);
        let mainarr = [];
        _.forEach(entries, function (v, i) {
          let label = v[0];
          let value = v[1];
          let obj = {};
          obj[label] = value;
          mainarr.push(obj);
        });

        values["product_exclude_country"] = mainarr;
      }

      if (formValues1 && formValues1.hasdefaultphoto) {
        values.hasdefaultphoto = formValues1.hasdefaultphoto;
      }
      if (detailserror != 0) {
        toast.error(`Please add Variation Details to save draft`);
        return false;
      }
      this.setState({ draftbtnLoader: true });

      console.log("value", values);
      // debugger;

      let addEdit = () => {
        // Ok to go for edit and add
        if (!isEdit) {
          //If Not EDIT SHOW DETAILS PAGE
          /**** Show Free Product And Send Selected data to Store  ****/
          // values["source"] = "web";
          // this.props.fetchProductFeeDetails(values);

          values["isapproved"] = session.usertype === "personal" ? 0 : 1; //
          values["isactive"] = session.usertype === "personal" ? 0 : 1; //
          values["listed"] = session.usertype === "personal" ? 0 : 1; //
        }

        if (session.usertype === "personal") {
          values["order_processedby"] = "sa";
        }

        if (values.auctiontype && values.auctiontype == true) {
          values.listed = 0;
        }
        // console.log('values',values);return false;
        // Its confirmed
        this.setState({ draftbtnLoader: true }); // Start The button Loader
        this.setState({ isBtnDisable: true }); // Set Button Disabled

        if (counter > 0) {
          // Store File to server first
          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                this.props.uploadMediaLargeVendorVariation(
                  this.props.session,
                  item,
                  (response) => {
                    if (response.success === 0) {
                      resolve();
                    } else {
                      uploaderCounter++;
                      resolve();
                    }
                  }
                );
              })
            );
          });

          Promise.all(arrPromise).then(() => {
            if (uploaderCounter === counter) {
              /* UPLOAD AT TIME OF ADD */

              this.props.addproduct(this.props.session, values, (callback) => {
                let productid = callback.productid;
                productidForpersonalvendor = callback.productid;
                /* Add Discount after product add */
                if (_.isEmpty(prodDiscAddedData)) {
                  toast.success(
                    isEdit
                      ? LocaleStrings.product_updated_successfully
                      : LocaleStrings.product_added_successfully
                  );
                  this.props.unSetTobeEditProductStore(); //latest code
                  this.setState({ draftbtnLoader: false });
                  this.setState({ isBtnDisable: false }); // Set Button Disabled false
                } else {
                  let discountObject = {
                    discounttype: prodDiscAddedData.discounttype,
                    title: prodDiscAddedData.discount_title,
                    validity_from: moment(
                      prodDiscAddedData.validity_from
                    ).format(DATE_FORMAT_WITH_TIME_WITH_SECOND),
                    validity_end: moment(prodDiscAddedData.validity_end).format(
                      DATE_FORMAT_WITH_TIME_WITH_SECOND
                    ),
                    discount_percentage: prodDiscAddedData.discount_percentage,
                    createdby: this.props.session.userid,
                    discountarea_by_iddiscount: [
                      { idproduct: productid, discounareaid: 4 },
                    ],
                    fromproduct: 1,
                    callfrom: "makediscountvendor", // latest code
                  };

                  if (isEdit) {
                    discountObject["id"] = prodDiscAddedData.id;

                    // If Product Discount Is there Add It with Product ID
                    this.props.updateDiscountMaster(
                      this.props.session,
                      discountObject,
                      (callBackInner) => {
                        toast.success(
                          isEdit
                            ? LocaleStrings.product_updated_successfully
                            : LocaleStrings.product_added_successfully
                        );
                        this.props.unSetTobeEditProductStore(); //latest code
                        this.setState({ draftbtnLoader: false });
                        this.setState({ isBtnDisable: false }); // Set Button Disabled false
                      }
                    );
                  } else {
                    // If Product Discount Is there Add It with Product ID
                    this.props.addDiscountMaster(
                      this.props.session,
                      discountObject,
                      (callBackInner) => {
                        toast.success(
                          isEdit
                            ? LocaleStrings.product_updated_successfully
                            : LocaleStrings.product_added_successfully
                        );
                        this.props.reset();
                        this.setState({ draftbtnLoader: false });
                        this.setState({ isBtnDisable: false }); // Set Button Disabled false
                      }
                    );
                  }
                }

                // Clear All Sate and reinitialize redux

                this.setState({ selectedServiceId: 0 });
                this.setState({ productTitle: "" });
                this.setState({ productSubTitle: "" });
                this.setState({ showerror: false });
                this.setState({ vatiantsFeildArrState: [] });
                this.setState({ defaultSaleType: "" });
                this.props.currentShippngType("flat");
                this.setState({ returnType: "" });
                this.setState({
                  incrementalTypeState: "percentage",
                });
                console.log(3);
                this.setState({ variantVariationsState: {} });
                this.setState({ specificationState: [] });
                this.setState({ enar: "en" });
                this.setState({ istaxable: 0 });

                /**** Item / Product specification Object ****/
                product_specifications = [];

                this.props.allSelectedSubcategorisID({});
                this.props.fetchProductFeeDetails({});

                this.props.productAddedDiscount({});
                this.props.clearDiscountList({}); // Clear discount list
                // this.props.parentCallBack();
                if (this.props.fromApp) {
                  window.location.replace(
                    "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                  );
                  // this.props.parentCallBack();
                } else {
                  this.props.parentCallBack();
                }
                let newTabs = _.map(this.props.tabs, (value, index) => {
                  if (value.tabindex === 1) {
                    value.current = true;
                  } else {
                    value.current = false;
                  }

                  return value;
                });

                this.props.tabListWithSelection(newTabs);
              });
            } else {
              toast.error("Something went wrong");
              this.setState({ draftbtnLoader: false });
              this.setState({ isBtnDisable: false }); // Set Button Disabled false
            }
          });
        } else {
          if (isEdit) {
            console.log("values-2-", values);
            // console.log("relist_1", this.props.relist_while_product_upload);
            if (this.props.relist_while_product_upload == true) {
              values.listed = 1;
              // this.props.toggleunlistproduct(session, 1, values.id, (callback) => {
              // });
            }

            /* UPLOAD AT TIME OF EDIT */
            this.props.addproduct(this.props.session, values, (callback) => {
              let productid = callback.productid;
              productidForpersonalvendor = callback.productid;
              this.props.relistwhileUpdatingProduct(false);
              if (_.isEmpty(prodDiscAddedData)) {
                toast.success(
                  isEdit
                    ? LocaleStrings.product_updated_successfully
                    : LocaleStrings.product_added_successfully
                );
                this.props.unSetTobeEditProductStore(); //latest code
                this.setState({ draftbtnLoader: false }); // End The button Loader
                this.setState({ isBtnDisable: false }); // unSet Button Disabled
              } else {
                let discountObject = {
                  discounttype: prodDiscAddedData.discounttype,
                  title: prodDiscAddedData.discount_title,
                  validity_from: moment(prodDiscAddedData.validity_from).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  ),
                  validity_end: moment(prodDiscAddedData.validity_end).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  ),
                  discount_percentage: prodDiscAddedData.discount_percentage,
                  createdby: this.props.session.userid,
                  discountarea_by_iddiscount: [
                    { idproduct: productid, discounareaid: 4 },
                  ],
                  fromproduct: 1,
                  callfrom: "makediscountvendor", // latest code
                };

                if (isEdit && _.has(prodDiscAddedData, "id")) {
                  // Insert Discount ID If its edit & discount is aleady there
                  discountObject["id"] = prodDiscAddedData.id;
                }

                if (_.has(discountObject, "id")) {
                  // Update product -> update discount

                  // If Product Discount Is there Add It with Product ID
                  this.props.updateDiscountMaster(
                    this.props.session,
                    discountObject,
                    (callBackInner) => {
                      toast.success(
                        isEdit
                          ? LocaleStrings.product_updated_successfully
                          : LocaleStrings.product_added_successfully
                      );
                      this.props.unSetTobeEditProductStore(); //latest code
                      this.setState({ draftbtnLoader: false });
                      this.setState({ isBtnDisable: false }); // Set Button Disabled false
                    }
                  );
                } else {
                  this.props.addDiscountMaster(
                    this.props.session,
                    discountObject,
                    (callBackInner) => {
                      toast.success(
                        isEdit
                          ? LocaleStrings.product_updated_successfully
                          : LocaleStrings.product_added_successfully
                      );
                      this.props.unSetTobeEditProductStore(); //latest code
                      this.setState({ draftbtnLoader: false });
                      this.setState({ isBtnDisable: false }); // Set Button Disabled false
                    }
                  );
                }
              }

              // Clear All Sate and reinitialize redux

              this.setState({ selectedServiceId: 0 });
              this.setState({ productTitle: "" });
              this.setState({ productSubTitle: "" });
              this.setState({ showerror: false });
              this.setState({ vatiantsFeildArrState: [] });
              this.setState({ defaultSaleType: "" });
              this.props.currentShippngType("flat");
              this.setState({ returnType: "" });
              this.setState({ incrementalTypeState: "percentage" });
              console.log(4);
              this.setState({ variantVariationsState: {} });
              this.setState({ specificationState: [] });
              this.setState({ enar: "en" });
              this.setState({ istaxable: 0 });

              /**** Item specification Object ****/
              product_specifications = [];

              this.props.allSelectedSubcategorisID({});
              this.props.fetchProductFeeDetails({});
              // this.props.parentCallBack(); // After Add Redirect
              if (this.props.fromApp) {
                window.location.replace(
                  "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                );
                // this.props.parentCallBack();
              } else {
                this.props.parentCallBack();
              }

              this.props.productAddedDiscount({});
              this.props.clearDiscountList({}); // Clear discount list
              let newTabs = _.map(this.props.tabs, (value, index) => {
                if (value.tabindex === 1) {
                  value.current = true;
                } else {
                  value.current = false;
                }

                return value;
              });

              this.props.tabListWithSelection(newTabs);
            });
          } else {
            // saving draft without photo
            this.props.addproduct(this.props.session, values, (callback) => {
              let productid = callback.productid;
              productidForpersonalvendor = callback.productid;
              this.props.relistwhileUpdatingProduct(false);
              if (_.isEmpty(prodDiscAddedData)) {
                toast.success(
                  isEdit
                    ? LocaleStrings.product_updated_successfully
                    : LocaleStrings.product_added_successfully
                );
                this.props.unSetTobeEditProductStore(); //latest code
                this.setState({ draftbtnLoader: false }); // End The button Loader
                this.setState({ isBtnDisable: false }); // unSet Button Disabled
              } else {
                let discountObject = {
                  discounttype: prodDiscAddedData.discounttype,
                  title: prodDiscAddedData.discount_title,
                  validity_from: moment(prodDiscAddedData.validity_from).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  ),
                  validity_end: moment(prodDiscAddedData.validity_end).format(
                    DATE_FORMAT_WITH_TIME_WITH_SECOND
                  ),
                  discount_percentage: prodDiscAddedData.discount_percentage,
                  createdby: this.props.session.userid,
                  discountarea_by_iddiscount: [
                    { idproduct: productid, discounareaid: 4 },
                  ],
                  fromproduct: 1,
                  callfrom: "makediscountvendor", // latest code
                };

                if (isEdit && _.has(prodDiscAddedData, "id")) {
                  // Insert Discount ID If its edit & discount is aleady there
                  discountObject["id"] = prodDiscAddedData.id;
                }

                if (_.has(discountObject, "id")) {
                  // Update product -> update discount

                  // If Product Discount Is there Add It with Product ID
                  this.props.updateDiscountMaster(
                    this.props.session,
                    discountObject,
                    (callBackInner) => {
                      toast.success(
                        isEdit
                          ? LocaleStrings.product_updated_successfully
                          : LocaleStrings.product_added_successfully
                      );
                      this.props.unSetTobeEditProductStore(); //latest code
                      this.setState({ draftbtnLoader: false });
                      this.setState({ isBtnDisable: false }); // Set Button Disabled false
                    }
                  );
                } else {
                  // console.log('discountObject',discountObject);
                  // // need to check
                  this.props.addDiscountMaster(
                    this.props.session,
                    discountObject,
                    (callBackInner) => {
                      toast.success(
                        isEdit
                          ? LocaleStrings.product_updated_successfully
                          : LocaleStrings.product_added_successfully
                      );
                      this.props.unSetTobeEditProductStore(); //latest code
                      this.setState({ draftbtnLoader: false });
                      this.setState({ isBtnDisable: false }); // Set Button Disabled false
                    }
                  );
                }
              }

              // Clear All Sate and reinitialize redux

              this.setState({ selectedServiceId: 0 });
              this.setState({ productTitle: "" });
              this.setState({ productSubTitle: "" });
              this.setState({ showerror: false });
              this.setState({ vatiantsFeildArrState: [] });
              this.setState({ defaultSaleType: "" });
              this.props.currentShippngType("flat");
              this.setState({ returnType: "" });
              this.setState({ incrementalTypeState: "percentage" });
              console.log(4);
              this.setState({ variantVariationsState: {} });
              this.setState({ specificationState: [] });
              this.setState({ enar: "en" });
              this.setState({ istaxable: 0 });

              /**** Item specification Object ****/
              product_specifications = [];

              this.props.allSelectedSubcategorisID({});
              this.props.fetchProductFeeDetails({});
              // this.props.parentCallBack(); // After Add Redirect

              if (this.props.fromApp) {
                window.location.replace(
                  "http://shopezvendor.staging.teks.co.in/shopezuploadsuccess"
                );
                // this.props.parentCallBack();
              } else {
                this.props.parentCallBack();
              }

              this.props.productAddedDiscount({});
              this.props.clearDiscountList({}); // Clear discount list
              let newTabs = _.map(this.props.tabs, (value, index) => {
                if (value.tabindex === 1) {
                  value.current = true;
                } else {
                  value.current = false;
                }

                return value;
              });

              this.props.tabListWithSelection(newTabs);
            });
          }
        }
      };

      addEdit(values); // a block function
    } else {
      console.log("1");
    }
  };

  /**** Translate  ****/
  translate = (event, lang, calledfrom) => {
    let { formValues, globalSpecifications, storedItemSpecifications } =
      this.props;
    this.setState({ tanslateBtnLoader: true });

    let { session } = this.props;

    let qstringArr;
    let source = lang === "en" ? "ar" : "en";
    let target = lang;

    console.log("formValues", formValues);

    // If ar then create data for en and vicevarsa
    if (lang === "ar") {
      // Create object from english to arabic
      qstringArr = [
        { productname_en: "", value: "", key: "productname_en" },
        { product_desc_en: "", value: "", key: "product_desc_en" },
        { location_state_en: "", value: "", key: "location_state_en" },
        { location_city_en: "", value: "", key: "location_city_en" },
        { specification_desc_en: "", value: "", key: "specification_desc_en" },
      ];

      // Creating object for global specification Arabic
      // _.forEach(globalSpecifications, (item, index) => {
      //   let globalSpecFieldNameEn = "globalspec_name_en_" + item.id;
      //   // Pushing value into initial value for both language
      //   qstringArr.push({
      //     [globalSpecFieldNameEn]: "",
      //     value: "",
      //     key: globalSpecFieldNameEn,
      //   });
      // });
      if (storedItemSpecifications && storedItemSpecifications.required) {
        _.forEach(storedItemSpecifications.required, (item, index) => {
          let itemSpecFieldNameEn = "itemspec_name_en_" + item.id;
          qstringArr.push({
            [itemSpecFieldNameEn]: "",
            value: "",
            key: itemSpecFieldNameEn,
          });
        });
      }
      // Creating object for item specification Arabic
      if (storedItemSpecifications && storedItemSpecifications.additional) {
        _.forEach(storedItemSpecifications.additional, (item, index) => {
          let itemSpecFieldNameEn = "itemspec_name_en_" + item.id;
          qstringArr.push({
            [itemSpecFieldNameEn]: "",
            value: "",
            key: itemSpecFieldNameEn,
          });
        });
      }
      // Creating object for item specification Arabic
      // Added for custom specifcations
      if (
        formValues &&
        formValues.product_specification_custom &&
        formValues.product_specification_custom.length > 0
      ) {
        _.forEach(formValues.product_specification_custom, (val, i) => {
          let keyNameEn = "cusspecs-key_en_" + i;
          qstringArr.push({
            [keyNameEn]: "",
            value: "",
            key: keyNameEn,
          });
          let valNameEn = "cusspecs-value_en_" + i;
          qstringArr.push({
            [valNameEn]: "",
            value: "",
            key: valNameEn,
          });
        });
      }
      // Create object for variation and variation details
      if (_.has(formValues, "variants")) {
        _.forEach(formValues.variants, (val, i) => {
          let variantsNameAr = "variants#name_en_" + i;
          qstringArr.push({
            [variantsNameAr]: "",
            value: "",
            key: variantsNameAr,
          });

          // Variantion deatils name
          _.forEach(val.variantnames, (innnerVal, innerIndex) => {
            let variantnamesNameAr = "variantnames|name_en_" + innerIndex;
            qstringArr.push({
              [variantnamesNameAr]: "",
              value: "",
              key: variantnamesNameAr,
            });
          });
        });
      }
    } else {
      // Create object from arabic to english
      qstringArr = [
        { productname_ar: "", value: "", key: "productname_ar" },
        { product_desc_ar: "", value: "", key: "product_desc_ar" },
        { location_state_ar: "", value: "", key: "location_state_ar" },
        { location_city_ar: "", value: "", key: "location_city_ar" },
        { specification_desc_ar: "", value: "", key: "specification_desc_ar" },
      ];

      // Creating object for global specification English
      _.forEach(globalSpecifications, (item, index) => {
        let globalSpecFieldNameAr = "globalspec_name_ar_" + item.id;
        // Pushing value into initial value for both language
        qstringArr.push({
          [globalSpecFieldNameAr]: "",
          value: "",
          key: globalSpecFieldNameAr,
        });
      });

      // Creating object for item specification English
      if (storedItemSpecifications && storedItemSpecifications.required) {
        _.forEach(storedItemSpecifications.required, (item, index) => {
          let itemSpecFieldNameAr = "itemspec_name_ar_" + item.id;
          qstringArr.push({
            [itemSpecFieldNameAr]: "",
            value: "",
            key: itemSpecFieldNameAr,
          });
        });
      }
      if (storedItemSpecifications && storedItemSpecifications.additional) {
        _.forEach(storedItemSpecifications.additional, (item, index) => {
          let itemSpecFieldNameAr = "itemspec_name_ar_" + item.id;
          qstringArr.push({
            [itemSpecFieldNameAr]: "",
            value: "",
            key: itemSpecFieldNameAr,
          });
        });
      }

      // Create object for variation and variation details
      if (_.has(formValues, "variants")) {
        _.forEach(formValues.variants, (val, i) => {
          let variantsNameAr = "variants#name_ar_" + i;
          qstringArr.push({
            [variantsNameAr]: "",
            value: "",
            key: variantsNameAr,
          });

          // Variantion deatils name
          _.forEach(val.variantnames, (innnerVal, innerIndex) => {
            let variantnamesNameAr = "variantnames|name_ar_" + innerIndex;
            qstringArr.push({
              [variantnamesNameAr]: "",
              value: "",
              key: variantnamesNameAr,
            });
          });
        });
      }
      // Added for custom specifcations
      if (
        formValues &&
        formValues.product_specification_custom &&
        formValues.product_specification_custom.length > 0
      ) {
        _.forEach(formValues.product_specification_custom, (val, i) => {
          let keyNameAr = "cusspecs-key_ar_" + i;
          qstringArr.push({
            [keyNameAr]: "",
            value: "",
            key: keyNameAr,
          });
          let valNameAr = "cusspecs-value_ar_" + i;
          qstringArr.push({
            [valNameAr]: "",
            value: "",
            key: valNameAr,
          });
        });
      }
    }

    // Push data into qstring array
    _.forEach(qstringArr, (value, index) => {
      if (_.has(formValues, value.key)) {
        // If required key is exist under form value
        if (formValues[value.key]) {
          // if value available to translate
          value[value.key] = formValues[value.key];
        }
        // For variants and variations
      }
    });

    // Push data into query string for variants and variation
    // Create object for variation and variation details

    let newVariants = { variants: { ...formValues["variants"] } };
    let product_specification_custom_new = {
      product_specification_custom: {
        ...formValues["product_specification_custom"],
      },
    };

    if (_.has(formValues, "variants")) {
      _.forEach(formValues.variants, (val, i) => {
        let variantsNameAr = "variants#name_ar_" + i;
        let variantsNameEn = "variants#name_en_" + i;

        if (lang === "ar") {
          _.forEach(qstringArr, (value, index) => {
            // If required key is exist under form value
            if (value.key === variantsNameEn) {
              // if value available to translate
              value[value.key] = val.name_en;

              /* Adding data in our iscustom variable */

              newVariants.variants[i]["key"] = variantsNameEn;
            }
          });
        } else {
          _.forEach(qstringArr, (value, index) => {
            // If required key is exist under form value
            if (value.key === variantsNameAr) {
              // if value available to translate
              value[value.key] = val.name_ar;
              /* Adding data in our iscustom variable */

              newVariants.variants[i]["key"] = variantsNameAr;
            }
          });
        }

        // Variantion deatils name
        _.forEach(val.variantnames, (innnerVal, innerIndex) => {
          let variantnamesNameAr = "variantnames|name_ar_" + innerIndex;
          let variantnamesNameEn = "variantnames|name_en_" + innerIndex;

          if (lang === "ar") {
            _.forEach(qstringArr, (vdvalue, vdindex) => {
              // If required key is exist under form value

              if (vdvalue.key === variantnamesNameEn) {
                // if value available to translate
                vdvalue[vdvalue.key] = innnerVal.name_en;

                /* Adding data in our iscustom variable */

                newVariants.variants[i].variantnames[innerIndex]["key"] =
                  variantnamesNameEn;
              }
              // For variants and variations
            });
          } else {
            _.forEach(qstringArr, (vdvalue, vdindex) => {
              // If required key is exist under form value
              if (vdvalue.key === variantnamesNameAr) {
                // if value available to translate
                vdvalue[vdvalue.key] = innnerVal.name_ar;
                /* Adding data in our iscustom variable */
                newVariants.variants[i].variantnames[innerIndex]["key"] =
                  variantnamesNameAr;
              }
              // For variants and variations
            });
          }
        });
      });
    }
    // New Added for custom specifcations

    if (
      _.has(formValues, "product_specification_custom") &&
      formValues.product_specification_custom.length > 0
    ) {
      _.forEach(formValues.product_specification_custom, (val, i) => {
        // console.log("Object.keys(val).length > 0", Object.keys(val).length > 0);

        if (Object.keys(val).length > 0) {
          let keyNameAr = "cusspecs-key_ar_" + i;
          let keyNameEn = "cusspecs-key_en_" + i;
          let valueNameAr = "cusspecs-value_ar_" + i;
          let valueNameEn = "cusspecs-value_en_" + i;
          if (lang === "ar") {
            _.forEach(qstringArr, (value, index) => {
              // If required key is exist under form value
              if (value.key === keyNameEn) {
                // if value available to translate
                value[value.key] = val.key_en;
                /* Adding data in our iscustom variable */
                product_specification_custom_new.product_specification_custom[
                  i
                ]["key"] = keyNameEn;
              }
              if (value.key === valueNameEn) {
                // if value available to translate
                value[value.key] = val.value_en;
                /* Adding data in our iscustom variable */
                product_specification_custom_new.product_specification_custom[
                  i
                ]["key"] = valueNameEn;
              }
            });
          } else {
            _.forEach(qstringArr, (value, index) => {
              // If required key is exist under form value
              if (value.key === keyNameAr) {
                // if value available to translate
                value[value.key] = val.key_ar;
                /* Adding data in our iscustom variable */

                product_specification_custom_new.variants[i]["key"] = keyNameAr;
              }
              if (value.key === valueNameAr) {
                // if value available to translate
                value[value.key] = val.value_ar;
                /* Adding data in our iscustom variable */

                product_specification_custom_new.variants[i]["key"] =
                  valueNameAr;
              }
            });
          }
        }
      });
    }

    let object = {
      source: source,
      target: target,
      qstring: qstringArr,
      type: "translate",
    };
    this.props.getTranslatedResults(session, object, (callback) => {
      if (callback.success) {
        // Translation success with response

        if (calledfrom != "insidesubmit") {
          toast.success(
            target === "ar"
              ? LocaleStrings.successfully_translated_to_arabic
              : LocaleStrings.successfully_translated_to_english
          );
        }

        let transaltedResponse = callback.data.qstring;
        // Put all data to its respected input boxes
        _.forEach(transaltedResponse, (val, i) => {
          let fieldNameAfterReplace =
            source === "en"
              ? _.replace(val.key, "_en", "_ar")
              : _.replace(val.key, "_ar", "_en");

          if (_.indexOf(val.key, "#") > -1) {
            // For Variants and variation details

            _.forEach(newVariants.variants, (valM, iM) => {
              if (valM.key === val.key) {
                valM[val.key] = val.value;
                if (source === "en") {
                  valM["name_ar"] = val.value;
                } else {
                  valM["name_en"] = val.value;
                }
              }
            });
          } else if (_.indexOf(val.key, "|") > -1) {
            _.forEach(newVariants.variants, (valM, iM) => {
              _.forEach(valM.variantnames, (valY, iY) => {
                if (valY.key === val.key) {
                  valY[val.key] = val.value;

                  if (source === "en") {
                    valY["name_ar"] = val.value;
                  } else {
                    valY["name_en"] = val.value;
                  }
                }
              });
            });
          } else if (_.indexOf(val.key, "-") > -1) {
            // Added for custom specifcations
            _.forEach(
              product_specification_custom_new.product_specification_custom,
              (valY, iY) => {
                if (valY.key === val.key) {
                  valY[val.key] = val.value;

                  if (source === "en") {
                    valY["key_ar"] = val.value;
                    valY["value_ar"] = val.value;
                    // valY["value_ar"] = val.value;
                  } else {
                    valY["key_en"] = val.value;
                    valY["value_en"] = val.value;
                  }
                }
              }
            );
          } else {
            // For normal variables
            this.props.dispatch(
              change("UploadProductForm", fieldNameAfterReplace, val.value)
            );
          }
        });

        if (_.size(product_specification_custom_new)) {
          this.props.dispatch(
            change("UploadProductForm", product_specification_custom_new)
          );
        }
        if (_.size(newVariants)) {
          this.props.dispatch(change("UploadProductForm", newVariants));
        }
      } else {
        if (calledfrom != "insidesubmit") {
          toast.error(LocaleStrings.something_went_wrong);
        }
      }
      this.setState({ tanslateBtnLoader: false });
    });
  };

  /**** Event Charity change ****/
  _handleCharityEventChange = (e) => {
    let { defaultSettings } = this.props;
    let selectedCharity = [];
    let donation_percentage = 0;
    if (
      defaultSettings.data &&
      defaultSettings.data.charity_events &&
      defaultSettings.data.charity_events.length > 0 &&
      e.target.value != ""
    ) {
      selectedCharity = _.filter(
        defaultSettings.data.charity_events,
        function (o) {
          return o.id == e.target.value;
        }
      );
    } else {
      this.setState({
        selected_charity_image: null,
        selected_charity_description_en: null,
        selected_charity_description_ar: null,
        selected_charity_name_en: null,
        selected_charity_name_ar: null,
        charity_donation_percentage: null,
        charity_iscustom: null,
      });
    }
    if (selectedCharity.length > 0) {
      donation_percentage = selectedCharity[0].percentage;
      this.setState({
        selected_charity_image: selectedCharity[0].image,
        selected_charity_description_en: selectedCharity[0].description_en,
        selected_charity_description_ar: selectedCharity[0].description_ar,
        selected_charity_name_en: selectedCharity[0].name_en,
        selected_charity_name_ar: selectedCharity[0].name_ar,
        charity_donation_percentage: donation_percentage,
        charity_iscustom: selectedCharity[0].iscustom,
      });
      this.props.dispatch(
        change("UploadProductForm", "donation_percentage", donation_percentage)
      );
      this.props.dispatch(
        change(
          "UploadProductForm",
          "charitydescription",
          selectedCharity[0].description_en
        )
      );
    }
  };

  /***** [EDIT] TIME FUNCTION *****/

  _handelUplaodProductClose = (e) => {
    let { tabs } = this.props;

    confirmAlert({
      title: LocaleStrings.leave_the_page_confirmation_header_text,
      message: `${LocaleStrings.leave_the_page_confirmation_suheader_text}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            /* Do the job */
            this.props.unSetTobeEditProductStore(); // Close Uplaod Product [IF EDIT SET tobeEDit to Balnk]
            this.props.allSelectedSubcategorisID({}); // SELECTED CATE & SUB_CATE -> {}
            this.props.showUploadSection(e, "VL");
            this.props.relistwhileUpdatingProduct(false);
            this.props.productExculdelocation({});
            this.props.continentLength({});

            this.setState({ vatiantsFeildArrState: [] });
            this.setState({ vatiantsFeildArrState: [] });

            // Reset tabs selection
            let newTabs = _.map(tabs, (value, index) => {
              if (value.tabindex === 1) {
                value.current = true;
              } else {
                value.current = false;
              }

              return value;
            });
            this.props.tabListWithSelection(newTabs); // Update tabs data
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {
            // Do nothing
          },
        },
      ],
    });
  };

  order_processedbyChange = (e) => {
    this.setState({ order_processed_status: e.target.value });
  };

  openCharityview = (e) => {
    this.props.charityviewModalopen(true);
  };

  auctiontypeChange = (e) => {
    this.setState({ auctiontypestate: e.target.checked });

    let currentDatetime = new Date(
      moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
    );
    this.props.dispatch(
      change("UploadProductForm", "auction_start_date", currentDatetime)
    );
    this.props.dispatch(change("UploadProductForm", "auctiondays", ""));
  };

  // auctionDatechange = (e, callfrom) => {
  //   let { formValues, isEdit } = this.props;

  //   if (isEdit) {
  //     let validitytill = "";
  //     if (callfrom == "days") {
  //       if (
  //         (formValues && formValues.auction_start_date != null) ||
  //         formValues.auction_start_date != ""
  //       ) {
  //         if (e.target.value != "") {
  //           let noofdays = parseInt(e.target.value);
  //           // let fomdate = moment(formValues.auction_start_date);
  //           let fomdate = new Date(
  //             moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
  //           );
  //           let validity = addDays(fomdate, noofdays);
  //           validitytill = moment(validity).format(
  //             DATE_FORMAT_WITH_TIME_WITH_SECOND
  //           );
  //           if (formValues.auctiontype == false) {
  //             this.props.dispatch(
  //               change("UploadProductForm", "auction_start_date", fomdate)
  //             );
  //           }

  //           this.props.dispatch(
  //             change("UploadProductForm", "auction_end_date", validitytill)
  //           );
  //         }
  //       }
  //     } else {
  //       // console.log("formValues",formValues)
  //       if (
  //         formValues.auctiondays != null &&
  //         formValues.auctiondays != "" &&
  //         formValues.auction_start_date &&
  //         formValues.auction_start_date != null &&
  //         formValues.auction_start_date != ""
  //       ) {
  //         let noofdays = parseInt(formValues.auctiondays);
  //         let fomdate = moment(formValues?.auction_start_date);
  //         let validity = addDays(fomdate._d, noofdays);
  //         // let  validity = addDays(formValues.validityfrom, noofdays);
  //         validitytill = moment(validity).format(
  //           DATE_FORMAT_WITH_TIME_WITH_SECOND
  //         );
  //         this.props.dispatch(
  //           change("UploadProductForm", "auction_end_date", validitytill)
  //         );
  //       }
  //     }
  //   } else {
  //     if (callfrom == "days") {
  //       if (
  //         formValues &&
  //         formValues.auction_start_date != "" &&
  //         formValues.auction_start_date != null
  //       ) {
  //         console.log("formValues", formValues);
  //         if (e.target.value != "") {
  //           let days = parseInt(e.target.value);
  //           let fomdate = new Date(
  //             moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
  //           );
  //           let validity = addDays(fomdate, days);
  //           let validitytill = moment(validity).format(
  //             DATE_FORMAT_WITH_TIME_WITH_SECOND
  //           );
  //           console.log("auction1", formValues.auctiontype);
  //           if (formValues.auctiontype && formValues.auctiontype == false) {
  //             console.log("auction2", formValues.auctiontype);
  //             this.props.dispatch(
  //               change("UploadProductForm", "auction_start_date", fomdate)
  //             );
  //           }
  //           this.props.dispatch(
  //             change("UploadProductForm", "auction_end_date", validitytill)
  //           );
  //         }
  //       }
  //     } else {
  //       // console.log("formValues", formValues);
  //       if (
  //         formValues.auctiondays &&
  //         formValues.auctiondays != "" &&
  //         formValues.auction_start_date &&
  //         formValues.auction_start_date != ""
  //       ) {
  //         // console.log("typeoff", typeof formValues.auction_start_date);
  //         if (typeof formValues.auction_start_date == "string") {
  //           let noofdays = parseInt(formValues.auctiondays);
  //           let fomdate = moment(formValues?.auction_start_date);
  //           let validity = addDays(fomdate._d, noofdays);
  //           // let  validity = addDays(formValues.validityfrom, noofdays);
  //           let validitytill = moment(validity).format(
  //             DATE_FORMAT_WITH_TIME_WITH_SECOND
  //           );
  //           this.props.dispatch(
  //             change("UploadProductForm", "auction_end_date", validitytill)
  //           );
  //         } else {
  //           let validity = addDays(
  //             formValues.auction_start_date,
  //             parseInt(formValues.auctiondays)
  //           );

  //           console.log("validity", validity);
  //           if (validity != "Invalid Date") {
  //             let validitytill = moment(validity).format(
  //               DATE_FORMAT_WITH_TIME_WITH_SECOND
  //             );
  //             this.props.dispatch(
  //               change("UploadProductForm", "auction_end_date", validitytill)
  //             );
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  auctionDatechange = (e, callfrom) => {
    let { formValues, isEdit } = this.props;

    if (isEdit) {
      let validitytill = "";
      if (callfrom == "days") {
        if (
          formValues &&
          formValues.auction_start_date != null &&
          formValues.auction_start_date != ""
        ) {
          if (e.target.value != "") {
            let noofdays = parseInt(e.target.value);
            let fomdate = "";
            console.log("auctiontype", typeof formValues.auctiontype);
            if (formValues.auctiontype == false) {
              fomdate = new Date(
                moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
              );
              this.props.dispatch(
                change("UploadProductForm", "auction_start_date", fomdate)
              );
            } else {
              if (typeof formValues.auction_start_date != "string") {
                fomdate = formValues?.auction_start_date;
              } else {
                let converStartdate = moment(formValues?.auction_start_date);
                fomdate = converStartdate._d;
              }
            }
            if (fomdate != "") {
              let validity = addDays(fomdate, noofdays);
              let validitytill = moment(validity).format(
                DATE_FORMAT_WITH_TIME_WITH_SECOND
              );

              this.props.dispatch(
                change("UploadProductForm", "auction_end_date", validitytill)
              );
            }
          }
        }
      } else {
        if (
          formValues.auctiondays != null &&
          formValues.auctiondays != "" &&
          formValues.auction_start_date &&
          formValues.auction_start_date != null &&
          formValues.auction_start_date != ""
        ) {
          let noofdays = parseInt(formValues.auctiondays);
          let fomdate = moment(formValues?.auction_start_date);
          let validity = addDays(fomdate._d, noofdays);
          // let  validity = addDays(formValues.validityfrom, noofdays);
          validitytill = moment(validity).format(
            DATE_FORMAT_WITH_TIME_WITH_SECOND
          );
          this.props.dispatch(
            change("UploadProductForm", "auction_end_date", validitytill)
          );
        }
      }
    } else {
      if (callfrom == "days") {
        if (
          formValues &&
          formValues.auction_start_date != "" &&
          formValues.auction_start_date != null
        ) {
          if (e.target.value != "") {
            let days = parseInt(e.target.value);
            let fomdate = "";
            if (formValues.auctiontype == false) {
              fomdate = new Date(
                moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
              );
              this.props.dispatch(
                change("UploadProductForm", "auction_start_date", fomdate)
              );
            } else {
              if (typeof formValues.auction_start_date != "string") {
                fomdate = formValues?.auction_start_date;
              } else {
                let converStartdate = moment(formValues?.auction_start_date);
                fomdate = converStartdate._d;
              }
            }
            if (fomdate != "") {
              let validity = addDays(fomdate, days);
              let validitytill = moment(validity).format(
                DATE_FORMAT_WITH_TIME_WITH_SECOND
              );

              this.props.dispatch(
                change("UploadProductForm", "auction_end_date", validitytill)
              );
            }
          }
        }
      } else {
        // console.log("formValues", formValues);
        if (
          formValues.auctiondays &&
          formValues.auctiondays != "" &&
          formValues.auction_start_date &&
          formValues.auction_start_date != ""
        ) {
          // console.log("typeoff", typeof formValues.auction_start_date);
          if (typeof formValues.auction_start_date == "string") {
            let noofdays = parseInt(formValues.auctiondays);
            let fomdate = moment(formValues?.auction_start_date);
            let validity = addDays(fomdate._d, noofdays);
            // let  validity = addDays(formValues.validityfrom, noofdays);
            let validitytill = moment(validity).format(
              DATE_FORMAT_WITH_TIME_WITH_SECOND
            );
            this.props.dispatch(
              change("UploadProductForm", "auction_end_date", validitytill)
            );
          } else {
            let validity = addDays(
              formValues.auction_start_date,
              parseInt(formValues.auctiondays)
            );

            // console.log("validity", validity);
            if (validity != "Invalid Date") {
              let validitytill = moment(validity).format(
                DATE_FORMAT_WITH_TIME_WITH_SECOND
              );
              this.props.dispatch(
                change("UploadProductForm", "auction_end_date", validitytill)
              );
            }
          }
        }
      }
    }
  };

  render() {
    var defaultSaletype = this.state.defaultSaleType;
    // console.log("newVariantimageRemove", this.state.newVariantimageRemove);
    // console.log("vatiantsFeildArrState", this.state.vatiantsFeildArrState);
    // console.log("variantVariationsState", this.state.variantVariationsState);
    // State
    var {
      selectedServiceId,
      makeDonationState,
      returnType,
      enar,
      istaxable,
      defaultHasvariant,
      variantVariationsState,
      vatiantsFeildArrState,
      tanslateBtnLoader,
      variationValue,
      selected_charity_description_en,
      selected_charity_description_ar,
      selected_charity_image,
      selected_charity_name_en,
      selected_charity_name_ar,
      charity_donation_percentage,
      charity_iscustom,
      auctiontypestate,
    } = this.state;

    // variantVariationsState = JSON.parse(JSON.stringify(variantVariationsState));
    // console.log("variantVariationsState", variantVariationsState);
    // Props
    var {
      session,
      initialValues,
      handleSubmit,
      prodDiscAddedData,
      productList,
      isEdit,
      tabs,
      shippingType,
      isCheckShippingBearerAvailable,
      variationMasterList,
      formValues, // Gets the form values. Shocking, right?
      language,
      zoneAndpincodes,
      defaultShippingCharges,
      countryList,
      continentList,
      continentLengthdata,
      ischarityviewModalopen,
      productData,
      auction_daysList,
    } = this.props;

    let charity_image = null;
    if (selected_charity_image != null && selected_charity_image != "") {
      charity_image = `${BASE_IMAGES_URL}/${selected_charity_image}?api_key=${APP_API_KEY}`;
    }

    let excludeplaceholder = "Select Country";
    let countryname = "";
    let continentname = "";
    if (
      continentLengthdata &&
      continentLengthdata.continentLength &&
      continentLengthdata.countrylength &&
      continentLengthdata.continentLength != 0 &&
      continentLengthdata.countrylength != 0 &&
      continentLengthdata.countryid &&
      continentLengthdata.countryid.length > 0 &&
      continentLengthdata.countinentid &&
      continentLengthdata.countinentid.length > 0
    ) {
      let countrynameArr = _.filter(countryList, function (o) {
        return o.id == continentLengthdata.countryid[0]?.value[0];
      });
      let continetnameArr = _.filter(continentList, function (o) {
        return o.id == continentLengthdata.countinentid[0].index;
      });

      if (countrynameArr.length > 0) {
        countryname = countrynameArr[0].name;
      }
      if (continetnameArr.length > 0) {
        continentname = continetnameArr[0].continent;
      }
      let showingcountryLength =
        parseInt(continentLengthdata.countrylength) - 1;
      let showingcontinetLength =
        parseInt(continentLengthdata.continentLength) - 1;

      if (
        continentLengthdata.continentLength == 1 &&
        continentLengthdata.countrylength > 1
      ) {
        excludeplaceholder = `${continentname} , ${countryname} + ${showingcountryLength} Country`;
      } else if (
        continentLengthdata.countrylength == 1 &&
        continentLengthdata.continentLength > 1
      ) {
        excludeplaceholder = `${continentname} + ${showingcontinetLength} Continent(s), ${countryname}`;
      } else if (
        continentLengthdata.countrylength == 1 &&
        continentLengthdata.continentLength == 1
      ) {
        excludeplaceholder = `${continentname} , ${countryname} `;
      } else if (
        continentLengthdata.countrylength > 1 &&
        continentLengthdata.continentLength > 1
      ) {
        excludeplaceholder = `${continentname} + ${showingcontinetLength} Continent(s), ${countryname} + ${showingcountryLength} Country`;
      }
    }

    let tabIndextoShow = _.findIndex(tabs, { current: true });
    let defaultShippingchargesid = 0;
    if (defaultShippingCharges && defaultShippingCharges.length > 0) {
      defaultShippingchargesid = defaultShippingCharges[0].id;
    }

    var productConditionOptions = [];
    var returnTypeOptions = [];
    var handlingTimeOptions = [];
    var refundTypeOptions = [];
    var shippingCostBearerOptions = [];
    var charityEventsOptions = [];
    var charityPercentOptions = [];
    var shippingServiceOptions = [];
    var servicePackagesOptions = [];
    var countryListOptions = [];

    var productTitleOption = []; // For Datalist
    var productDescOption = []; // For Custom Textarea datalist
    var variationMasterOption = []; // Product variation -> Enter the variant details -> Variation name
    var zonePincodeOptions = []; // Zone and pin code list
    var auctiondaysOpts = []; // Zone and pin code list

    // Zone and pin code list objects creation
    _.forEach(zoneAndpincodes, (val, index) => {
      var obj = {};
      // latest code
      obj = {
        label: val.zone,
        value: val.id,
      };
      obj.shippingid =
        val.shipping_charges_by_zone.length > 0
          ? val.shipping_charges_by_zone[0].id
          : defaultShippingchargesid;
      // old code
      // if (val.pin_code) {
      //   // Pincode
      //   obj["label"] = "Pin-" + val.pin_code;
      // } else {
      //   // zone
      //   obj["label"] = "Zone-" + val.zone_master_by_zone.zone;
      // }
      // obj["value"] = val.id;
      // obj["shippingid"] = val.id;
      zonePincodeOptions.push(obj);
    });

    if (productList) {
      _.uniqBy(productList, "id").map((value, index) => {
        var obj = {};
        var obj1 = {};

        obj["name"] = ProductTextLocalized(
          value.productname_en,
          value.productname_ar,
          enar
        );
        obj["value"] = ProductTextLocalized(
          value.productname_en,
          value.productname_ar,
          enar
        );

        // Description For Cust datalist
        obj1["name"] = ProductTextLocalized(
          value.product_desc_en,
          value.product_desc_ar,
          enar
        );
        obj1["value"] = ProductTextLocalized(
          value.product_desc_en,
          value.product_desc_ar,
          enar
        );

        productTitleOption.push(obj);
        productDescOption.push(obj1);
      });
    }
    /* Create variation master object list */
    if (variationMasterList) {
      _.forEach(this.props.variationMasterList, function (value) {
        var obj = {};
        obj["name"] = ProductTextLocalized(value.name_en, value.name_ar, enar);

        obj["value"] = ProductTextLocalized(value.name_en, value.name_ar, enar);

        variationMasterOption.push(obj);
      });
    }

    // Product Condition Object Creation
    _.forEach(
      this.props.defaultSettings.data.product_condition,
      function (value) {
        var obj = {
          name: ProductTextLocalized(
            value.condition_en,
            value.condition_ar,
            enar
          ),
          value: value.id,
        };
        productConditionOptions.push(obj);
      }
    );

    // Return Type Object Creation
    _.forEach(this.props.defaultSettings.data.return_time, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name_en, value.name_ar, enar),
        value: value.idc,
      };
      returnTypeOptions.push(obj);
    });

    // handling time Object creation
    _.forEach(this.props.defaultSettings.data.handling_time, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name_en, value.name_ar, enar),
        value: value.idc,
      };
      handlingTimeOptions.push(obj);
    });

    // Refund Type Object creation
    _.forEach(this.props.defaultSettings.data.refund_type, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name_en, value.name_ar, enar),
        value: value.id,
      };
      refundTypeOptions.push(obj);
    });

    /* Shipping cost bearer Object creation 
      If shippingCostBearerOptions -> true it will 
      show 
      all 3 option (consumer, vendor,shopez)
      if not then it will show 2 option (consumer, vendor)
    */
    _.forEach(
      this.props.defaultSettings.data.shipping_bearer_type,
      function (value) {
        let shipping_bearer_type_cust_name = ProductTextLocalized(
          value.name_en,
          value.name_ar,
          enar
        );
        var obj = {
          name: shipping_bearer_type_cust_name,
          value: value.id,
        };

        if (value.type === "shopez" && !isCheckShippingBearerAvailable) {
        } else {
          shippingCostBearerOptions.push(obj);
        }
      }
    );

    // charity Events Object creation
    _.forEach(this.props.defaultSettings.data.charity_events, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name_en, value.name_ar, enar),
        // value: value.id, old code
        value: value.id, // latest code
        percentage: value.percentage,
      };
      charityEventsOptions.push(obj);
    });

    // Charity Percentages Object creation
    _.forEach(
      this.props.defaultSettings.data.charity_percent,
      function (value) {
        var obj = {
          name: value.name,
          value: value.value,
        };
        charityPercentOptions.push(obj);
      }
    );

    // Shiping service Object Creation
    _.forEach(
      this.props.defaultSettings.data.delivery_company,
      function (value) {
        var obj = {
          name: value.companyname,
          value: value.id,
        };
        shippingServiceOptions.push(obj);
      }
    );

    // Using the _.filter() method get Package array
    let filtered_package_array = _.filter(
      this.props.defaultSettings.data.delivery_company,
      function (o) {
        return o.id == selectedServiceId;
      }
    );

    // Select Package Object Creation from service select
    if (filtered_package_array.length > 0) {
      _.forEach(filtered_package_array[0].packages, function (value) {
        var obj = {
          name: ProductTextLocalized(
            value.packagename_en,
            value.packagename_ar,
            enar
          ),
          value: value.id,
        };
        servicePackagesOptions.push(obj);
      });
    }

    // Category Subcategory
    var cateSubcateOptions = [
      {
        name: "test data",
        value: 1,
      },
    ];

    let cateSubCatePlaceHolder = "";

    if (language === "ar") {
      // Arabic
      cateSubCatePlaceHolder =
        this.props.selectedSubcategorids.length > 0
          ? LocaleStrings.categories +
            this.props.selectedSubcategorids[1].length +
            " , " +
            LocaleStrings.subcategories +
            this.props.selectedSubcategorids[0].length
          : LocaleStrings.select_product_category_subcategory;
    } else {
      // English
      cateSubCatePlaceHolder =
        this.props.selectedSubcategorids.length > 0
          ? this.props.selectedSubcategorids[1].length +
            LocaleStrings.categories +
            " , " +
            this.props.selectedSubcategorids[0].length +
            LocaleStrings.subcategories
          : LocaleStrings.select_product_category_subcategory;
    }

    // Has no variant section
    var refundUnitOptions = [];
    // Product Unit Type Object creation
    _.forEach(this.props.defaultSettings.data.product_units, function (value) {
      var obj = {
        name: value.name_en,
        value: value.idc,
      };

      refundUnitOptions.push(obj);
    });

    if (countryList) {
      _.forEach(countryList, function (value) {
        var obj = {
          name: value.name,
          value: value.id,
          label: value.name,
        };

        countryListOptions.push(obj);
      });
    }
    _.forEach(auction_daysList, function (value) {
      var obj = {
        name: value.name,
        value: value.value,
      };
      auctiondaysOpts.push(obj);
    });
    // console.log("initialValues", this.props.initialValues);
    return (
      <>
        <Toaster />
        {this.state.showerror ? (
          <TailwindAlert
            alertType="error"
            alertMessage={this.state.alertMessage}
          />
        ) : (
          ""
        )}

        {/* Category & Sub-category Modal */}
        <CategorySubcategory
          isEdit={this.props.isEdit}
          data={this.props.prodCateSubCateModal}
          hedaer={LocaleStrings.category_subcategory}
          selectedcatnsub={this.props.selectedSubcategorids}
          enar={this.state.enar}
        />

        {/* Update variations deatils Modal */}

        {/* {this.props.isvariationdeatilsopen ? ( */}
        <UpdateVariationDetails
          isEdit={isEdit}
          data={this.props.isvariationdeatilsopen}
          header={LocaleStrings.variation_details}
          actualarr={this.state.vatiantsFeildArrState}
          detailsArray={this.state.variantVariationsState}
          updateVariatVariationFc={this.variantVariationsStateUpdate}
          updateMainvariantState={this.vatiantsFeildArrupdateState}
          newVariantAdded={this.state.newVariantAdded} // state flag when new variant add / remopve
          //updateNewVariantAddedFlag={this.updateNewVariantAddedFlag}
          defaultHasvariant={this.state.defaultHasvariant}
          enar={this.state.enar}
          newVariantimageRemovefunc={this.newVariantimageRemove}
          newVariantimageRemovestate={this.state.newVariantimageRemove}
        />
        {/* ) : (
          ""
        )} */}
        {ischarityviewModalopen ? (
          <CharityViewModal
            image={charity_image}
            description={
              this.state.enar == "en"
                ? selected_charity_description_en
                : selected_charity_description_ar
            }
            name={
              this.state.enar == "en"
                ? selected_charity_name_en
                : selected_charity_name_ar
            }
            percentage={charity_donation_percentage}
          />
        ) : (
          ""
        )}
        <div className="overflow-x:hidden">
          <div className="xs:p-2 sm:p-6 flex">
            {/* Header Part */}
            {this.props.fromApp ? (
              ""
            ) : (
              <button
                type="button"
                className="btn-primary-circle"
                onClick={(e) => this._handelUplaodProductClose(e)}
                style={{ width: "28px", height: "27px" }}>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}

            {/* edit_product */}

            <span className="px-2  items-center">
              {isEdit ? LocaleStrings.edit_product : LocaleStrings.upload_new}
            </span>
            {isEdit && productData && productData.isdraft == 1 ? (
              <div className="ml-auto">
                <button
                  className="btn-primary"
                  type="button"
                  onClick={(e) => this.onSaveasDraft(e)}>
                  <svg
                    className={classNames(
                      this.state.draftbtnLoader ? "" : "hidden",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  Save as Draft
                </button>
              </div>
            ) : !isEdit ? (
              <div className="ml-auto">
                <button
                  className="btn-primary"
                  type="button"
                  onClick={(e) => this.onSaveasDraft(e)}>
                  <svg
                    className={classNames(
                      this.state.draftbtnLoader ? "" : "hidden",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  Save as Draft
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="xs:p-2 sm:p-6">
            {/* Form Tabs */}
            <div>
              <form
                className="space-y-8 border-solid border-2 border-black-900 "
                onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                {/* OUTSIDE STARTS */}
                <fieldset className="space-y-5 xs:mx-2 md:mx-10 mx-10 mt-6">
                  {/* About the product */}

                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div></div>
                    {/* commented for new requirement */}
                    {/* <div className="mt-2">
                    {" "}
                    <div style={{ display: "flex" }}>
                      <label className="mr-5 text-sm text-secondary">
                        {LocaleStrings.about_the_product}
                      </label>

                      <Field
                        name="autotranslate"
                        label={LocaleStrings.en}
                        component={this.renderFieldRadio}
                        type="radio"
                        value="0"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      />

                      <Field
                        name="autotranslate"
                        label={LocaleStrings.ar}
                        component={this.renderFieldRadio}
                        type="radio"
                        value="1"
                        className="mx-2"
                        onChange={this.handleInputLanguageChange.bind(this)}
                      />
                    </div>
                  </div> */}

                    <div className="w-48 ml-auto">
                      {" "}
                      <button
                        type="button"
                        disabled={tanslateBtnLoader}
                        className={classNames(
                          tanslateBtnLoader ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}
                        onClick={(e) => this.translate(e, enar)}>
                        <svg
                          className={classNames(
                            tanslateBtnLoader ? "" : "hidden",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {enar === "ar"
                          ? LocaleStrings.translate_from_english
                          : LocaleStrings.translate_from_arabic}
                      </button>
                    </div>
                  </div>
                  {/* Enable Auto translate */}
                  <div>
                    <Field
                      name="autotranslate_ischeck"
                      label={LocaleStrings.enable_auto_translate}
                      component={this.renderFieldCheckbox}
                      type="checkbox"
                      className="cust-check-box"
                      classNameLabel="text-box-lable"
                    />
                  </div>

                  {/* OUTSIDE ENDS */}
                </fieldset>
                {/* <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  {LocaleStrings.select_a_tab}
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full focus:ring-primary focus:border-primary border-ternary rounded-md"
                  defaultValue={this.props.tabs.find((tab) => tab.current).name}
                  onChange={(e) =>
                    this.onTabsChange(e, 0, "sm", this.props.tabs)
                  }>
                  {this.props.tabs.map((tab, index) => (
                    <option
                      key={tab.name}
                      value={index}
                      selected={tabIndextoShow === index ? "selected" : ""}>
                      {this.getBilingualTabName(tab)}
                    </option>
                  ))}
                </select>
              </div> */}
                <div className="block sm:hidden">
                  <nav
                    className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                    aria-label="Tabs">
                    {this.props.tabs.map((tab, tabIdx) => (
                      <span
                        key={tab.name}
                        className={classNames(
                          tab.current
                            ? "text-secondary"
                            : "text-ternary hover:text-secondary",

                          "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                        onClick={(e) =>
                          this.onTabsChange(
                            e,
                            tab.tabindex,
                            "lg",
                            this.props.tabs
                          )
                        }>
                        <span>
                          {this.getBilingualTabName(tab, "xs")}
                          {/* {tab.name}-- */}
                        </span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? "bg-primary" : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5 "
                          )}
                        />
                      </span>
                    ))}
                  </nav>
                </div>
                <div className="hidden sm:block">
                  <nav
                    className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                    aria-label="Tabs">
                    {this.props.tabs.map((tab, tabIdx) => (
                      <span
                        key={tab.name}
                        className={classNames(
                          tab.current
                            ? "text-secondary"
                            : "text-ternary hover:text-secondary",

                          "cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-4 text-xs font-normal text-center hover:bg-quaternary focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                        onClick={(e) =>
                          this.onTabsChange(
                            e,
                            tab.tabindex,
                            "lg",
                            this.props.tabs
                          )
                        }>
                        <span>
                          {this.getBilingualTabName(tab, "lg")}
                          {/* {tab.name}-- */}
                        </span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? "bg-primary" : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5 "
                          )}
                        />
                      </span>
                    ))}
                  </nav>
                </div>
                <div className="">
                  <div className="xs:m-2 sm:m-2 lg:m-10 space-x-4- ">
                    <div className={tabIndextoShow === 0 ? "" : "hidden"}>
                      <fieldset className="space-y-5">
                        {/* Product title */}

                        <div className="mt-2">
                          {" "}
                          <div style={{ display: "flex" }}>
                            <label className="mr-5 text-sm text-secondary">
                              {LocaleStrings.about_the_product}
                            </label>

                            <Field
                              name="autotranslate"
                              label={LocaleStrings.en}
                              component={this.renderFieldRadio}
                              type="radio"
                              value="0"
                              className="mx-2"
                              onChange={this.handleInputLanguageChange.bind(
                                this
                              )}
                            />

                            <Field
                              name="autotranslate"
                              label={LocaleStrings.ar}
                              component={this.renderFieldRadio}
                              type="radio"
                              value="1"
                              className="mx-2"
                              onChange={this.handleInputLanguageChange.bind(
                                this
                              )}
                            />
                          </div>
                        </div>
                        <div>
                          <Field
                            name={"productname_" + enar}
                            label={LocaleStrings.product_title}
                            placeholder={LocaleStrings.enter_product_title}
                            type="text"
                            component={this.renderDatalistSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            datalistoptn={productTitleOption}
                            onChange={this._handleDataList}
                            classNameLabel="text-box-lable"
                            list={"productname_" + enar}
                          />
                        </div>
                        {/* Product sub-title description */}
                        <div>
                          <Field
                            name={"product_desc_" + enar}
                            label={LocaleStrings.product_subtitle_desc}
                            placeholder={
                              LocaleStrings.enter_product_subtitle_desc
                            }
                            mandatory="true"
                            component={this.renderFieldTextareaWithList}
                            // maxlength={200}
                            datalist={productDescOption}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-box-lable"
                            className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                            onChange={this.handleProductSubTitleKeyUp}
                            onFocus={this.onFocus}
                            onBlur={this.onFocusOut}
                            enar={enar}
                            productSubTitle={this.state.productSubTitle}
                            isOpen={this.state.showDescAutocomplete}
                            getValue={this.getValue.bind(this)}
                          />
                        </div>
                        {/* Product category,subcategory */}
                        <div>
                          <Field
                            name="product_categorys"
                            label={LocaleStrings.product_category_subcategory}
                            // placeholder={
                            //   this.props.selectedSubcategorids.length > 0
                            //     ? this.props.selectedSubcategorids[1].length +
                            //       " categories, " +
                            //       this.props.selectedSubcategorids[0].length +
                            //       " sub-categories"
                            //     : LocaleStrings.select_product_category_subcategory
                            // }
                            placeholder={cateSubCatePlaceHolder}
                            component={this.renderSelectSpecial}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            opts={cateSubcateOptions}
                            className="cust-input-field  cursor-pointer"
                            classNameLabel="block text-box-lable"
                            fc={this.onCateSubCateModalOpen}
                          />
                        </div>
                        {/* Product condition */}
                        <div>
                          <Field
                            name="product_condition"
                            label={LocaleStrings.product_condition}
                            placeholder={LocaleStrings.select}
                            component={this.renderSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            opts={productConditionOptions}
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                          />
                        </div>
                        {/* <div className="grid grid-cols-3 gap-4">
                        <div className="col-start-2 col-span-1 ...">
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={(e) =>
                              this.onNextBtnTabChange(e, tabIndextoShow)
                            }
                          >
                            {LocaleStrings.next}
                          </button>
                        </div>
                      </div> */}
                      </fieldset>

                      {/* uproduct upload new change direct and auction product comes under this tab*/}
                      <fieldset className="space-y-5 mt-4">
                        {/* SALE TYPE [direact /action] 
                        Note :-  If one item is in “Direct Sale” mode means in “discount” then at Edit Disabled “Auction” option.
                        */}
                        <div
                          className="py-1 text-md font-semibold"
                          style={{
                            display:
                              isEdit &&
                              productData &&
                              productData.isdraft &&
                              (productData.isdraft == 0 ||
                                productData.isdraft == "0")
                                ? "none"
                                : "block",
                          }}>
                          {LocaleStrings.sales_format}
                        </div>
                        <div
                          style={{
                            display:
                              isEdit &&
                              productData &&
                              productData.isdraft &&
                              (productData.isdraft == 0 ||
                                productData.isdraft == "0")
                                ? "none"
                                : "flex",
                          }}>
                          <Field
                            name="saletype"
                            label={LocaleStrings.direct_sale}
                            component={this.renderFieldRadio}
                            type="radio"
                            value="directsale"
                            // defaultValue="directsale"
                            className="mx-2"
                            onChange={this.onSaletypeChange.bind(this)}
                          />

                          <Field
                            name="saletype"
                            label={LocaleStrings.auction}
                            component={this.renderFieldRadio}
                            type="radio"
                            value="auction"
                            className="mx-2"
                            onChange={this.onSaletypeChange.bind(this)}
                          />
                        </div>
                        {defaultSaletype === "directsale" ? (
                          <>
                            {/* {isEmpty(prodDiscAddedData) ? (
                            this.renderDiscountList()
                          ) : (
                            <div>
                              <p className="text-sm font-medium text-ternary ">
                                *{" "}
                                {parseFloat(
                                  prodDiscAddedData.discount_percentage
                                )}{" "}
                                {prodDiscAddedData.discounttype === "fixed"
                                  ? LocaleStrings.flat
                                  : LocaleStrings.percent_sign}{" "}
                                {LocaleStrings.discount_using}{" "}
                                {prodDiscAddedData.discount_title}{" "}
                                {LocaleStrings.might_apply}
                              </p>
                            </div>
                          )}
                          {this.props.session.usertype != 'personal' ? (
                            <div className="w-full sm:w-1/3">
                              <button
                                type="button"
                                className="btn-primary"
                                onClick={this.addProductDiscount_click}
                              >
                                {isEmpty(prodDiscAddedData)
                                  ? LocaleStrings.add_product_discount
                                  : LocaleStrings.edit_discount}
                              </button>
                            </div>
                          ) : ('')} */}

                            {/* ENABLE BEST OFFER */}
                            {this.props.session.usertype !== "personal" ? (
                              <div>
                                <Field
                                  name="bestofferenabled"
                                  label={LocaleStrings.enable_best_offer}
                                  component={this.renderFieldCheckbox}
                                  mandatory="false"
                                  type="checkbox"
                                  className="cust-check-box"
                                  classNameLabel="text-box-lable"
                                />
                                <p className="mt-1 text-sm text-tarnary">
                                  {LocaleStrings.enable_best_offer_text}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <div className="grid grid-cols-1  gap-4">
                          <div className="w-60 mx-auto">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.onNextBtnTabChange(e, tabIndextoShow)
                              }>
                              {LocaleStrings.next}
                            </button>
                          </div>
                        </div>
                      </fieldset>
                      {/*end uproduct upload new change direct and auction product comes under this tab*/}
                    </div>
                    <div className={tabIndextoShow === 1 ? "" : "hidden"}>
                      {/* Product variation [Enter the variant details] [STARTS]  */}
                      <fieldset
                        className={classNames(
                          parseInt(defaultHasvariant) ? "" : "mb-6",
                          "space-y-5"
                        )}>
                        {/* The product has variant ? section [STARTS]*/}

                        {defaultSaletype === "directsale" ? (
                          <>
                            {isEmpty(prodDiscAddedData) ? (
                              this.renderDiscountList()
                            ) : (
                              <div>
                                <p className="text-sm font-medium text-ternary ">
                                  *{" "}
                                  {parseFloat(
                                    prodDiscAddedData.discount_percentage
                                  )}{" "}
                                  {prodDiscAddedData.discounttype === "fixed"
                                    ? LocaleStrings.flat
                                    : LocaleStrings.percent_sign}{" "}
                                  {LocaleStrings.discount_using}{" "}
                                  {prodDiscAddedData.discount_title}{" "}
                                  {LocaleStrings.might_apply}
                                </p>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {/* VARIANTS ARE NOW DEPENDENT ON SALETYPE, AUCTION SALE == HASVARIANT (0) */}
                        {defaultSaletype === "directsale" ? (
                          <>
                            <div
                              className={classNames(
                                isEdit &&
                                  productData &&
                                  productData.isdraft &&
                                  (productData.isdraft == 0 ||
                                    productData.isdraft == "0")
                                  ? "sr-only"
                                  : "",
                                ""
                              )}>
                              <div className="mr-5 text-sm text-secondary my-1">
                                {LocaleStrings.is_product_has_variant}
                              </div>
                              <div className="mr-5 text-sm text-secondary my-1">
                                {LocaleStrings.product_variant_small_desc}
                              </div>
                              <div style={{ display: "flex" }}>
                                <Field
                                  name="hasvariant"
                                  label={LocaleStrings.button_yes}
                                  component={this.renderFieldRadio}
                                  type="radio"
                                  value="1"
                                  className="mx-2"
                                  onChange={this.handleHasvariantChange.bind(
                                    this
                                  )}
                                />
                                <Field
                                  name="hasvariant"
                                  label={LocaleStrings.button_no}
                                  component={this.renderFieldRadio}
                                  type="radio"
                                  value="0"
                                  className="mx-2"
                                  onChange={this.handleHasvariantChange.bind(
                                    this
                                  )}
                                />
                              </div>
                            </div>
                            {parseInt(defaultHasvariant) ? (
                              <>
                                {/* {new} */}

                                <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-2">
                                  <div className="bg-white  rounded pb-4">
                                    <div className="grid grid-cols-5">
                                      <div className="col-span-2">
                                        {this.renderMasterVariation()}

                                        <div className="flex p-2">
                                          <div>
                                            {LocaleStrings.add_variation}
                                          </div>
                                          <div
                                            className="end-item ml-auto text-primary cursor-pointer"
                                            onClick={(e) =>
                                              this.updateMastervariationList(e)
                                            }>
                                            {LocaleStrings.add}
                                          </div>
                                        </div>
                                        <div className="m-2">
                                          <Field
                                            name="custom_variation"
                                            type="text"
                                            component={this.renderFieldText}
                                            className="mr-2"
                                            placeholder={
                                              LocaleStrings.add_variation
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-span-3 border-l-2 border-color-gray">
                                        {variationValue.length > 0 ? (
                                          <>
                                            <div className="grid grid-cols-2 gap-2 m-4 mt-10">
                                              {this.renderMasterVariationValueList()}
                                            </div>
                                            {/* <div className="flex p-2">
                                            <Field
                                              name="custom_variation_value"
                                              type="text"
                                              component={this.renderFieldText}
                                              className="mr-2"
                                              placeholder="Add Value1"
                                            />
                                            <div
                                              className="text-primary ml-2 cursor-pointer"
                                              onClick={(e) =>
                                                this.updateVariationValue(e)
                                              }>
                                              {LocaleStrings.add}
                                            </div>
                                          </div> */}
                                          </>
                                        ) : (
                                          <p className="paragraph-seconday text-center items-center">
                                            {
                                              LocaleStrings.select_variation_text
                                            }
                                          </p>
                                        )}

                                        {variationValue.length > 0 ? (
                                          <div className="flex p-2">
                                            <Field
                                              name="custom_variation_value"
                                              type="text"
                                              component={this.renderFieldText}
                                              className="mr-2"
                                              placeholder="Add Value"
                                            />
                                            <div
                                              className="text-primary ml-2 cursor-pointer"
                                              onClick={(e) =>
                                                this.updateVariationValue(e)
                                              }>
                                              {LocaleStrings.add}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    {isEdit ? (
                                      <div className="p-4 font-semibold">
                                        *Adding New Variant Would Require Adding
                                        of Media And Price details again
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {vatiantsFeildArrState.length > 0 ? (
                                    <div className="bg-white p-4">
                                      <div className="grid grid-cols-6 my-2">
                                        {this.renderstateVariationformed()}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {this.props.session.usertype != "personal" &&
                                this.state.defaultSaleType != "auction" ? (
                                  <div className="w-full sm:w-1/3 ml-auto">
                                    <button
                                      type="button"
                                      className="btn-primary"
                                      onClick={this.addProductDiscount_click}>
                                      {isEmpty(prodDiscAddedData)
                                        ? LocaleStrings.add_product_discount
                                        : LocaleStrings.edit_discount}
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* {old}  */}
                                {/* <FieldArray
                                  name="variants"
                                  component={this.renderVariants}
                                  option={variationMasterOption}
                                        /> */}
                                {vatiantsFeildArrState &&
                                vatiantsFeildArrState.length > 0 ? (
                                  <div className="text-right">
                                    <button
                                      type="button"
                                      className="btn-primary-right"
                                      onClick={
                                        this.updateVariationDeatilsClick
                                      }>
                                      {
                                        LocaleStrings.update_variation_details_btn_text
                                      }
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {vatiantsFeildArrState.length > 0 ? (
                                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 border-2 border-gray-300 p-4 rounded-lg">
                                    {this.renderstatemasterVariationImages()}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {variantVariationsState.length > 0 ? (
                                  <>
                                    <div>
                                      {LocaleStrings.price_quantity_small_text}
                                    </div>
                                    <div className="hidden md:block">
                                      <div className="grid grid-cols-8 pt-8 gap-4">
                                        {this.rendervariantVariationsStateListmobile()}
                                      </div>
                                    </div>
                                    <div className="block md:hidden">
                                      <div className="grid grid-cols-1  pt-8 gap-4">
                                        {this.rendervariantVariationsStateList()}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {/* {old} */}
                              </>
                            ) : (
                              <>
                                <div className="end-item">
                                  {this.props.session.usertype != "personal" &&
                                  this.state.defaultSaleType != "auction" ? (
                                    <div className="w-full sm:w-1/3">
                                      <button
                                        type="button"
                                        className="btn-primary"
                                        onClick={this.addProductDiscount_click}>
                                        {isEmpty(prodDiscAddedData)
                                          ? LocaleStrings.add_product_discount
                                          : LocaleStrings.edit_discount}
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                  <div>
                                    <Field
                                      name={
                                        "all_variants_price_unit[" + 0 + "]"
                                      }
                                      label={
                                        LocaleStrings.isVariationDeatilsopen_priceunit
                                      }
                                      placeholder={
                                        LocaleStrings.isVariationDeatilsopen_priceunit
                                      }
                                      type="number"
                                      component={this.renderFieldText}
                                      mandatory="true"
                                      min="0"
                                      labelposition={LABEL_POSITION_TOP}
                                      classNameLabel="text-sm font-medium text-secondary"
                                      onChange={(e) =>
                                        this.handleVariantDetailsKeyUp(
                                          e,
                                          0,
                                          "price_unit"
                                        )
                                      }
                                    />
                                  </div>
                                  <div>
                                    {/* {variantVariationsState &&
                                            variantVariationsState[0]
                                              ? variantVariationsState[0].quantity
                                              : null} */}
                                    <Field
                                      name={"all_variants_units[" + 0 + "]"}
                                      label={
                                        LocaleStrings.isVariationDeatilsopen_unit
                                      }
                                      placeholder={"Select Unit"}
                                      component={this.renderSelect}
                                      mandatory="true"
                                      labelposition={LABEL_POSITION_TOP}
                                      opts={refundUnitOptions}
                                      className="cust-input-field2"
                                      classNameLabel="block text-sm font-medium text-secondary"
                                      onChange={(e) =>
                                        this.handleVariantDetailsKeyUp(
                                          e,
                                          0,
                                          "units"
                                        )
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Field
                                      name={"all_variants_quantity[" + 0 + "]"}
                                      label={LocaleStrings.quantity}
                                      placeholder={LocaleStrings.quantity}
                                      type="number"
                                      component={this.renderFieldText}
                                      mandatory="true"
                                      min="0"
                                      labelposition={LABEL_POSITION_TOP}
                                      classNameLabel="text-sm font-medium text-secondary"
                                      onChange={(e) =>
                                        this.handleVariantDetailsKeyUp(
                                          e,
                                          0,
                                          "quantity"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <Drop
                                    parentIndex={0}
                                    mainarr={variantVariationsState}
                                    updateStateArr={
                                      this.variantVariationsStateUpdateFc
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="end-item">
                              {this.props.session.usertype != "personal" &&
                              this.state.defaultSaleType != "auction" ? (
                                <div className="w-full sm:w-1/3">
                                  <button
                                    type="button"
                                    className="btn-primary"
                                    onClick={this.addProductDiscount_click}>
                                    {isEmpty(prodDiscAddedData)
                                      ? LocaleStrings.add_product_discount
                                      : LocaleStrings.edit_discount}
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                              <div>
                                <Field
                                  name={"all_variants_price_unit[" + 0 + "]"}
                                  label={
                                    LocaleStrings.isVariationDeatilsopen_startingbid
                                  }
                                  placeholder={
                                    LocaleStrings.isVariationDeatilsopen_startingbid
                                  }
                                  type="number"
                                  component={this.renderFieldText}
                                  mandatory="true"
                                  min="0"
                                  labelposition={LABEL_POSITION_TOP}
                                  classNameLabel="text-sm font-medium text-secondary"
                                  onChange={(e) =>
                                    this.handleVariantDetailsKeyUp(
                                      e,
                                      0,
                                      "price_unit"
                                    )
                                  }
                                />
                              </div>
                              <div>
                                {/* {variantVariationsState &&
                                      variantVariationsState[0]
                                        ? variantVariationsState[0].quantity
                                        : null} */}
                                <Field
                                  name={"all_variants_units[" + 0 + "]"}
                                  label={
                                    LocaleStrings.isVariationDeatilsopen_unit
                                  }
                                  placeholder={"Select Unit"}
                                  component={this.renderSelect}
                                  mandatory="true"
                                  labelposition={LABEL_POSITION_TOP}
                                  opts={refundUnitOptions}
                                  className="cust-input-field2"
                                  classNameLabel="block text-sm font-medium text-secondary"
                                  onChange={(e) =>
                                    this.handleVariantDetailsKeyUp(
                                      e,
                                      0,
                                      "units"
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <Field
                                  name={"all_variants_quantity[" + 0 + "]"}
                                  label={LocaleStrings.quantity}
                                  placeholder={LocaleStrings.quantity}
                                  type="number"
                                  component={this.renderFieldText}
                                  mandatory="true"
                                  isdisabled={"true"}
                                  min="0"
                                  labelposition={LABEL_POSITION_TOP}
                                  classNameLabel="text-sm font-medium text-secondary"
                                  onChange={(e) =>
                                    this.handleVariantDetailsKeyUp(
                                      e,
                                      0,
                                      "quantity"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            {/****  AUCTION *****/}
                            {/* MINIMUM RESERVE PRICE */}
                            <div>
                              <Field
                                name="auction_min_price_percentage"
                                label={LocaleStrings.min_reserve_price}
                                placeholder={
                                  LocaleStrings.enter_min_reserve_price
                                }
                                type="number"
                                component={this.renderFieldText}
                                mandatory="true"
                                labelposition={LABEL_POSITION_TOP}
                                classNameLabel="text-box-lable"
                              />
                            </div>
                            {/* AUCTION START DATE */}
                            <div>
                              <Field
                                name="auctiontype"
                                label={LocaleStrings.schedule_auction}
                                component={this.renderFieldCheckbox}
                                mandatory="false"
                                type="checkbox"
                                className="cust-check-box"
                                classNameLabel="text-box-lable"
                                onChange={(e) => this.auctiontypeChange(e)}
                              />
                            </div>
                            {auctiontypestate == true ? (
                              <div>
                                <Field
                                  name="auction_start_date"
                                  label={LocaleStrings.start_date}
                                  component={
                                    this.renderDateTimePickerWithLabel1
                                  }
                                  mandatory="false"
                                  minDate={
                                    _.has(initialValues, ["id"])
                                      ? new Date(
                                          moment(
                                            initialValues.createdon
                                          ).format(MIN_DATE_FORMAT)
                                        )
                                      : new Date(
                                          moment().format(MIN_DATE_FORMAT)
                                        )
                                  }
                                  // selected={moment()}
                                  onChange={(e) =>
                                    this.auctionDatechange(e, "startdate")
                                  }
                                  className="cust-input-field "
                                  classNameLabel="text-box-lable"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            <div>
                              <Field
                                name="auctiondays"
                                label="Select Auction Duration"
                                placeholder={LocaleStrings.select}
                                component={this.renderSelect}
                                mandatory="true"
                                labelposition={LABEL_POSITION_TOP}
                                opts={auctiondaysOpts}
                                className="cust-input-field2"
                                onChange={(e) =>
                                  this.auctionDatechange(e, "days")
                                }
                                classNameLabel="block text-box-lable"
                              />
                            </div>

                            {/* AUCTION END DATE */}

                            <div className="hidden">
                              <Field
                                name="auction_end_date"
                                label={LocaleStrings.end_date}
                                component={this.renderDateTimePickerWithLabel1}
                                mandatory="false"
                                // disabled={true}
                                minDate={
                                  _.has(initialValues, ["id"])
                                    ? new Date(
                                        moment(initialValues.createdon).format(
                                          MIN_DATE_FORMAT
                                        )
                                      )
                                    : new Date(moment().format(MIN_DATE_FORMAT))
                                }
                                // selected={moment()}
                                className="cust-input-field "
                                classNameLabel="text-box-lable"
                              />
                            </div>
                            {/*ACTION INCREMENTAL RATE */}
                            <div>
                              <Field
                                name="incremental_rate"
                                label={LocaleStrings.incremental_rate}
                                placeholder={
                                  LocaleStrings.enter_incremental_rate
                                }
                                type="text"
                                component={this.renderFieldTextDiscount}
                                labelposition={LABEL_POSITION_TOP}
                                mandatory="true"
                                className="cust-input-field"
                                classNameLabel="text-box-lable"
                                discountType={this.state.incrementalTypeState}
                                handelDiscountType={this.handelIncrementalType}
                              />
                            </div>
                            <div>
                              <Drop
                                parentIndex={0}
                                mainarr={variantVariationsState}
                                updateStateArr={
                                  this.variantVariationsStateUpdateFc
                                }
                              />
                            </div>
                          </>
                        )}

                        {/* The product has variant ? section [ENDS]*/}

                        {/* {Item Specification in tab2} */}

                        <div>{LocaleStrings.required_itemspecs}</div>

                        <div>{LocaleStrings.item_specs_smalltext}</div>

                        <div>{this.renderItemSpecFieldRequired()}</div>
                        <hr />
                        <div>{LocaleStrings.additional_itemspecs}</div>
                        <div>{LocaleStrings.item_specs_smalltext}</div>

                        <div>{this.renderItemSpecFieldAdditional()}</div>
                        <div className="grid grid-cols-1 md:grid-cols-4 m-2">
                          <div className="cursor-pointer">Add Custom</div>
                          <div className="col-span-3">
                            <FieldArray
                              name={"product_specification_custom"}
                              component={this.renderspecsCustom}
                            />
                            <div className="text-sm mt-2">
                              {LocaleStrings.specs_small_text}
                            </div>
                          </div>
                        </div>
                        <div className="md:flex block center-item gap-4">
                          <div className="w-60">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.previousTabChange(e, tabIndextoShow)
                              }>
                              Previous
                            </button>
                          </div>
                          <div className="w-60">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.onNextBtnTabChange(e, tabIndextoShow)
                              }>
                              {LocaleStrings.next}
                            </button>
                          </div>
                        </div>
                      </fieldset>

                      {/* Product variation [Enter the variant details] [ENDS]  */}
                    </div>
                    {/* hidden for upload product new change */}
                    {/* <div className={tabIndextoShow === 2 ? "" : "hidden"}>
                    <fieldset className="space-y-5">
                      <div>
                        <Field
                          name={"specification_desc_" + enar}
                          label={LocaleStrings.standard_description}
                          placeholder={LocaleStrings.enter_standard_description}
                          mandatory="true"
                          component={this.renderFieldTextarea}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                          className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                        />
                      </div>
                     
                      <div>{this.renderGlobalSpecFields()}</div>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-start-2 col-span-1 ...">
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={(e) =>
                              this.onNextBtnTabChange(e, tabIndextoShow)
                            }
                          >
                            {LocaleStrings.next}
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className={tabIndextoShow === 3 ? "" : "hidden"}>
                    <fieldset className="space-y-5">
                    
                      <div>
                        <div className="flex justify-between ...">
                          <div>
                            <p className="paragraph-seconday">
                              {LocaleStrings.item_specifications}
                            </p>
                          </div>
                        </div>
                      </div>
                      {this.renderItemSpecFields()}
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-start-2 col-span-1 ...">
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={(e) =>
                              this.onNextBtnTabChange(e, tabIndextoShow)
                            }
                          >
                            {LocaleStrings.next}
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className={tabIndextoShow === 4 ? "" : "hidden"}>
                  <fieldset className="space-y-5 mt-4">
                      <div style={{ display: isEdit ? "none" : "flex" }}>
                        <Field
                          name="saletype"
                          label={LocaleStrings.direct_sale}
                          component={this.renderFieldRadio}
                          type="radio"
                          value="directsale"
                          // defaultValue="directsale"
                          className="mx-2"
                          onChange={this.onSaletypeChange.bind(this)}
                        />

                        <Field
                          name="saletype"
                          label={LocaleStrings.auction}
                          component={this.renderFieldRadio}
                          type="radio"
                          value="auction"
                          className="mx-2"
                          onChange={this.onSaletypeChange.bind(this)}
                        />
                      </div>
                      {defaultSaletype === "directsale" ? (
                        <>
                          {isEmpty(prodDiscAddedData) ? (
                            this.renderDiscountList()
                          ) : (
                            <div>
                              <p className="text-sm font-medium text-ternary ">
                                *{" "}
                                {parseFloat(
                                  prodDiscAddedData.discount_percentage
                                )}{" "}
                                {prodDiscAddedData.discounttype === "fixed"
                                  ? LocaleStrings.flat
                                  : LocaleStrings.percent_sign}{" "}
                                {LocaleStrings.discount_using}{" "}
                                {prodDiscAddedData.discount_title}{" "}
                                {LocaleStrings.might_apply}
                              </p>
                            </div>
                          )}
                          {this.props.session.usertype != 'personal' ? (
                            <div className="w-full sm:w-1/3">
                              <button
                                type="button"
                                className="btn-primary"
                                onClick={this.addProductDiscount_click}
                              >
                                {isEmpty(prodDiscAddedData)
                                  ? LocaleStrings.add_product_discount
                                  : LocaleStrings.edit_discount}
                              </button>
                            </div>
                          ) : ('')}

                          {this.props.session.usertype !== "personal" ? (
                            <div>
                              <Field
                                name="bestofferenabled"
                                label={LocaleStrings.enable_best_offer}
                                component={this.renderFieldCheckbox}
                                mandatory="false"
                                type="checkbox"
                                className="cust-check-box"
                                classNameLabel="text-box-lable"
                              />
                              <p className="mt-1 text-sm text-tarnary">
                                {LocaleStrings.enable_best_offer_text}
                              </p>
                            </div>
                          ) : ('')}

                        </>
                      ) : (
                        <>
                          
                          <div>
                            <Field
                              name="auction_min_price_percentage"
                              label={LocaleStrings.min_reserve_price}
                              placeholder={
                                LocaleStrings.enter_min_reserve_price
                              }
                              type="number"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-box-lable"
                            />
                          </div>
                          <div>
                            <Field
                              name="auction_start_date"
                              label={LocaleStrings.start_date}
                              component={this.renderDateTimePickerWithLabel1}
                              mandatory="false"
                              minDate={
                                _.has(initialValues, ["id"])
                                  ? new Date(
                                    moment(initialValues.createdon).format(
                                      MIN_DATE_FORMAT
                                    )
                                  )
                                  : new Date(moment().format(MIN_DATE_FORMAT))
                              }
                              // selected={moment()}
                              className="cust-input-field "
                              classNameLabel="text-box-lable"
                            />
                          </div>
                          <div>
                            <Field
                              name="auction_end_date"
                              label={LocaleStrings.end_date}
                              component={this.renderDateTimePickerWithLabel1}
                              mandatory="false"
                              minDate={
                                _.has(initialValues, ["id"])
                                  ? new Date(
                                    moment(initialValues.createdon).format(
                                      MIN_DATE_FORMAT
                                    )
                                  )
                                  : new Date(moment().format(MIN_DATE_FORMAT))
                              }
                              // selected={moment()}
                              className="cust-input-field "
                              classNameLabel="text-box-lable"
                            />
                          </div>
                          <div>
                            <Field
                              name="incremental_rate"
                              label={LocaleStrings.incremental_rate}
                              placeholder={LocaleStrings.enter_incremental_rate}
                              type="text"
                              component={this.renderFieldTextDiscount}
                              labelposition={LABEL_POSITION_TOP}
                              mandatory="true"
                              className="cust-input-field"
                              classNameLabel="text-box-lable"
                              discountType={this.state.incrementalTypeState}
                              handelDiscountType={this.handelIncrementalType}
                            />
                          </div>
                        </>
                      )}
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-start-2 col-span-1 ...">
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={(e) =>
                              this.onNextBtnTabChange(e, tabIndextoShow)
                            }
                          >
                            {LocaleStrings.next}
                          </button>
                        </div>
                      </div>
                    </fieldset>

                  </div> */}
                    <div className={tabIndextoShow === 2 ? "" : "hidden"}>
                      {/* Shipping type and service */}
                      <fieldset className="space-y-5">
                        {/* SHIPPING TYPE */}
                        <div className="">
                          <div className="py-2 text-md font-semibold">
                            {LocaleStrings.item_location}
                          </div>
                          <div className="py-2">
                            <Field
                              name="location_countryid"
                              label={LocaleStrings.label_country}
                              placeholder={LocaleStrings.placeholder_country}
                              component={this.renderSelect}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              opts={countryListOptions}
                              className="cust-input-field2"
                              classNameLabel="block text-box-lable"
                            />
                          </div>
                          <div className="py-2">
                            <Field
                              name={"location_state_" + enar}
                              label={LocaleStrings.label_state}
                              placeholder={LocaleStrings.placeholder_state}
                              type="text"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-box-lable"
                            />
                          </div>
                          <div className="py-2">
                            <Field
                              name={"location_city_" + enar}
                              label={LocaleStrings.label_city}
                              placeholder={LocaleStrings.placeholder_city}
                              type="text"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-box-lable"
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="">
                          <div className="py-2 text-md font-semibold">
                            {LocaleStrings.shipping_charge}
                          </div>
                          <div>
                            <Field
                              name="shipping_type"
                              label={LocaleStrings.flat}
                              component={this.renderFieldRadio}
                              type="radio"
                              value="flat"
                              className="mx-2"
                              lebelAlign="R"
                              onChange={this._handleShippingType}
                            />
                            <div className="text-xs text-ternary px-4 py-2">
                              Please note that if the shipping charge set by you
                              (either 'Flat' or 'No shipping') for the product
                              is less than the charge set by our partners, you
                              will be responsible for bearing the balance amount
                              towards Shopez. Shopez cannot be held responsible
                              for any discrepancies in shipping charges or for
                              any additional costs incurred due to such
                              discrepancies.
                            </div>
                            <Field
                              name="shipping_cost_f"
                              label=""
                              placeholder={LocaleStrings.enter_shipping_cost}
                              type="number"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              className="mt-2"
                              min="0"
                              classNameLabel="text-box-lable"
                              isdisabled={shippingType == "local" ? "true" : ""}
                            />
                          </div>
                          {/* Note:- Upload product-->Hide Calculated shipping option for now */}
                          <div className="py-2">
                            <Field
                              name="shipping_type"
                              label={LocaleStrings.calculated}
                              component={this.renderFieldRadio}
                              type="radio"
                              value="calculated"
                              className="mx-2"
                              labelClasses=""
                              lebelAlign="R"
                              onChange={this._handleShippingType}
                            />
                            <div className="text-xs text-ternary px-4 py-2">
                              {LocaleStrings.shipping_charge_note}
                            </div>
                            {/* Note:- Upload product-->Hide Calculated shipping option for now */}
                            <Field
                              name="shipping_cost_c"
                              label=""
                              placeholder={
                                LocaleStrings.shipping_cost_by_location
                              }
                              type="number"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              min="0"
                              className="mt-2 sr-only"
                              classNameLabel="text-box-lable sr-only"
                              isdisabled={shippingType == "local" ? "true" : ""}
                            />
                          </div>

                          <div className="py-2">
                            <Field
                              name="shipping_type"
                              label={LocaleStrings.no_shipping}
                              component={this.renderFieldRadio}
                              type="radio"
                              value="local"
                              className="mx-2"
                              lebelAlign="R"
                              onChange={this._handleShippingType}
                            />
                            <div className="text-xs text-ternary px-4 py-2">
                              {LocaleStrings.shipping_charge_note2}
                            </div>
                          </div>
                        </div>
                        {/* not required in uproduct upload new change */}
                        {/* <div>
                        <Field
                          name="product_calculated_shipping"
                          label={LocaleStrings.shipping_zone_pincode}
                          placeholder={LocaleStrings.select_shipping_zone_pincode}
                          options={zonePincodeOptions}
                          component={this.renderAutoComplete1}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-2 ring-2 ring-red-500"
                          classNameLabel="text-box-lable"
                          isMulti={true}
                        />
                      </div> */}
                        {/* SHIPPING SERVICE */}
                        <hr />
                        <div className="">
                          <div className="py-2 text-md font-semibold">
                            {LocaleStrings.shipping}
                          </div>
                          <div className="py-3">
                            <Field
                              name="delivery_company"
                              label={LocaleStrings.shipping_service}
                              placeholder={LocaleStrings.select_service}
                              component={this.renderSelect}
                              mandatory={
                                shippingType === "local" ? "false" : "true"
                              }
                              labelposition={LABEL_POSITION_TOP}
                              opts={shippingServiceOptions}
                              className="cust-input-field2"
                              classNameLabel="block text-box-lable"
                              onChange={this._handleSelectService}
                              isdisabled={shippingType == "local" ? true : ""}
                            />
                          </div>
                          {/* PACKAGE */}
                          <div className="py-3">
                            <Field
                              name="idcompany_package"
                              label={LocaleStrings.package}
                              placeholder={LocaleStrings.select_package}
                              component={this.renderSelect}
                              mandatory={
                                shippingType === "local" ? "false" : "true"
                              }
                              labelposition={LABEL_POSITION_TOP}
                              opts={servicePackagesOptions}
                              className="cust-input-field2"
                              classNameLabel="block text-box-lable"
                              isdisabled={shippingType == "local" ? true : ""}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="text-md font-semibold">
                          {LocaleStrings.exclude_location}
                        </div>
                        <div onClick={this.excludeShipping_click}>
                          <Field
                            name="excludelocation"
                            label="Country"
                            // placeholder={continentLengthdata.hasOwnProperty("continentcount") && continentLengthdata.continentcount != 0 ? continentLengthdata.continentcount + ' ' +'Continent Selected': "Exclude Countries"}
                            placeholder={excludeplaceholder}
                            type="text"
                            component={this.renderFieldText}
                            // mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-box-lable"
                          />
                        </div>
                        <div className="md:flex block center-item gap-4">
                          <div className="w-60">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.previousTabChange(e, tabIndextoShow)
                              }>
                              Previous
                            </button>
                          </div>
                          <div className="w-60">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.onNextBtnTabChange(e, tabIndextoShow)
                              }>
                              {LocaleStrings.next}
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className={tabIndextoShow === 3 ? "" : "hidden"}>
                      {/* Set preferences     */}
                      <fieldset className="space-y-5">
                        {/* ACCEPT CASH ON DELIVERY */}
                        <div style={{ display: "flex" }}>
                          <label className="mr-5 text-sm text-secondary">
                            {LocaleStrings.accept_cash_on_delivery}
                          </label>

                          <Field
                            name="cashondelivery"
                            label={LocaleStrings.button_yes}
                            component={this.renderFieldRadio}
                            type="radio"
                            value="yes"
                            className="mx-2"
                          />

                          <Field
                            name="cashondelivery"
                            label={LocaleStrings.button_no}
                            component={this.renderFieldRadio}
                            type="radio"
                            value="no"
                            className="mx-2"
                          />
                        </div>
                        {/* Is this product taxable ? */}
                        <div style={{ display: "flex" }}>
                          <label className="mr-5 text-sm text-secondary">
                            {LocaleStrings.product_managemnet_istaxable_text}
                          </label>
                          <Field
                            name="istaxable"
                            label={LocaleStrings.button_yes}
                            component={this.renderFieldRadio}
                            type="radio"
                            value={"1"}
                            className="mx-2"
                            onChange={this._handleIstaxable}
                          />
                          <Field
                            name="istaxable"
                            label={LocaleStrings.button_no}
                            component={this.renderFieldRadio}
                            type="radio"
                            value={"0"}
                            className="mx-2"
                            onChange={this._handleIstaxable}
                          />
                        </div>
                        {/* Product tax value in % */}
                        {istaxable === "1" || istaxable === 1 ? (
                          <div>
                            <Field
                              name="producttax"
                              label={LocaleStrings.product_tax_text}
                              placeholder={LocaleStrings.enter_product_tax_tex}
                              type="number"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-box-lable"
                            />
                          </div>
                        ) : null}

                        {/* ITEM LOCATION */}
                        <div>
                          {/* <Field
                          name="item_location"
                          label={LocaleStrings.item_location}
                          placeholder={LocaleStrings.enter_item_location}
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-box-lable"
                        /> */}
                        </div>
                        {/* HANDLING TIME */}
                        <div>
                          <Field
                            name="handling_time"
                            label={LocaleStrings.handling_time}
                            placeholder={LocaleStrings.select_handling_time}
                            component={this.renderSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            opts={handlingTimeOptions}
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                          />
                        </div>
                        {/* RETURN TIME */}
                        <div>
                          <Field
                            name="return_time"
                            label={LocaleStrings.return_time}
                            placeholder={LocaleStrings.select_return_time}
                            component={this.renderSelect}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            opts={returnTypeOptions}
                            onChange={this._handleReturnType}
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                            isdisabled={
                              this.state.defaultSaleType == "auction"
                                ? "true"
                                : ""
                            } // latest code
                          />
                        </div>
                        {/* REFUND TYPE */}
                        <div>
                          <Field
                            name="refund_type"
                            label={LocaleStrings.refund_type}
                            placeholder={LocaleStrings.select_refund_type}
                            component={this.renderSelect}
                            mandatory={
                              returnType === "no_return" ? "false" : "true"
                            }
                            labelposition={LABEL_POSITION_TOP}
                            opts={refundTypeOptions}
                            // isdisabled={returnType == "no_return" ? "true" : ""}// old code
                            isdisabled={
                              returnType == "no_return"
                                ? "true"
                                : this.state.defaultSaleType == "auction"
                                ? "true"
                                : ""
                            }
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                          />
                        </div>
                        <div>
                          <Field
                            name="shippingcostbearer"
                            label={LocaleStrings.shipping_cost_breared_by}
                            placeholder={
                              LocaleStrings.select_shipping_cost_breared_by
                            }
                            component={this.renderSelect}
                            mandatory={
                              returnType == "no_return" ||
                              shippingType == "local" //old code
                              // returnType == "no_return" || shippingType == "local"  ? "false" : "true" //latest code
                            }
                            labelposition={LABEL_POSITION_TOP}
                            opts={shippingCostBearerOptions}
                            // isdisabled={
                            //   returnType == "no_return" || shippingType == "local"
                            //     ? "true"
                            //     : ""
                            // } // old code
                            isdisabled={
                              returnType == "no_return" ||
                              shippingType == "local"
                                ? "true"
                                : this.state.defaultSaleType == "auction"
                                ? "true"
                                : ""
                            }
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                          />
                        </div>

                        {/* MAKE A DONATION */}
                        <div>
                          <Field
                            name="make_donation"
                            label={LocaleStrings.make_a_donation}
                            component={this.renderFieldCheckbox}
                            type="checkbox"
                            className="cust-check-box"
                            classNameLabel="text-box-lable"
                            onChange={this._handleMakeDonation.bind(this)}
                          />
                        </div>
                        {/* CHARITY EVENTS */}
                        <div className="pb-4">
                          <Field
                            name="idcharityevents"
                            label={LocaleStrings.charity_events}
                            placeholder={LocaleStrings.select_charity_events}
                            component={this.renderSelect}
                            mandatory={makeDonationState ? "true" : "false"}
                            labelposition={LABEL_POSITION_TOP}
                            opts={charityEventsOptions}
                            className="cust-input-field2"
                            classNameLabel="block text-box-lable"
                            isdisabled={makeDonationState ? "" : true}
                            onChange={(e) => this._handleCharityEventChange(e)}
                          />
                        </div>
                        {/* DONATION PERCENTAGE */}
                        <div className="pb-4 hidden">
                          {/* <Field
                          name="donation_percentage"
                          label={LocaleStrings.donation_percentage}
                          placeholder={LocaleStrings.set_donation_percentage}
                          component={this.renderSelect}
                          mandatory={makeDonationState ? "true" : "false"}
                          labelposition={LABEL_POSITION_TOP}
                          opts={charityPercentOptions}
                          className="cust-input-field2"
                          classNameLabel="block text-box-lable"
                          isdisabled={makeDonationState ? "" : true}
                        /> */}
                          <Field
                            name="donation_percentage"
                            label={LocaleStrings.donation_percentage}
                            placeholder={LocaleStrings.set_donation_percentage}
                            type="text"
                            component={this.renderFieldText}
                            mandatory={makeDonationState ? "true" : "false"}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-box-lable"
                            isdisabled={true}
                          />
                        </div>

                        {selected_charity_description_en != null &&
                        makeDonationState == true ? (
                          <div className="flex">
                            {this.state.enar == "en" ? (
                              <>
                                Charity Description:-
                                {selected_charity_description_en.length > 30
                                  ? selected_charity_description_en.substring(
                                      0,
                                      30
                                    ) + "..."
                                  : selected_charity_description_en}
                                <div
                                  className="cursor-pointer text-primary"
                                  onClick={(e) => this.openCharityview()}>
                                  See Details
                                </div>
                              </>
                            ) : (
                              <>
                                Charity Description:-
                                {selected_charity_description_ar.length > 30
                                  ? selected_charity_description_ar.substring(
                                      0,
                                      30
                                    ) + "..."
                                  : selected_charity_description_ar}
                                <div
                                  className="cursor-pointer text-primary"
                                  onClick={(e) => this.openCharityview()}>
                                  See Details
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          // <Field
                          //   name="charitydescription"
                          //   label="Charity Description"
                          //   placeholder="Charity Description"
                          //   type="text"
                          //   component={this.renderFieldText}
                          //   labelposition={LABEL_POSITION_TOP}
                          //   classNameLabel="text-box-lable"
                          //   isdisabled={true}
                          // />
                          ""
                        )}
                        {/* {charity_image != null ? (
                        <div className="block">
                          <div className="my-2">Charity Image</div>
                          <img
                            className="w-40 h-40 rounded"
                            src={charity_image}></img>
                        </div>
                      ) : (
                        ""
                      )} */}
                        {charity_donation_percentage != null &&
                        makeDonationState == true ? (
                          <div>
                            Donation Percentage Added By Shopez:-{" "}
                            {charity_donation_percentage} %
                          </div>
                        ) : (
                          ""
                        )}
                        {(charity_iscustom == true ||
                          charity_iscustom == 1 ||
                          charity_iscustom == "1") &&
                        makeDonationState == true ? (
                          <Field
                            name="donation_percentage"
                            label="Add Custom donation perecentage"
                            placeholder="Add Custom donation perecentage"
                            type="text"
                            component={this.renderFieldText}
                            mandatory={makeDonationState ? "true" : "false"}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-box-lable"
                            // onChange={(e) => this.handelcustomDonation()}
                          />
                        ) : (
                          ""
                        )}
                        {session.usertype != "personal" ? (
                          <>
                            <div className="mr-5 text-sm text-secondary my-4 text-lg text-semibold">
                              Order to be handled by
                            </div>
                            <div className="pb-8" style={{ display: "flex" }}>
                              <Field
                                name="order_processedby"
                                label="Self"
                                component={this.renderFieldRadio}
                                type="radio"
                                value="self"
                                className="mx-2"
                                onChange={this.order_processedbyChange.bind(
                                  this
                                )}
                              />
                              <Field
                                name="order_processedby"
                                label="By Shopez"
                                component={this.renderFieldRadio}
                                type="radio"
                                value="sa"
                                className="mx-2"
                                onChange={this.order_processedbyChange.bind(
                                  this
                                )}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="grid grid-cols-1">
                          <div className="w-60 mx-auto">
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={(e) =>
                                this.previousTabChange(e, tabIndextoShow)
                              }>
                              {LocaleStrings.previous}
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {/* <div className="grid grid-cols-3 gap-4o"> */}

                    {tabIndextoShow == 3 ? (
                      <div className="lg:w-60 mx-auto">
                        <button
                          type="submit"
                          id="uploadproductformbtn"
                          disabled={this.state.isBtnDisable}
                          className={classNames(
                            this.state.isBtnDisable ? "cursor-not-allowed" : "",
                            // "btn-primary"
                            "btn-primary"
                          )}>
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "hidden",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {isEdit
                            ? LocaleStrings.update_product
                            : LocaleStrings.add_product}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/***** VALIDATE FORM FC *****/
function validate(values, props) {
  let { shippingType, tabIndextoShow, tabs, globalSpecifications } = props;

  var errors = { variants: [] };
  var requiredFields = [];
  var requiredFieldsDates = ["auction_start_date", "auction_end_date"]; // For dates (we cant use trim with object)

  // Variant validation
  let variants = values["variants"];

  variants?.forEach((v, i) => {
    errors.variants.push({});

    // Variant english
    if (!v?.name_en || v?.name_en.trim() === "") {
      errors.variants[i].name_en = LocaleStrings.required;
    }

    // Variant arbic
    if (!v?.name_ar || v?.name_ar.trim() === "") {
      errors.variants[i].name_ar = LocaleStrings.required;
    }

    // Variant Quanity
    if (!v?.quantity || v?.quantity === "") {
      errors.variants[i].quantity = LocaleStrings.required;
    }

    // Child validation
    if (v.variantnames && v.variantnames.length) {
      errors.variants[i]["variantnames"] = [];

      v.variantnames?.forEach((values, index) => {
        errors.variants[i].variantnames.push({});
        if (!values?.name_en || values?.name_en.trim() === "") {
          errors.variants[i].variantnames[index].name_en =
            LocaleStrings.required;
        }
      });
    }
  });

  if (shippingType === "local") {
    requiredFields = [
      "autotranslate",
      "productname_en",
      "productname_ar",
      "product_desc_en",
      "product_desc_ar",
      "product_condition",
      "specification_desc_en",
      "specification_desc_ar",
      "saletype",
      "auction_min_price_percentage",
      "shipping_type",
      // "item_location",
      "handling_time",
      "return_time",
      "refund_type",
      "incremental_rate",
      "location_city_en",
      "location_state_en",
      "location_countryid",
      // "shippingcostbearer" // latest code
    ];
  } else {
    requiredFields = [
      "autotranslate",
      "productname_en",
      "productname_ar",
      "product_desc_en",
      "product_desc_ar",
      "product_condition",
      "specification_desc_en",
      "specification_desc_ar",
      "saletype",
      "auction_min_price_percentage",
      "shipping_type",
      // "item_location",
      "handling_time",
      "return_time",
      "refund_type",
      "incremental_rate",
      "delivery_company",
      "idcompany_package",
      "location_city_en",
      "location_state_en",
      "location_countryid",
      // "shippingcostbearer" //latest code
    ];
  }

  if (values.istaxable === "1" || values.istaxable === 1) {
    if (!requiredFields.producttax) {
      requiredFields.push("producttax");
    }
  }

  _.forEach(globalSpecifications, (item, index) => {
    if (item.ismandatory === "1") {
      let globalSpecFieldNameEn = "globalspec_name_en_" + item.id;
      let globalSpecFieldNameAr = "globalspec_name_ar_" + item.id;
      // Pushing value into initial value for both language
      requiredFields.push(globalSpecFieldNameEn);
      requiredFields.push(globalSpecFieldNameAr);
    }
  });

  if (values.return_time === "no_return") {
    requiredFields = requiredFields.filter(function (item) {
      return item !== "refund_type";
    });
  }
  // if (values.return_time === "no_return") {
  //   requiredFields = requiredFields.filter(function (item) {
  //     return item !== "shippingcostbearer";
  //   });
  // }//latest code

  // Percentage validation
  if (values && values.producttax && !validatePercent(values.producttax)) {
    errors["producttax"] = LocaleStrings.invalid;
  }

  // if (
  //   values.auction_min_price_percentage
  //   &&
  //   !validatePercent(values.auction_min_price_percentage)
  // ) {
  //   errors["auction_min_price_percentage"] = LocaleStrings.invalid;
  // }

  if (values.return_time === "no_return") {
    requiredFields = requiredFields.filter(function (item) {
      return item !== "refund_type";
    });
  }
  // if (values.return_time === "no_return") {
  //   requiredFields = requiredFields.filter(function (item) {
  //     return item !== "shippingcostbearer";
  //   });
  // } //latest code

  // console.log("values", values);
  // console.log("requiredFields", requiredFields);

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  /* For dates (we cant use trim with object) */
  if (values.saletype && values.saletype === "auction") {
    // requiredFieldsDates.forEach((field) => {
    //   if (!values[field] || values[field] === "") {
    //     errors[field] = LocaleStrings.required;
    //   }
    // });

    if (values["auction_start_date"] && values["auction_end_date"]) {
      let auction_start_date = "";
      let auction_end_date = "";

      // start date
      if (typeof values.auction_start_date === "string") {
        auction_start_date = parse(
          moment(values.auction_start_date).format("DD-MM-YYYY HH:mm:ss"),
          "dd-MM-yyyy HH:mm:ss",
          new Date()
        );
      } else {
        auction_start_date = values.auction_start_date;
      }

      // end date
      if (typeof values.auction_end_date === "string") {
        auction_end_date = parse(
          moment(values.auction_end_date).format("DD-MM-YYYY HH:mm:ss"),
          "dd-MM-yyyy HH:mm:ss",
          new Date()
        );
      } else {
        auction_end_date = values.auction_end_date;
      }

      if (auction_start_date.getTime() > auction_end_date.getTime()) {
        errors["auction_start_date"] =
          LocaleStrings.validity_end_date_and_greater_date_validation_text;
      }
    }
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  var storedItemSpecifications = _.isEmpty(state.storedItemSpecifications)
    ? []
    : state.storedItemSpecifications.data;
  // let currentDatetime = new Date(
  //   moment().format(DATE_FORMAT_WITH_TIME_WITH_SECOND)
  // );
  // console.log("currentDatetime", currentDatetime);
  // INITIALVALUE OBJECT FOR ADD
  let objectAdd = {
    saletype: "directsale",
    autotranslate: "0",
    autotranslate_ischeck: true, // field not in db
    bestofferenabled: false,
    make_donation: true, // field not in db
    cashondelivery: "yes",
    istaxable: "0",
    hasvariant: "1",
    shipping_type: "flat",
    variants: [{}],
    product_specification_custom: [{}],
    order_processedby: "self",
    // producttax: user.tax.toString(),
    producttax:
      user.tax !== null && user.tax !== "" ? user.tax.toString() : "0",
    auctiontype: false,
    // auction_start_date: currentDatetime,
  };

  let objectEdit = {};

  if (state.toBeEditproduct && state.toBeEditproduct.response) {
    //let product_variants_name = []; // Old Code without language change
    let product_variants_en_name = [];
    let product_variants_ar_name = [];
    let variantnames_name_en = [];
    let variantnames_name_ar = [];
    let product_variants_quantity = [];
    objectEdit = state.toBeEditproduct.response;
    objectEdit.autotranslate_ischeck =
      state.toBeEditproduct?.response?.autotranslate_ischeck == 0 ||
      state.toBeEditproduct?.response?.autotranslate_ischeck == "0"
        ? false
        : true;

    let variants = []; // Array for field array [parent / variation]
    // For [product_variants] [EXAMPLE - SIZE]
    _.forEach(objectEdit.product_variants, (value, index) => {
      variants.push(value);

      product_variants_en_name.push(value.name_en);
      product_variants_ar_name.push(value.name_ar);
      product_variants_quantity.push(value.quantity);

      // For [variantnames] [EXAMPLE - XL,XXL]
      let newArr = [];
      let newArr2 = [];

      _.forEach(value.variantnames, (innerValue, innerIndex) => {
        newArr.push(innerValue.name_en);
        newArr2.push(innerValue.name_ar);
      });

      variantnames_name_en.push(newArr);
      variantnames_name_ar.push(newArr2);
    });

    _.forEach(variants, (vObject, vIndex) => {
      vObject["variantnames"] = vObject.variantnames;
    });

    objectEdit["variants"] = variants; // inject data for field array

    objectEdit["product_variants_name_en_"] = product_variants_en_name;
    objectEdit["product_variants_name_ar_"] = product_variants_ar_name;
    objectEdit["product_variants_quantity"] = product_variants_quantity;
    objectEdit["variantnames_en"] = variantnames_name_en;
    objectEdit["variantnames_ar"] = variantnames_name_ar;

    if (objectEdit.globalspecifications.length > 0) {
      // For Product specification / global specifications [creating initial object from it]
      _.forEach(
        objectEdit.globalspecifications,
        (globalSpecValues, globalSpecIndex) => {
          objectEdit["globalspec_name_en_" + globalSpecValues.idspecification] =
            globalSpecValues.value_en;
          objectEdit["globalspec_name_ar_" + globalSpecValues.idspecification] =
            globalSpecValues.value_ar;
        }
      );
    }

    if (objectEdit.specifications.length > 0) {
      // For Item specification / Item specifications [creating initial object from it]
      _.forEach(
        objectEdit.specifications,
        (specificationsValues, specificationsIndex) => {
          objectEdit[
            "itemspec_name_en_" + specificationsValues.idspecification
          ] = specificationsValues.value_en;
          objectEdit[
            "itemspec_name_ar_" + specificationsValues.idspecification
          ] = specificationsValues.value_ar;
        }
      );
    }
    // not required in uproduct upload new change
    // if (_.size(objectEdit?.product_calculated_shipping) > 0) {
    //   //state.zoneAndpincodes?.data
    //   _.forEach(objectEdit?.product_calculated_shipping, (val, i) => { });
    // }

    // state.zoneAndpincodes?.data;

    objectEdit["bestofferenabled"] =
      objectEdit["bestofferenabled"] === "yes" ||
      objectEdit["bestofferenabled"] === true
        ? true
        : false;
    // objectEdit["order_processedby"] = "self";
  }

  // console.log("objectEdit", objectEdit);
  // console.log("state.toBeEditproduct.response", state.toBeEditproduct.response);
  let variationMasterListArr = [];
  if (!_.isEmpty(state.variationMasterList)) {
    _.forEach(state.variationMasterList.data, (value, index) => {
      let object = value;
      object.isSelected = false;
      variationMasterListArr.push(object);
    });
  }

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    localCurrency: state.currency,
    tabs: state.uploadProductsTabs,
    defaultSettings: state.DefaultSettings,
    isproductdiscountopen: state.isProductDiscountopen,
    isvariationdeatilsopen: state.isVariationDeatilsopen,
    prodCateSubCateModal: state.prodCateSubCateModal,
    selectedSubcategorids: state.selectedSubcategorids, // [ACTION NAME - allSelectedSubcategorisID]
    storedItemSpecifications, // Item specification
    globalSpecifications: _.isEmpty(state.globalSpecifications)
      ? []
      : state.globalSpecifications.data, // Global Specification
    prodDiscAddedData: state.prodDiscAddedData ? state.prodDiscAddedData : [], // Added Discount data ACTION NAME [ productAddedDiscount ]
    productexcludeLocationdata: state.productexcludeLocationdata
      ? state.productexcludeLocationdata
      : [], // Added productexcludeLocationdata data
    continentLengthdata: state.continentLengthdata
      ? state.continentLengthdata
      : 0, // Added productexcludeLocationdata data
    productData: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? state.toBeEditproduct.response
        : objectAdd
      : objectAdd,
    //initialValues:{name_en:['test1','test2'],v_name_en:{0:['test11','test12'],3:['test31','test33']}},
    initialValues: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? objectEdit
        : objectAdd
      : objectAdd,
    isEdit: !_.isEmpty(state.toBeEditproduct)
      ? !_.isEmpty(state.toBeEditproduct.response)
        ? true
        : false
      : false,
    isProductFeeOpen: state.isProductFeeOpen,
    producvtSubmitConfirm: state.producvtSubmitConfirm,
    discountlist: !_.isEmpty(state.discountlist)
      ? !_.isEmpty(state.discountlist.data)
        ? state.discountlist.data
        : []
      : [],
    productList: !_.isEmpty(state.productList)
      ? !_.isEmpty(state.productList.data)
        ? state.productList.data
        : []
      : [],
    shippingType: state.shippngTypeStatus,
    isCheckShippingBearerAvailable: _.isEmpty(
      state.isCheckShippingBearerAvailable
    )
      ? false
      : state.isCheckShippingBearerAvailable.data.isavailable,
    variationMasterList: _.isEmpty(variationMasterListArr)
      ? {}
      : variationMasterListArr, // Variation master list
    settingsMasterStore: state.settingsMasterStore
      ? state.settingsMasterStore.data !== "undefined"
        ? state.settingsMasterStore.data
        : []
      : [],
    countryList: state.countryList
      ? state.countryList.data !== "undefined"
        ? state.countryList.data
        : []
      : [],
    continentList: state.continentList
      ? state.continentList.data !== "undefined"
        ? state.continentList.data
        : []
      : [],
    vendorDetails: !_.isEmpty(state.vendorDetails)
      ? !_.isEmpty(state.vendorDetails.data)
        ? state.vendorDetails.data
        : []
      : [],
    formValues: getFormValues("UploadProductForm")(state),
    formValues1: getFormValues("addUpdateVariationDetailsForm")(state),
    zoneAndpincodes: state.zoneAndpincodes?.data,
    defaultShippingCharges: state.defaultShippingCharges?.data,
    relist_while_product_upload: state.relist_while_product_upload,
    listingamount: state.listingamount
      ? state.listingamount.data !== "undefined"
        ? state.listingamount.data
        : 0
      : 0,
    ischarityviewModalopen: state.ischarityviewModalopen,
    auction_daysList: state.auction_daysList,
    isCatsubcatupdatedData: state.isCatsubcatupdatedData,
  };
}
export default connect(mapStateToProps, {
  isProductCateSubCateModalOpen,
  isProductDiscountopen,
  isExcludeshippingmodalopen,
  isVariationDeatilsopen,
  productTitleSubTitleData,
  addproduct,
  uploadMediaLargeVendorVariation,
  addDiscountMaster,
  updateDiscountMaster,
  productFeeModal,
  fetchProductFeeDetails,
  allSelectedSubcategorisID,
  unSetTobeEditProductStore,
  tabListWithSelection,
  currentShippngType,
  productAddedDiscount,
  fetchVariationMaster,
  fetchSettingsMaster,
  enabledbestoffer,
  clearDiscountList,
  reinitializeProduxtSpecification, // Clear product item specification,
  fetchZoneAndPincode,
  getTranslatedResults, // Translate API call
  toggleunlistproduct,
  relistwhileUpdatingProduct,
  fetchShippingCharges,
  makepaymentPersonalvendor,
  updateMastervariationListArray,
  continentLength,
  productExculdelocation,
  charityviewModalopen,
  iscatsubCatUpdated,
})(
  reduxForm({
    validate,
    form: "UploadProductForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(UploadProduct)
);
