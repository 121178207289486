import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import LocaleStrings from "@language";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { classNames } from "@commonFunction";
import { Field, reduxForm, getFormValues, change } from "redux-form";
import _, { map } from "lodash";
import {
  isExcludeshippingmodalopen,
  productExculdelocation,
  continentLength,
} from "@productmanagementActions";

class ExcludeShipping extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      discTypeState: "percentage",
      btnLoader: false,
      isBtnDisable: false,
      selectedContinent: null,
      selectedOptions: [],
      selectedUsers: [],
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { initialValues, continentList } = this.props;
    let continentArr = [];
    let continentnamArr = [];
    if (Object.keys(initialValues).length > 0) {
      _.map(initialValues, (item, index) => {
        continentnamArr = _.filter(continentList.data, function (o) {
          return o.id == index;
        });
        if (continentnamArr.length > 0) {
          continentArr.push(continentnamArr[0]);
        }
      });
      this.setState({ selectedUsers: continentArr });
    }
  }

  renderField(field) {
    var {
      meta: { touched, error },
    } = field;
    var className = `form-group login-element ${
      touched && error ? "text-danger text-xs" : ""
    }`;
    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
        <div className="text-help h-4">{touched ? error : ""}</div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ open: true });
    }
    if (nextProps.hedaer) {
      this.setState({ headerText: nextProps.hedaer });
    }
  }

  closeModal() {
    this.setState({ open: false });
    this.props.isExcludeshippingmodalopen(false);
  }

  _handleContinentSelect = (e, id) => {
    this.setState({ selectedContinent: id });
  };

  /**** Fake Close ****/
  closeModal1() {}
  /**** OLD SUBMIT ****/
  // submit_locations = () => {
  //   let { formValues } = this.props;
  //   var selectedUsers = this.state.selectedUsers;

  //   let countrylength = 0;
  //   let continentArr = [];
  //   let countryArr = [];
  //   console.log("formValues", formValues);
  //   _.map(formValues, (item, index) => {
  //     let objcontinet = {
  //       index: index,
  //     };
  //     let objcountry = {};
  //     if (item != undefined) {
  //       objcountry.value = item;
  //       continentArr.push(objcontinet);
  //       countryArr.push(objcountry);
  //       countrylength += item.length;
  //     }
  //   });

  //   this.setState({ btnLoader: true });
  //   this.props.productExculdelocation(formValues);
  //   let continentLength = Object.keys(formValues).length;
  //   // Object.keys(formValues).length == 1
  //   //   ? 1
  //   //   : Object.keys(formValues).length - 1;
  //   countrylength = countrylength;
  //   let countobj = {
  //     countrylength: countrylength,
  //     continentLength: continentLength,
  //     countryid: countryArr,
  //     countinentid: continentArr,
  //   };
  //   this.props.continentLength(countobj);
  //   this.closeModal();
  //   this.setState({ btnLoader: false });
  // };

  submit_locations = () => {
    let { formValues } = this.props;
    var selectedUsers = this.state.selectedUsers;

    let countrylength = 0;
    let continentArr = [];
    let continentindexArr = [];
    let countryArr = [];
    let removecontinetarr = [];

    // _.map(formValues, (item, index) => {
    //   let objcontinet = {
    //     index: index,
    //   };

    //   let objcountry = {};
    //   if (item != undefined) {
    //     // objcountry.value = item;
    //     continentArr.push(objcontinet);
    //     continentindexArr.push(index); // to manage checkbox selected outside
    //     // countryArr.push(objcountry);
    //     // countrylength += item.length;
    //   }
    // });
    // if (selectedUsers.length > 0 && continentindexArr) {
    //   _.map(selectedUsers, (val, i) => {
    //     let filteredArr = _.filter(continentindexArr, function (o) {
    //       return o == val.id;
    //     });

    //     if (filteredArr.length == 0) {
    //       let countryarr = [];
    //       _.forEach(val.country_by_continentid, (v, i) => {
    //         countryarr.push(v.id);
    //       });

    //       formValues[val.id] = countryarr;
    //     } else {
    //       if (
    //         formValues[filteredArr[0]] &&
    //         _.isEmpty(formValues[filteredArr[0]])
    //       ) {
    //         removecontinetarr.push(filteredArr[0]);
    //       }
    //     }
    //   });
    // }

    _.map(formValues, (item, index) => {
      if (item.length == 0) {
        delete formValues[index];
      }
    });

    _.map(formValues, (item, index) => {
      let objcontinet = {
        index: index,
      };

      let objcountry = {};
      if (item != undefined) {
        objcountry.value = item;
        countryArr.push(objcountry);
        continentArr.push(objcontinet);
        continentindexArr.push(index); // to manage checkbox selected outside
        countrylength += item.length;
      }
    });

    this.setState({ btnLoader: true });
    this.props.productExculdelocation(formValues);
    let continentLength = Object.keys(formValues).length;
    countrylength = countrylength;
    let countobj = {
      countrylength: countrylength,
      continentLength: continentLength,
      countryid: countryArr,
      countinentid: continentArr,
    };
    this.props.continentLength(countobj);
    this.closeModal();
    this.setState({ btnLoader: false });
  };

  // Form Submit
  onFormSubmit(values) {}

  selectionCallback = (continent) => {
    let { formValues } = this.props;
    var selectedUsers = this.state.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.id === continent.id;
    });

    if (index >= 0) {
      var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
        return selusers.id !== continent.id;
      });

      if (formValues != undefined && Object.keys(formValues).length) {
        delete formValues[continent.id];
      }
      this.checkMatchArray(newSelectedUsers);
      this.setState({ selectedUsers: newSelectedUsers });
    } else {
      if (!continent.deleted) {
        let countryarr = [];
        _.forEach(continent.country_by_continentid, (v, i) => {
          countryarr.push(v.id);
        });

        formValues[continent.id] = countryarr;
        var newSelectUser = { ...continent, checked: true };
        selectedUsers = [...selectedUsers, newSelectUser];
      }

      this.checkMatchArray(selectedUsers);
      this.setState({ selectedUsers });
      this.setState({ isBtnDisable: false });
    }
  };

  checkMatchArray = (selectedUsers) => {
    setTimeout(() => {
      var continentList = this.props.continentList.data;

      var newArray = [];
      _.map(continentList, (contactUser) => {
        var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
          return selusers.id === contactUser.id;
        });

        if (newSelectedUsers && newSelectedUsers.length > 0) {
          newArray.push(newSelectedUsers[0]);
        }
      });

      // Check for current page selection and according to show or hide action option for bulk email send
      if (newArray.length > 0) {
        this.setState({ actionOption: true });
      } else {
        this.setState({ actionOption: false });
      }
    }, 100);
  };

  rendercontinentList = () => {
    let { continentList } = this.props;
    if (continentList.data.length > 0) {
      var item = _.map(continentList.data, (val, index) => {
        let optionArr = [];
        _.forEach(val.country_by_continentid, function (value) {
          var obj = {
            name: value.name,
            value: value.id,
            label: value.name,
          };

          optionArr.push(obj);
        });
        var selectedUsers = this.state.selectedUsers;
        var index = _.findIndex(selectedUsers, function (curuser) {
          return curuser.id === val.id;
        });
        var checked = false;
        if (index >= 0) {
          checked = true;
        }
        return (
          <>
            <div className="">
              <div className="bg-quaternary p-6 rounded my-4">
                <div className="grid grid-cols-6">
                  <div className="col-span-1">
                    <input
                      type="checkbox"
                      className=" left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      onChange={() => this.selectionCallback(val)}
                      checked={checked}
                    />
                  </div>
                  <div className="col-span-4">
                    <div>{val.continent}</div>
                  </div>
                  <div className="col-span-1">
                    <div
                      onClick={(e) => this._handleContinentSelect(e, val.id)}>
                      <img
                        loading="lazy"
                        class="h-4 transform rotate-90 px-2 animation cursor-pointer transition duration-500 ease-in-out"
                        id="arrow_0"
                        src="/images2/profile_settings_images/arrow-right.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                this.state.selectedContinent == val.id ? "" : "hidden",
                "py4"
              )}>
              <Field
                name={val.id}
                placeholder={val.continent + "'s" + " " + "Countries"}
                mandatory="false"
                component={this.renderReactMultiSelectCheckboxes}
                opts={optionArr}
                labelposition={LABEL_POSITION_TOP}
              />
            </div>
          </>
        );
      });
      if (_.isEmpty(item)) {
        return (
          <div className="col-span-4  p-2  text-center">
            {" "}
            <p className="paragraph-seconday">
              {LocaleStrings.excludeshipping_nocontent}
            </p>
          </div>
        );
      }
    }

    return item;
  };

  componentWillUnmount() {
    this.state = {
      open: false,
      headerText: "",
      discTypeState: "percentage",
      isBtnDisable: false,
    };
  }

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      isExcludeshippingopen,
      continentList,
    } = this.props;
    return (
      <Transition.Root show={isExcludeshippingopen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={isExcludeshippingopen}
          onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}>
                <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div></div>
                  <div className="flex flex-wrap col-span-2 content-center justify-center mt-4">
                    <div className="text-lg leading-6 font-medium text-secondary">
                      {LocaleStrings.exclude_location}
                    </div>
                  </div>
                  <div className=" absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal"
                      onClick={this.closeModal}>
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mx-14 my-4 sm:flex-row-reverse">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div>{this.rendercontinentList()}</div>
                  </form>
                  <div className="pt-4">
                    <button
                      type="button"
                      onClick={(e) => this.submit_locations(e)}
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? "cursor-not-allowed" : "",
                        "btn-primary"
                      )}>
                      <svg
                        className={classNames(
                          this.state.btnLoader ? "" : "hidden",
                          "animate-spin-medium h-5 w-5 rounded-full mx-2"
                        )}
                        disabled={this.state.isBtnDisable}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.update}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  var errors = {};
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isExcludeshippingopen: state.isExcludeshippingopen,
    initialValues: state.productexcludeLocationdata,
    continentList: state.continentList,
    formValues: getFormValues("ExcludeShippingForm")(state),
  };
}

export default connect(mapStateToProps, {
  isExcludeshippingmodalopen,
  productExculdelocation,
  continentLength,
})(
  reduxForm({
    validate,
    form: "ExcludeShippingForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ExcludeShipping)
);
