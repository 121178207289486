import { filter } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import { BASE_IMAGES_URL, APP_API_KEY } from "@constant";

class ProductImgCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showerror: false,
      currentImage: 0,
    };
  }

  getUrl(object) {
    // Old code
    var reader = new FileReader();
    var imageUrl = "";
    if (!object.raw_thumbnail) {
      imageUrl = `${BASE_IMAGES_URL}/vendor/variations/${object.thumbfilename}?api_key=${APP_API_KEY}`;
    } else {
      let arrayBuffer = object.raw_thumbnail.url;

      var blob = new Blob([arrayBuffer], { type: "image/jpeg" });
      var urlCreator = window.URL || window.webkitURL;
      imageUrl = urlCreator.createObjectURL(blob);
    }

    // detaileDvariantsObj.variations_media_raw[0].file_path;

    // New codes var imageUrl = object.file_path;

    return imageUrl;
  }

  renderCarousel = () => {
    var reader = new FileReader();
    let { productObjectForFeeDetails, isProductFeeDefault, refkeyArr } =
      this.props;
    let { currentImage } = this.state;
    let images = [];

    if (isProductFeeDefault) {
      // For first time default array
      images = _.filter(
        productObjectForFeeDetails.detailedvariants[0].variations_media_raw,
        //productObjectForFeeDetails.detailedvariants[0].file_path,// New image
        (detailsImgObj, index) => {
          return !_.isEmpty(detailsImgObj.type);
        }
      );
      if (images.length == 0) {
        images = _.filter(
          productObjectForFeeDetails.detailedvariants[0].variations_media,
          //productObjectForFeeDetails.detailedvariants[0].file_path,// New image
          (detailsImgObj, index) => {
            return !_.isEmpty(detailsImgObj.type);
          }
        );
      }
    } else {
      // If NOt default as Variant Selected By user

      let filterRefkey = _.filter(productObjectForFeeDetails.detailedvariants, {
        refkey: _.join(refkeyArr, "-"),
      });

      if (_.isEmpty(filterRefkey)) {
        images = [];
      } else {
        images = _.filter(
          filterRefkey[0].variations_media_raw,
          (detailsImgObj, index) => {
            return !_.isEmpty(detailsImgObj.type);
          }
        );
        if (images.length == 0) {
          images = _.filter(
            filterRefkey[0].variations_media,
            (detailsImgObj, index) => {
              return !_.isEmpty(detailsImgObj.type);
            }
          );
        }
      }
    }

    // We will start by storing the index of the current image in the state.
    // const [currentImage, setCurrentImage] = React.useState(0);

    // We are using react ref to 'tag' each of the images. Below will create an array of
    // objects with numbered keys. We will use those numbers (i) later to access a ref of a
    // specific image in this array.
    const refs = images.reduce((acc, val, i) => {
      acc[i] = React.createRef();
      return acc;
    }, {});

    const scrollToImage = (i) => {
      // First let's set the index of the image we want to see next
      // setCurrentImage(i);
      this.setState({ currentImage: i });
      // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
      // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
      // your current view! To do so we pass an index of the image, which is then use to identify our current
      // image's ref in 'refs' array above.
      refs[i]?.current.scrollIntoView({
        //     Defines the transition animation.
        behavior: "smooth",
        //      Defines vertical alignment.
        block: "nearest",
        //      Defines horizontal alignment.
        inline: "start",
      });
    };

    // Some validation for checking the array length could be added if needed
    const totalImages = images.length;

    // Below functions will assure that after last image we'll scroll back to the start,
    // or another way round - first to last in previousImage method.
    const nextImage = () => {
      if (currentImage >= totalImages - 1) {
        scrollToImage(0);
      } else {
        scrollToImage(currentImage + 1);
      }
    };

    const previousImage = () => {
      if (currentImage === 0) {
        scrollToImage(totalImages - 1);
      } else {
        scrollToImage(currentImage - 1);
      }
    };

    // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
    const arrowStyle =
      "absolute text-custWhite text-2xl bg-custblack h-10 w-10 rounded-full opacity-75 flex items-center justify-center";

    // Let's create dynamic buttons. It can be either left or right. Using
    // isLeft boolean we can determine which side we'll be rendering our button
    // as well as change its position and content.
    const sliderControl = (isLeft) => (
      <button
        type="button"
        onClick={isLeft ? previousImage : nextImage}
        className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
        style={{ top: "40%" }}>
        <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
          {isLeft ? "◀" : "▶"}
        </span>
      </button>
    );

    return (
      <div className="flex justify-center w-screen md:w-full items-center">
        {/* // <div className="flex justify-center w-half md:w-screen md:w-full items-center"> */}
        <div className="relative w-full bg-red" align="center">
          <div className="carousel">
            {sliderControl(true)}
            {images.map((img, i) => (
              <>
                <div className="w-full flex-shrink-0 " key={img} ref={refs[i]}>
                  {img.file_type === "video" ? (
                    <video width="400" controls>
                      <source src={img.file_path} type="video/mp4" />
                      {LocaleStrings.browserhtml_notsupport}
                    </video>
                  ) : (
                    <img
                      loading="lazy"
                      src={this.getUrl(img)}
                      alt="image"
                      className="w-screen object-contain custblack rounded  h-44"
                    />
                  )}
                </div>
              </>
            ))}
            {sliderControl()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { productObjectForFeeDetails, isProductFeeDefault } = this.props;
    //let {} = this.state;

    return (
      <>
        <div className="w-full justify-center">
          {/* <Carousel data={productObjectForFeeDetails}  /> */}
          {this.renderCarousel()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    productObjectForFeeDetails: state.productObjectForFeeDetails,
    isProductFeeDefault: state.isProductFeeDefault,
    refkeyArr: ownProps.selectedVariantDataArray, //Data array with refkeys
  };
}

export default connect(mapStateToProps, {})(ProductImgCarousel);
