import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment-timezone";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
// Functions Import
import { ProductTextLocalized, classNames, isEmpty } from "@commonFunction";
import toast from "react-hot-toast";
import {
  // DATE_FORMAT_TO_SHOW,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
} from "@constant";

// Import Actions
import { isRaiseTicketModalopen } from "../actions/index";
import {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
} from "../../appsuggestions/actions/index";

// Component Import

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
var voucher_codes = require("voucher-code-generator");

class RaiseTicketAdd extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      fileName: "",
      fileArr: [],
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)

    this.props.isRaiseTicketModalopen(false);
  }

  /**** Input File select ****/
  _handleSelect(event) {
    let { fileArr } = this.state;
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    let ext = file.name.split(".").pop(); // File extension

    // if(_.indexOf(validateExtention, ext) === -1) { // Validate File Extensions
    //     toast.error(LocaleStrings.upload_only_doc_and_pdf_file_error_text);
    // }else {

    // }
    this.setState({ fileName: file.name });
    //this.props.autofill(typeindex,file.name);

    fileArr.push(file);
    this.setState({ fileArr: fileArr });
  }

  /**** RED cross Click ****/
  _handleUnSelect = (e) => {
    //let { fileArr } = this.state;

    //fileArr.splice(0, fileArr.length);

    this.setState({ fileArr: [] });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    if (
      !values.problem ||
      values.problem.trim() == "" ||
      values.problem == null
    ) {
      toast.error(LocaleStrings.raise_question_error);
      return false;
    }
    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });

    let { fileArr } = this.state;

    values["userid"] = this.props.session.userid;
    values["requesttype"] = "ticket";
    values["email"] = this.props.session.emailid;

    // Upload Section
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    let attachmentNames = [];

    _.forEach(fileArr, function (value, index) {
      attachmentNames.push(value.name);

      let coverJson = {
        type: value.type,
        name: value.name,
        media: value.url,
      };

      filetobeupload.push(coverJson); //
    });

    let counter = filetobeupload.length;

    // Store File to server first
    filetobeupload.map((item, index) => {
      arrPromise.push(
        new Promise((resolve, reject) => {
          this.props.uploadMediaLargeAppSuggestion(
            this.props.session,
            item,
            (response) => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            }
          );
        })
      );
    });

    if (counter > 0) {
      //Save With Files
      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          values["attachment"] = _.join(attachmentNames, ",");

          // After file upload
          this.props.sendsuggestions(this.props.session, values, (callback) => {
            if (callback.status === 1) {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });
              this.setState({ fileName: "" });
              this.setState({ fileArr: [] });

              toast.success(LocaleStrings.faq_support_submit_success);
              this.props.reset();
              this.closeModal();
            } else {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });

              toast.error(LocaleStrings.something_went_wrong);
            }
          });
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    } else {
      //Save With out Files
      values["attachment"] = "";

      this.props.sendsuggestions(this.props.session, values, (callback) => {
        if (callback.status === 1) {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          this.setState({ fileName: "" });
          this.setState({ fileArr: [] });

          toast.success(LocaleStrings.faq_support_submit_success);
          this.props.reset();
          this.closeModal();
        } else {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });

          toast.error(LocaleStrings.something_went_wrong);
        }
      });
    }
  }

  render() {
    let { initialValues, language, handleSubmit } = this.props;
    let { isBtnDisable, fileArr } = this.state;

    return (
      <>
        <Transition.Root show={this.props.isRaiseTicketopen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isRaiseTicketopen}
            onClose={this.closeModal1}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0, maxHeight: "460px" }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 font-medium text-secondary">
                        {LocaleStrings.raise_a_ticket}
                      </div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="p-10 space-y-8">
                      {/* Body Start */}
                      <div className="">
                        <p className="text-sm text-ternary pb-4">
                          {LocaleStrings.raise_tacket_sub_header_text}
                        </p>
                      </div>
                      <Field
                        name={"problem"}
                        label={LocaleStrings.write_your_problems}
                        // placeholder={LocaleStrings.write_your_questions}
                        mandatory="true"
                        component={this.renderFieldTextarea}
                        labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-box-lable"
                        className="py-3 px-4 block w-full shadow-sm text-secondary border border-warm-gray-300 rounded-md"
                      />
                      {/* File Attachment */}
                      <div className="flex justify-between">
                        <div className=" flex">
                          <span className="text-sm text-ternary">
                            {_.size(fileArr) > 0 ? _.size(fileArr) : ""}{" "}
                            {_.size(fileArr) === 1
                              ? LocaleStrings.file_attached
                              : _.size(fileArr) > 1
                              ? LocaleStrings.files_attached
                              : null}
                          </span>
                          <img
                            loading="lazy"
                            className={classNames(
                              _.size(fileArr) > 0 ? "" : "hidden",
                              "h-4 w-4 m-0.5 cursor-pointer"
                            )}
                            src={"/images2/common/red-cross.svg"}
                            onClick={(e) => this._handleUnSelect(e)}
                          />
                        </div>
                        <div className="flex ">
                          <label
                            htmlFor="attachment"
                            className="relative btn-primary-outline cursor-pointer">
                            <img
                              loading="lazy"
                              className={"h-4 w-4 "}
                              src={
                                "/images2/profile_settings_images/attachfile.svg"
                              }
                            />
                            <span>{LocaleStrings.attach_files}</span>
                            <input
                              id="attachment"
                              name="attachment"
                              type="file"
                              accept="image/*"
                              className="sr-only"
                              onChange={(e) => this._handleSelect(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Body Ends*/}

                    <div className="p-6 border-t-2 border-quaternary">
                      {/* Footer */}
                      <button
                        type="submit"
                        disabled={this.state.isBtnDisable}
                        className={classNames(
                          this.state.isBtnDisable ? "cursor-not-allowed" : "",
                          "btn-primary"
                        )}>
                        <svg
                          className={classNames(
                            this.state.btnLoader ? "" : "sr-only",
                            "animate-spin-medium h-5 w-5 rounded-full mx-2"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.raise_a_ticket}
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["problem"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  // if (email && !validateEmail(email)) {
  //     errors["emailid"] = "Invalid email";
  // }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isRaiseTicketopen: state.isRaiseTicketopen,
    initialValues: {},
  };
}

export default connect(mapStateToProps, {
  isRaiseTicketModalopen,
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
})(
  reduxForm({
    validate,
    form: "addCouponForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RaiseTicketAdd)
);
