import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LocaleStrings from "@language";
import { DATE_FORMAT } from "@constant";
import { confirmAlert } from "react-confirm-alert";
import { classNames } from "@commonFunction";
// Import Actions
import {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
  fetchDiscountMaster,
  discountactions, // accept or reject
  updateDiscountMaster,
} from "@discountsmanagementActions";

// Common Functions
import { ProductTextLocalized, converDateIntoLocal } from "@commonFunction";
import toast from "react-hot-toast";

class DiscMasterTableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoader: false,
    };

    // All Binds Functions
  }

  openDiscountManagementModal(e, discountObj) {
    if (Object.keys(discountObj.discountarea_by_iddiscount).length > 0) {
      let idcategory = discountObj.discountarea_by_iddiscount[0].idcategory;
      let idsubcategory =
        discountObj.discountarea_by_iddiscount[0].idsubcategory;

      this.props.fetchProductsByCateSubCate(
        this.props.session,
        idcategory,
        idsubcategory,
        (callBack) => {}
      );
    }

    this.props.getSelectedDiscountObj(discountObj, this.props.session);
    this.props.isOpenDiscManagModal(true);
  }

  /* Accept or reject a discount from super admin */
  _handelAcceptOrReject = (event, discountobj, type) => {
    let { session } = this.props;
    let title =
      type === "accepted"
        ? LocaleStrings.discount_accept_confirm_title
        : LocaleStrings.discount_reject_confirm_title;

    let subtitle =
      type === "accepted"
        ? LocaleStrings.discount_accept_confirm_message
        : LocaleStrings.discount_reject_confirm_message;

    let updateStatus =
      type === "accepted"
        ? LocaleStrings.discount_accepted_success
        : LocaleStrings.discount_rejected_success;

    confirmAlert({
      title: title,
      message: subtitle,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            // props.openReportModal(true, userid);
            this.props.discountactions(
              session,
              discountobj.id,
              type,
              (callBack) => {
                if (callBack.status === 1) {
                  toast.success(updateStatus);
                  this.props.updatePageNumber(1); // Initilize page number to 1
                  // Fetch discount list
                  this.props.fetchDiscountMaster(session, 1, (callBack) => {});
                } else {
                  toast.error(LocaleStrings.something_went_wrong);
                  return false;
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  activateDeactive = (e, values) => {
    let obj = {
      id: values.id,
      active: values?.active == "1" || values?.active == 1 ? 0 : 1,
    };
    let message = LocaleStrings.discount_activated_success;
    if (values.active == 0) {
      message = LocaleStrings.discount_deactivated_success;
    }

    this.setState({ btnLoader: true });
    this.props.updateDiscountMaster(this.props.session, obj, (callR) => {
      if (callR.status == 1) {
        this.props.fetchDiscountMaster(this.props.session, 1, (callBack) => {
          toast.success(message);
          this.setState({ btnLoader: false });
        });
      } else {
        toast.success(LocaleStrings.something_went_wrong);
        this.setState({ btnLoader: false });
      }
    });
  };

  render() {
    let language = this.props.language;
    let values = this.props.dismasterObj;
    let discountArea = this.props.discountArea;
    let productCategory = this.props.productCategory;
    let productSubCategory = this.props.productSubCategory;
    let productListdiscount = this.props.productListdiscount;
    let area = "";

    let appliedOn = "";
    if (
      values &&
      values.discountarea_by_iddiscount &&
      values.discountarea_by_iddiscount.length > 0
    ) {
      if (
        values.discountarea_by_iddiscount[0] !== null &&
        values.discountarea_by_iddiscount[0].discounareaid !== null &&
        values.discountarea_by_iddiscount[0].discounareaid !== ""
      ) {
        let afterFindObj = {};
        if (discountArea !== undefined && discountArea !== "undefined") {
          afterFindObj = _.find(discountArea, {
            id: parseInt(values?.discountarea_by_iddiscount[0]?.discounareaid),
          });

          appliedOn = ProductTextLocalized(
            afterFindObj.area_en,
            afterFindObj.area_ar,
            language
          );
        }
      }
    }

    if (appliedOn == "Category") {
      let productCategory_filter = [];
      if (productCategory.data && productCategory.data.length > 0) {
        productCategory_filter = _.filter(productCategory.data, function (o) {
          return (
            o.id === parseInt(values?.discountarea_by_iddiscount[0]?.idcategory)
          );
        });

        if (productCategory_filter.length > 0) {
          area = ProductTextLocalized(
            productCategory_filter[0].categoryname_en,
            productCategory_filter[0].categoryname_ar,
            language
          );
        }
        // console.log("productCategory_filter", productCategory_filter);
        // console.log("values", values);
      }
    } else if (appliedOn == "Subcategory") {
      let productsubCategory_filter = [];
      if (productSubCategory.data && productSubCategory.data.length > 0) {
        productsubCategory_filter = _.filter(
          productSubCategory.data,
          function (o) {
            return (
              o.id ===
              parseInt(values?.discountarea_by_iddiscount[0]?.idsubcategory)
            );
          }
        );

        if (productsubCategory_filter.length > 0) {
          area = ProductTextLocalized(
            productsubCategory_filter[0].subcategoryname_en,
            productsubCategory_filter[0].subcategoryname_ar,
            language
          );
        }
      }
    } else if (appliedOn == "Product") {
      let product_filter = [];
      if (productListdiscount.data && productListdiscount.data.length > 0) {
        product_filter = _.filter(productListdiscount.data, function (o) {
          return (
            o.id === parseInt(values?.discountarea_by_iddiscount[0]?.idproduct)
          );
        });

        if (product_filter.length > 0) {
          area = ProductTextLocalized(
            product_filter[0].productname_en,
            product_filter[0].productname_ar,
            language
          );
        }
      }
    }

    return (
      <tr key={this.props.position} className="table-hover-transition">
        <td className="sales-table-td">
          <div className="sales-table-td-text">{values.title}</div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text ">{appliedOn}</div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text ">
            {/* {area} */}
            {area.length > 30 ? area.substring(0, 30) + "..." : area}
          </div>
        </td>
        <td className="sales-table-td">
          <div className="sales-table-td-text">
            {values.validity_from}
            {" - "}
            {values.validity_end}
          </div>
        </td>
        <td className="sales-table-td">
          {" "}
          <div className="sales-table-td-text">
            {values.discount_percentage}%
          </div>
        </td>
        <td className="sales-table-td">
          {values.owner === 1 ? (
            <div className="sales-table-td-text ">
              <button
                type="button"
                className="tbl-btn-primary"
                onClick={(e) => this.openDiscountManagementModal(e, values)}>
                {LocaleStrings.edit}
              </button>
              <button
                type="button"
                className="tbl-btn-primary ml-2"
                onClick={(e) => this.activateDeactive(e, values)}>
                <svg
                  className={classNames(
                    this.state.btnLoader ? "" : "hidden",
                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                  )}
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
                {values.active == 1 ? "Deactivate" : "Activate"}
              </button>
            </div>
          ) : values.status === "accepted" ? (
            <div className="card-badge-success">{LocaleStrings.accepted}</div>
          ) : (
            <>
              {/* Accept / reject button */}
              <div className="sales-table-td-text">
                <button
                  type="button"
                  className="tbl-btn-primary"
                  onClick={(e) =>
                    this._handelAcceptOrReject(e, values, "accepted")
                  }>
                  {LocaleStrings.accept}
                </button>
                <button
                  type="button"
                  className="tbl-btn-danger mx-2"
                  onClick={(e) =>
                    this._handelAcceptOrReject(e, values, "rejected")
                  }>
                  {LocaleStrings.reject}
                </button>
              </div>
            </>
          )}
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    discountArea: state.discountArea ? state.discountArea.data : [],
    productCategory: state.productCategory,
    productSubCategory: state.productSubCategory,
    productListdiscount: state.productListdiscount,
  };
}

export default connect(mapStateToProps, {
  isOpenDiscManagModal,
  getSelectedDiscountObj,
  fetchProductsByCateSubCate,
  fetchDiscountMaster,
  discountactions, // accept or reject
  updateDiscountMaster,
})(DiscMasterTableList);
